import { useQuery } from '@tanstack/react-query';
import {
  getCloudDeploymentEnvs,
  getDeploymentEnvs,
  getDeploymentEnvSubtypes,
  getClustersNamespaces,
} from '../controllers/deploymentEnvironment';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';
import { DeploymentEnvironment } from '../models/master';

export const useDeploymentEnvsData = (
  view = 'all',
  includeInfraOnly = 'all',
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.DEPLOYMENTENVIRONMENTS, view, includeInfraOnly],
    queryFn: () => getDeploymentEnvs(view, includeInfraOnly),
    ...queryDefaultOptions,
    ...options,
  });
};

export const useDeploymentEnvSubtypes = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.DEPLOYMENTENVIRONMENTS_SUBTYPES],
    queryFn: getDeploymentEnvSubtypes,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useClustersNamespaces = (
  deploymentEnvs: DeploymentEnvironment[],
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.NAMESPACES, deploymentEnvs],
    queryFn: () => getClustersNamespaces(deploymentEnvs),
    ...queryDefaultOptions,
    ...options,
  });
};

export const useCloudDeploymentEnvs = (
  cloudId: string,
  view = 'all',
  includeInfraOnly = 'all',
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [
      QueryKeys.CLOUD_DEPLOYMENTENVIRONMENTS,
      cloudId,
      view,
      includeInfraOnly,
    ],
    queryFn: () => getCloudDeploymentEnvs(cloudId, view, includeInfraOnly),
    ...queryDefaultOptions,
    ...options,
  });
};
