import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dateUtils from '../../../lib/dates';
import images from '../../../images/images.js';
import { AppliedFilter, EventData } from '../../../models/master';
import EventsDataTable from './EventsDataTable';
import { Link } from 'react-router-dom';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import {
  getResourceTypeIcon,
  getResourceTypeUrl,
  resourceTypeIcon,
  resourceTypeUrl,
} from '../config';

import './EventsTable.scss';

interface TableRow {
  id: any;
  severity: JSX.Element;
  event_message: JSX.Element;
  status: any;
  resource_name: JSX.Element;
  assigned_to: string;
  time_event: string;
}

interface ExpendedTableRow {
  id: any;
  category: string;
  updated: string;
  labels: string[] | null;
  comment: string;
}

interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: string[];
}

const EventsTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: EventData[] | null;
  data: EventData[] | null;
  filteredDataSet: EventData[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  leftInlineFilters?: Filter[];
  visibilityFlag?: string;
  persistFilter?: boolean;
  error403Flag: boolean;
  eventsDataLoading: boolean;
  error500Flag: boolean;
}) => {
  const { t } = useTranslation('events');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;

  const tableRef = useRef<HTMLDivElement>(null);
  const { trackButtonClicked } = useAnalytics();

  const EventHeaders = [
    {
      key: 'severity',
      originalKey: 'severity',
      header: t('tableHeaders.severity'),
      sort: true,
    },
    {
      key: 'event_message',
      originalKey: 'message',
      header: t('tableHeaders.message'),
      sort: true,
    },
    {
      key: 'status',
      originalKey: 'event_status',
      header: t('tableHeaders.status'),
      sort: true,
    },
    {
      key: 'resource_name',
      originalKey: 'resource_name',
      header: t('tableHeaders.resourceName'),
      sort: true,
    },
    {
      key: 'assigned_to',
      originalKey: 'assigned_user',
      header: t('tableHeaders.assignedTo'),
      sort: true,
    },
    {
      key: 'time_event',
      originalKey: 'created_at',
      header: t('tableHeaders.eventTime'),
      sort: true,
    },
  ];

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {TableRow[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const setRowsData = () => {
    let formattedRows: TableRow[] = [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: EventData) => {
        const severityIcons = () => {
          if (row.severity === t('severityConditions.critical'))
            return images.criticalStatusIcon();
          else if (row.severity === t('severityConditions.minor'))
            return images.minorStatusIcon();
          else if (row.severity === t('severityConditions.major'))
            return images.majorStatusIcon();
          else if (row.severity === t('severityConditions.information'))
            return images.infoSmallEventIcon();
          else if (row.severity === t('severityConditions.cleared')) return '';
          else return '';
        };

        const boldItems =
          !row?.is_handled &&
          !row?.is_cleared &&
          row?.assigned_user === t('unassigned');

        const Icon = getResourceTypeIcon(
          row.resource_type as keyof typeof resourceTypeIcon
        );

        const detailsUrl = getResourceTypeUrl(
          row.resource_type as keyof typeof resourceTypeUrl
        );

        formattedRows.push({
          id: row.resource_id,
          severity: (
            <div
              className={`events-table-severity ${
                boldItems ? 'table-font-weight' : ''
              } ${row.severity === 'minor' ? 'severity-minor' : '—'}`}
            >
              {severityIcons()}{' '}
              {row.severity != null
                ? row.severity.charAt(0).toUpperCase() + row.severity.slice(1)
                : '—'}
            </div>
          ),
          event_message: (
            <div className='name-column-value line-break'>
              {!!row.message ? (
                <Link
                  className='no-underline-link'
                  onClick={() =>
                    trackButtonClicked(
                      analyticsData['Events'].events.openEvents.props,
                      analyticsData['Events'].events.openEvents.event
                    )
                  }
                  to={`/eventDetails?eventId=${row.resource_id}`}
                >
                  {row.message}
                </Link>
              ) : (
                '—'
              )}
            </div>
          ),
          status: !!row.event_status ? row.event_status : '—',
          resource_name: row?.resource_name ? (
            <>
              {detailsUrl ? (
                <Link
                  className='no-underline-link'
                  to={`/${detailsUrl}=${row.resource_instance}`}
                >
                  <div className='resource-name'>
                    {Icon} {row?.resource_name}
                  </div>
                </Link>
              ) : (
                <div className='resource-name'>
                  {Icon} {row?.resource_name}
                </div>
              )}
            </>
          ) : (
            <span>&#8212;</span>
          ),
          assigned_to:
            row.assigned_user === t('unassigned') ? '—' : row.assigned_user,
          time_event: !!row.created_at
            ? dateUtils.getUserFriendlyDate(row.created_at)
            : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const setExpandedRowsData = () => {
    let formattedRows: ExpendedTableRow[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: EventData) => {
        formattedRows.push({
          id: row.resource_id,
          category: !!row.category ? row.category : '—',
          updated: !!row.created_at
            ? dateUtils.getUserFriendlyDate(row.created_at)
            : '—',
          labels: row.labels,
          comment: !!row.comment ? row.comment : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };
  const emptyStateData = {
    icon: images.noEventsLarge(),
    notFoundIcon: images.NotFoundLarge(),
    header: t('emptyState.emptyContainerHeader'),
    description: t('emptyState.emptyContainerDescription'),
    click: () => {},
  };

  return (
    <div className='event-groups-table' ref={tableRef}>
      <EventsDataTable
        id='table-with-filter-panel'
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={EventHeaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: TableRow[]) =>
          props.filteredDataCallback(data)
        }
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: AppliedFilter[]) =>
          props.filtersAppliedCallback(data)
        }
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data: any, sortDirection: any) =>
          props.sortRows(data, sortDirection)
        }
        leftInlineFilters={props.leftInlineFilters}
        visibilityFlag={props.visibilityFlag}
        isRowsExpandable={true}
        rowsExpansionContent={setExpandedRowsData()}
        persistFilter={props.persistFilter}
        render403Container={props.error403Flag}
        eventsDataLoading={props.eventsDataLoading}
        render500Container={props.error500Flag}
      />
    </div>
  );
};

export default EventsTable;
