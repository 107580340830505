import './styles/App.scss';
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';

import {
  HeaderContainer,
  Header,
  HeaderMenuButton,
  HeaderName,
  Content,
  Dropdown,
  HeaderGlobalAction,
} from 'carbon-components-react';
import { Crossroads16 } from '@carbon/icons-react';
import { Popover, PopoverContent } from '@carbon/react';

import LandingPage from './pages/LandingPage/LandingPage';
import NavSidePanel from './pages/NavSidePanel';
import ToastNotification from './components/Notifications/Toast/Notification';
import ActionableNotification from './components/Notifications/Actionable/Notification';
import ApplicationDetailsPage from './pages/ApplicationDetails/ApplicationDetails';
import ApplicationsContainer from './pages/ApplicationsContainer/ApplicationsContainer';
import DeploymentEnvsContainer from './pages/DeploymentEnvsContainer/DeploymentEnvsContainer';
import ConnectionAccessPolicies from './pages/ConnectionAccessPolicies/ConnectionAccessPolicies';
import DeploymentEnvDetails from './pages/DeploymentEnvDetails/DeploymentEnvDetails';
import ConnectionAccessPolicyDetails from './pages/ConnectionAccessPolicies/ConnectionAccessPolicyDetails';
import ResourceGroupsContainer from './pages/ResourceGroups/ResourceGroupsContainer';
import TopologyPage from './pages/TopologyPage/TopologyPage';
import CloudDetails from './pages/CloudDetails/CloudDetails';
import LoginPage from './pages/LoginPage/LoginPage';
import LocationsContainer from './pages/Locations/LocationsContainer';
import LocationDetailsContainer from './pages/LocationDetails/LocationDetailsContainer';
import CloudContainer from './pages/Cloud/CloudContainer';
import PartitionDetails from './pages/PartitionDetails/PartitionDetails';
import ApplicationDeploymentDetails from './pages/ApplicationDeploymentDetails/ApplicationDeploymentDetails';
import APIKeyManager from './pages/APIKeyManager/APIKeyManager';
import SecretsContainer from './pages/SecretManager/SecretManagerContainer';
import Error403Page from './pages/Errors/Error403';
import Error404Page from './pages/Errors/Error404';
import Error500Page from './pages/Errors/Error500';
import GatewaysContainer from './pages/Gateways/GatewaysContainer';
import EventDetails from './pages/EventsDetails/EventDetails';
import EventsContainer from './pages/Events/EventsContainer';
import GatewayDetails from './pages/GatewayDetails/GatewayDetailsPage';
import IdentityDetails from './pages/IdentityDetails/IdentityDetails';
import RoleDetails from './pages/RoleDetails/RoleDetails';
import Logout from './pages/Logout/Logout';
import IdentityContainer from './pages/Identities/IdentitiesContainer';
import RolesContainer from './pages/Roles/RolesContainer';
import NetworkSegmentsContainer from './pages/NetworkSegmentsContainer/NetworkSegmentsContainer';
import NetworkSegmentsDetails from './pages/NetworkSegmentDetails/NetworkSegmentDetails';
import SubscriptionUsage from './pages/SubscriptionUsage/SubscriptionUsage';
import useAnalytics from './lib/useAnalytics';
import analyticsData from './lib/analyticsEventData';
import GenericResponsiveMiddleTruncation from './components/GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';

import {
  removeFiltersFromStorage,
  removeViewStateFromStorage,
} from './lib/utils';
import { handleInvalidateQueriesStorage } from './lib/invalidateQueriesFunctions';

import {
  SHOW_MAINTENANCE_BANNER,
  SHOW_TECH_PREVIEW_BANNER,
  SHOW_MESH_AGENT_UPGRADE_BANNER,
} from './config';
import './App.css';

const ALLOWED_IDLE_TIME = 360; //minutes

function App() {
  const { t } = useTranslation('app');
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
  const [openProfileMenu, setProfileMenuOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const { trackButtonClicked, resetAnalytics } = useAnalytics();

  useEffect(() => {
    if (!location?.pathname?.includes('callback')) {
      let formattedPath = '';
      if (location.pathname === '/') formattedPath = 'Home';
      else if (location.pathname?.length > 1) {
        const path = location.pathname?.slice(1) ?? '';
        formattedPath = path.charAt(0).toUpperCase() + path.slice(1);
      }
      window.ineum && window.ineum('page', formattedPath);
    }
  }, [location.pathname]);

  const userAccounts =
    JSON.parse(localStorage.getItem('AUTH_DETAILS'))?.map(
      account => account.account_name
    ) ?? [];

  const logoutMenu = [];
  if (userAccounts !== undefined) {
    logoutMenu.push(t('logout'));
  }

  const selectedAccount = localStorage?.SELECTED_ACCOUNT;
  const userData = JSON.parse(
    localStorage.getItem(`ACCOUNT_DETAILS_${selectedAccount}`)
  );

  const addScript = ({ src, id, onLoad }) => {
    const existing = document.getElementById(id);
    if (existing) {
      return existing;
    } else {
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.id = id;
      walkme.async = true;
      walkme.src = src;
      window._walkmeConfig = {
        smartLoad: true,
        segmentOptions: {
          trackWalkMeEvents: true,
          trackWalkMeUbtEvents: false,
        },
      };
      walkme.onload = () => {
        if (onLoad) {
          onLoad();
        }
      };

      document.body.appendChild(walkme);
      return walkme;
    }
  };

  const removeScript = async ({ id }) => {
    const script = document.getElementById(id);
    if (script) {
      await document.body.removeChild(script);
    }
  };

  const resouceItems = [
    { id: 1, text: t('resourceGroups') },
    { id: 2, text: t('apiKeys') },
    { id: 2, text: t('secrets') },
    { id: 3, text: t('subscriptionUsage') },
    { id: 3, text: t('identities') },
    { id: 4, text: t('roles') },
  ];

  async function handleAccountChange(key) {
    if (key === t('logout')) {
      trackButtonClicked(
        analyticsData['Login & Logout'].events.userLogout.props,
        analyticsData['Login & Logout'].events.userLogout.event
      );
      await removeScript({
        id: 'walk-me-script',
      });
      localStorage.removeItem(`ACCOUNT_DETAILS`);
      resetAnalytics();
      navigate('/logout');
    } else {
      window.localStorage.setItem('SELECTED_ACCOUNT', key);
      removeFiltersFromStorage();
      removeViewStateFromStorage();
      navigate(0);
    }
  }

  const updateLastActivity = () => {
    window.localStorage.setItem('lastActvity', new Date());
  };

  const handleInvalidateQuery = () => {
    handleInvalidateQueriesStorage(queryClient);
  };

  useEffect(() => {
    window.addEventListener('storage', handleInvalidateQuery);

    //  code to check if user is logged in by successfullly checking token in cookie.
    const authDetails = window.localStorage.getItem('SELECTED_ACCOUNT');

    // Setting desired URL in session storage to prevent loss on login
    if (
      !authDetails &&
      window.location.pathname &&
      !['callback', '403'].some(val => window.location.pathname.includes(val))
    ) {
      window.sessionStorage.setItem(
        'LOGIN_REDIRECT_URL',
        window.location.pathname + window.location.search
      );
    }

    return () => window.removeEventListener('storage', handleInvalidateQuery);
  }, []);

  useEffect(() => {
    //  code to check if user is logged in by successfullly checking token in cookie.
    const authDetails = window.localStorage.getItem('SELECTED_ACCOUNT');

    const channel = new BroadcastChannel('trigger-logout-channel');
    channel.addEventListener('message', event => {
      if (event.data === 'trigger-logout') {
        navigate('/logout');
        document.removeEventListener('mousemove', updateLastActivity);
        channel.close();
      }
    });

    let timeInterval;

    if (authDetails) {
      document.addEventListener('mousemove', updateLastActivity);

      timeInterval = setInterval(() => {
        const lastAcivity = localStorage.getItem('lastActvity');

        if (!lastAcivity) {
          // If there is no lastActvity key present in local storage, create one
          // Added to fix playwright tests from failing at the beginning
          updateLastActivity();
          return;
        }

        const diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
        const seconds = Math.floor(diffMs / 1000);
        const minute = Math.floor(seconds / 60);
        if (minute >= ALLOWED_IDLE_TIME) {
          //   'No activity from last 6 hours... Logging Out'
          clearInterval(timeInterval);
          navigate('/logout');
          channel.postMessage('trigger-logout');
          document.removeEventListener('mousemove', updateLastActivity);
        }
      }, 1000);
      if (process.env.REACT_APP_WALK_ME_URL)
        addScript({
          src: process.env.REACT_APP_WALK_ME_URL,
          id: 'walk-me-script',
          onLoad: () => {
            console.log('walk me script loaded!');
          },
        });
      setUserLoggedIn(true);
    }

    return () => {
      clearInterval(timeInterval);
      removeScript({
        id: 'walk-me-script',
      });
      document.removeEventListener('mousemove', updateLastActivity);
    };
  }, [userLoggedIn]);

  const handleLogin = () => {
    // Update last activity when user login
    updateLastActivity();
    setUserLoggedIn(true);
    if (process.env.REACT_APP_WALK_ME_URL)
      addScript({
        src: process.env.REACT_APP_WALK_ME_URL,
        id: 'walk-me-script',
        onLoad: () => {
          console.log('walk me script loaded!');
        },
      });
    window.localStorage.setItem(
      'showMaintenanceBanner',
      SHOW_MAINTENANCE_BANNER
    );
    window.localStorage.setItem(
      'showTechPreviewBanner',
      SHOW_TECH_PREVIEW_BANNER
    );
    window.localStorage.setItem(
      'showMeshAgentUpgradeBanner',
      SHOW_MESH_AGENT_UPGRADE_BANNER
    );
  };

  const handleLogout = () => {
    window.localStorage.clear();
    setUserLoggedIn(false);
    navigate('/');
  };

  function handleResourceItemChange(item) {
    removeFiltersFromStorage();
    if (item.text === t('resourceGroups')) {
      trackButtonClicked(
        analyticsData['Admin Dropdown'].events.openResourceGroup.props,
        analyticsData['Admin Dropdown'].events.openResourceGroup.event
      );
      navigate('/resourceGroups');
    } else if (item.text === t('apiKeys')) {
      trackButtonClicked(
        analyticsData['Admin Dropdown'].events.openApiKey.props,
        analyticsData['Admin Dropdown'].events.openApiKey.event
      );
      navigate('/apiKeyManager');
    } else if (item.text === t('secrets')) {
      trackButtonClicked(
        analyticsData['Admin Dropdown'].events.openSecrets.props,
        analyticsData['Admin Dropdown'].events.openSecrets.event
      );
      navigate('/secrets');
    } else if (item.text === t('identities')) {
      trackButtonClicked(
        analyticsData['Admin Dropdown'].events.openIdentities.props,
        analyticsData['Admin Dropdown'].events.openIdentities.event
      );
      navigate('/identities');
    } else if (item.text === t('roles')) {
      trackButtonClicked(
        analyticsData['Admin Dropdown'].events.openRoles.props,
        analyticsData['Admin Dropdown'].events.openRoles.event
      );
      navigate('/roles');
    } else if (item.text === t('subscriptionUsage')) {
      trackButtonClicked(
        analyticsData['Admin Dropdown'].events.openSubscriptionUsage.props,
        analyticsData['Admin Dropdown'].events.openSubscriptionUsage.event
      );
      navigate('/subscriptionUsage');
    }
    removeViewStateFromStorage();
  }

  const getFirstLetter = name => {
    const fNameLName = name.split('.');
    if (fNameLName.length > 1) {
      return `${fNameLName[0].charAt(0)}${fNameLName[1].charAt(
        0
      )}`.toLocaleUpperCase();
    } else {
      return fNameLName[0]?.substring(0, 2)?.toUpperCase();
    }
  };

  return userLoggedIn ? (
    <div className='app'>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header aria-label='IBM Platform Name'>
            <HeaderMenuButton aria-label='Open menu' isActive={true} />
            <HeaderName
              href='#'
              prefix='IBM'
              className='header-name'
              onClick={event => {
                event.preventDefault();
                navigate('/');
              }}
            >
              {t('platform')}
            </HeaderName>

            <div className='dropdown-alignment'>
              <div className='walkMe-section'>
                <HeaderGlobalAction aria-label='WalkMe' id='walkMeBtn'>
                  <Crossroads16 className='walkMe-icon' />
                </HeaderGlobalAction>
              </div>
              <div onFocus={() => openProfileMenu && setProfileMenuOpen(false)}>
                <Dropdown
                  light
                  id='inline'
                  className='resource-dropdown'
                  label={t('manage')}
                  type='inline'
                  size='sm'
                  items={resouceItems}
                  itemToString={item => (item ? item.text : '')}
                  onChange={e => handleResourceItemChange(e.selectedItem)}
                />

                <Dropdown
                  light
                  id='inline'
                  className='tenant-dropdown'
                  label={selectedAccount}
                  type='inline'
                  items={userAccounts}
                  onChange={e => handleAccountChange(e.selectedItem)}
                />
              </div>
              <div>
                <Popover
                  align='bottom-right'
                  isTabTip
                  open={openProfileMenu}
                  onRequestClose={() => {
                    setProfileMenuOpen(false);
                  }}
                >
                  <div
                    className='profile-icon-header'
                    onClick={e => {
                      e.stopPropagation();
                      setProfileMenuOpen(!openProfileMenu);
                    }}
                  >
                    {/* <IdentityTag name={userData?.username ?? ''} size='lg' /> */}
                    <div className='identity-profile-icon'>
                      {getFirstLetter(userData?.username ?? '')}
                    </div>
                  </div>
                  <PopoverContent>
                    <div className='popoverContent'>
                      <div className='profile-section'>
                        <div className='user-profile-image'>
                          <div className='identity-profile-icon-popover'>
                            {getFirstLetter(userData?.username ?? '')}
                          </div>
                        </div>
                        <div className='profile-details-section'>
                          <div className='profile-name'>
                            {userData?.username}
                          </div>
                          <div className='profile-email'>
                            {
                              <GenericResponsiveMiddleTruncation
                                str={userData?.user_id}
                              />
                            }
                          </div>
                        </div>
                      </div>
                      <div
                        className='logout-container'
                        onClick={() => handleAccountChange(t('logout'))}
                      >
                        <div className='popover-links'>
                          <span className='link-container'>
                            <span className='link_text'>{t('logout')}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <NavSidePanel
              isSideNavExpanded={isSideNavExpanded}
              onClickSideNavExpand={onClickSideNavExpand}
            />
          </Header>
        )}
      ></HeaderContainer>
      <Content className={'main-content'}>
        <Routes>
          <Route
            path='/'
            element={<LandingPage />} //localhost:3000/mcmnui
          />
          <Route
            path='/applicationDetails'
            element={<ApplicationDetailsPage />}
          />
          <Route path='/applications' element={<ApplicationsContainer />} />
          <Route
            path='/deploymentEnvironments'
            element={<DeploymentEnvsContainer />}
          />
          <Route
            path='/connectionAccessPolicies'
            element={<ConnectionAccessPolicies />}
          />
          <Route
            path='/deploymentEnvironmentDetails'
            element={<DeploymentEnvDetails />}
          />
          <Route
            path='/connectionAccessPolicyDetails'
            element={<ConnectionAccessPolicyDetails />}
          />
          <Route path='/resourceGroups' element={<ResourceGroupsContainer />} />
          <Route path='/topologies' element={<TopologyPage />} />
          <Route path='/locations' element={<LocationsContainer />} />
          <Route
            path='/locationDetails'
            element={<LocationDetailsContainer />}
          />
          <Route path='/clouds' element={<CloudContainer />} />
          <Route path='/partitionDetails' element={<PartitionDetails />} />
          <Route
            path='/applicationDeplDetails'
            element={<ApplicationDeploymentDetails />}
          />
          <Route path='/cloudDetails' element={<CloudDetails />} />
          <Route path='/apiKeyManager' element={<APIKeyManager />} />
          <Route path='/gateways' element={<GatewaysContainer />} />
          <Route path='/gatewayDetails' element={<GatewayDetails />} />
          <Route path='/secrets' element={<SecretsContainer />} />
          <Route path='/403' element={<Error403Page />} />
          <Route path='/404' element={<Error404Page />} />
          <Route path='/eventDetails' element={<EventDetails />} />
          <Route path='/event' element={<EventsContainer />} />
          <Route path='/identityDetails' element={<IdentityDetails />} />
          <Route path='/roleDetails' element={<RoleDetails />} />
          <Route path='/logout' element={<Logout onLogout={handleLogout} />} />
          <Route path='/identities' element={<IdentityContainer />} />
          <Route path='/roles' element={<RolesContainer />} />
          <Route
            path='/networkSegments'
            element={<NetworkSegmentsContainer />}
          />
          <Route
            path='/networkSegmentDetails'
            element={<NetworkSegmentsDetails />}
          />
          <Route path='/subscriptionUsage' element={<SubscriptionUsage />} />
        </Routes>
      </Content>
      <ToastNotification />
      <ActionableNotification />
    </div>
  ) : (
    <>
      <Routes>
        <Route path='/403' element={<Error403Page />} />
        <Route path='*' element={<LoginPage onLogin={handleLogin} />}></Route>
      </Routes>
      <ToastNotification />
    </>
  );
}

export default App;
