import { TFunction } from 'react-i18next';

export const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  resourceGroup: {
    value: null,
    error: false,
    errorMessage: '',
  },
  appResourceGroup: {
    value: {
      resource_id: 'default-app',
      name: 'Default_Application_Group',
    },
    error: false,
    errorMessage: '',
  },
  cloud: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  location: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
  intended_compute_profile: { value: '', error: false, errorMessage: '' },
  rhsi_compute_profile: {
    value: {
      profile: '',
      cpu_req: '',
      cpu_lim: '',
      mem_req: '',
      mem_lim: '',
    },
    error: false,
    errorMessage: '',
  },
  skupper_site_configuration: {
    ingress_type: {
      value: { id: '', label: '' },
      error: false,
      errorMessage: '',
    },
    ingress_hostname: { value: '', error: false, errorMessage: '' },
    router_ingress_hostname: { value: '', error: false, errorMessage: '' },
    controller_ingress_hostname: { value: '', error: false, errorMessage: '' },
    ingress_annotations: { value: '', error: false, errorMessage: '' },
    site_name: { value: '', error: false, errorMessage: '' },
    router_mode: {
      value: { id: 'interior', label: '' },
      error: false,
      errorMessage: '',
    },
    router_logging: {
      value: { id: 'info', label: '' },
      error: false,
      errorMessage: '',
    },
    annotations: { value: '', error: false, errorMessage: '' },
    router_service_annotations: { value: '', error: false, errorMessage: '' },
    router_pod_annotations: { value: '', error: false, errorMessage: '' },
    routers: { value: '', error: false, errorMessage: '' },
    router_data_connection_count: { value: '', error: false, errorMessage: '' },
    router_load_balancer_ip: { value: '', error: false, errorMessage: '' },
    create_network_policy: { value: false, error: false, errorMessage: '' },
    enable_service_sync: { value: true, error: false, errorMessage: '' },
    router_cpu_limit: { value: '', error: false, errorMessage: '' },
    router_cpu: { value: '', error: false, errorMessage: '' },
    router_memory_limit: { value: '', error: false, errorMessage: '' },
    router_memory: { value: '', error: false, errorMessage: '' },
    labels: { value: [], error: false, errorMessage: '' },
  },
  version: { value: '', error: false, errorMessage: '' },
  deployed_in_partition_id: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  deployed_in_depl_env_id: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  deployed_in_type: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

export const getRouterModes = (
  t: TFunction<'gatewaysContainer', undefined>
) => {
  return [
    { id: 'interior', label: t('gatewayDetailsForm.routerMode.interior') },
    { id: 'edge', label: t('gatewayDetailsForm.routerMode.edge') },
  ];
};

export const getRouterLogging = (
  t: TFunction<'gatewaysContainer', undefined>
) => {
  return [
    { id: 'trace', label: t('gatewayDetailsForm.routerLogging.trace') },
    { id: 'debug', label: t('gatewayDetailsForm.routerLogging.debug') },
    { id: 'info', label: t('gatewayDetailsForm.routerLogging.info') },
    { id: 'notice', label: t('gatewayDetailsForm.routerLogging.notice') },
    { id: 'warning', label: t('gatewayDetailsForm.routerLogging.warning') },
    { id: 'error', label: t('gatewayDetailsForm.routerLogging.error') },
  ];
};
