import {
  Resouce,
  Location,
  LocationConnection,
  TopologyItem,
  TopologyEntityType,
  Coordinate,
  EdgeType,
} from './types';

const getResouceType = (entityType: TopologyEntityType) => {
  const types = {
    deplenv: 'deployment_env',
    deployment: 'application',
    servicedeployment: 'service',
    gateway: 'gateway',
    partition: 'partition',
  };

  return (types as any)[entityType];
};

interface AllowedTypes {
  isSkupperGateway: boolean;
}

/**
 * To check whether we need to traverse certain edge type
 */
const getAllowedEdgeType = (edgeType: EdgeType, allowedTypes: AllowedTypes) => {
  const types = {
    CLOUD_HAS_LOCATION: true,

    LOCATION_HAS_DE: true,

    DE_HAS_DEPL: true,
    DE_HAS_GW: true,
    DE_HAS_PART: true,

    DEPL_HAS_SVCD: true,
    DEPL_HAS_INSTANCE: false,

    PART_HAS_DEPL: true,

    PART_HAS_GW: allowedTypes.isSkupperGateway ? true : false,

    APP_HAS_SVC: false,
    APP_HAS_DEPL: false,

    SVC_HAS_SVCD: false,

    CONN_BY_POLICY: false,

    HAS_REMOTECONNECTION: false,
  };

  return (types as any)[edgeType];
};

const coordinatesUsed: {
  [key: string]: Coordinate;
} = {};

const getCoordinates = (locationId: string, location: string | undefined) => {
  if (location) {
    const [lng = '', lat = ''] = location.split(',');
    coordinatesUsed[locationId] = {
      lng: Number(lng.trim()),
      lat: Number(lat.trim()),
    };
    return coordinatesUsed[locationId];
  }

  return null;
};

/**
 * Response convertor that transform flat topology resouces to hierarchy data.
 */
export const generateToplogyHierarchy = (items: TopologyItem[]) => {
  const resouceMap: { [key: string]: Resouce } = {};
  const locations: Location[] = [];
  const locationIds: string[] = [];
  const gateways: Resouce[] = [];
  const connections: LocationConnection[] = [];
  const policies: Resouce[] = [];
  const namespaces: Resouce[] = [];
  const dataFlows: Resouce[] = [];
  const deployments: Resouce[] = [];
  const networkSegmentLocationMap: { [key: string]: string[] } = {};
  const networkSegmentDeplEnvMap: { [key: string]: string[] } = {};

  // Loop over the topology resouces once and create map of resouce id and resouce details and array of locations
  if (Array.isArray(items)) {
    for (const item of items) {
      resouceMap[item.resource_id] = item as Resouce;

      if (item.entityType === 'location') {
        locations.push(item as Location);
      }

      if (item.entityType === 'policy') {
        policies.push(item as Resouce);
      }

      if (
        item.entityType === 'partition' &&
        item.resource_id.includes('namespace')
      ) {
        namespaces.push(item as Resouce);
      }

      if (item.entityType === 'deployment') {
        deployments.push(item as Resouce);
      }
    }
  }

  const traverse = (
    resource: Resouce,
    locationId: string,
    locationUniqueId: string,
    cloudId: string
  ) => {
    resource.uniqueId = resource.resource_id;
    resource.location_id = locationId;
    resource.location_uniqueId = locationUniqueId;
    resource.cloud_id = cloudId;
    resource.children = [];
    if (Array.isArray(resource._references)) {
      for (const reference of resource._references) {
        const childItem = resouceMap[reference._toUniqueId];

        if (childItem) {
          childItem._type = getResouceType(childItem.entityType);

          const isSkupperGateway =
            childItem._type === 'gateway' && childItem.subtype === 'RHSI-EDGE';

          const edgeType = getAllowedEdgeType(reference._edgeType, {
            isSkupperGateway,
          });
          // Set default segment to partition and gateway if it doesnot exists
          if (childItem._type === 'partition') {
            childItem.network_segment_id =
              childItem.network_segment_id || 'default-network-segment';
          } else if (childItem._type === 'gateway') {
            // Skupper gateways should have network segment of its namespace. All other gateways will have default
            childItem.network_segment_id = isSkupperGateway
              ? resource.network_segment_id
              : 'default-network-segment';
          }

          // If resouce item is deployment env and if its child is application and if that application has partition_id
          // then we can skip that application as it will be rendered under its partition
          if (resource._type === 'deployment_env') {
            if (childItem._type === 'application' && childItem.partition_id) {
              continue;
            }

            // Create Network segment map for location and deployment environment to filter by network segment
            const networkSegmentId =
              resource.intrinsicType === 'cluster' &&
              childItem._type === 'partition' &&
              childItem.network_segment_id
                ? childItem.network_segment_id
                : 'default-network-segment';
            if (Array.isArray(networkSegmentLocationMap[locationId]))
              networkSegmentLocationMap[locationId].push(networkSegmentId);
            else networkSegmentLocationMap[locationId] = [networkSegmentId];
            if (Array.isArray(networkSegmentDeplEnvMap[resource.resource_id]))
              networkSegmentDeplEnvMap[resource.resource_id].push(
                networkSegmentId
              );
            else
              networkSegmentDeplEnvMap[resource.resource_id] = [
                networkSegmentId,
              ];
            networkSegmentLocationMap[locationId] = Array.from(
              new Set(networkSegmentLocationMap[locationId])
            );
            networkSegmentDeplEnvMap[resource.resource_id] = Array.from(
              new Set(networkSegmentDeplEnvMap[resource.resource_id])
            );
          }
          if (reference._edgeType === 'DEPL_HAS_FLOW') {
            dataFlows.push(resource);
          }

          if (childItem._type === 'gateway' && edgeType) {
            childItem.uniqueId = childItem.resource_id;
            childItem.location_id = locationId;
            childItem.location_uniqueId = locationUniqueId;
            childItem.cloud_id = cloudId;
            gateways.push({ ...childItem });
            // We don't need to continue traversing gateway's references since it causes circular loop
            resource.children.push(childItem);
            continue;
          }

          // The childItem.type guard ensure that we only traverse resouce type that need to render in the topology.
          // We don't need to traverse resouce with entity type Cloud, RemoteConnection, App, Instance, Service and ServiceEndPoint.
          // Also we don't need to traverse certain edge type.
          if (childItem._type && edgeType) {
            // Note: Inorder to call service API we need application id of that service. Since that information is missing, for now
            // we are manually adding it.
            if (resource._type === 'application') {
              childItem.application_id = resource.application_id;
              childItem.application_name = resource.name;
            }
            resource.children.push(
              traverse(childItem, locationId, locationUniqueId, cloudId)
            );
          }
        }
      }
      // We need to store network segment map even if there are no children for a location or deployment environment
      if (
        resource._references.length === 0 &&
        (resource._type === 'deployment_env' ||
          resource.entityType === 'location')
      ) {
        const networSegmentId = 'default-network-segment';
        if (Array.isArray(networkSegmentLocationMap[locationId]))
          networkSegmentLocationMap[locationId].push(networSegmentId);
        else networkSegmentLocationMap[locationId] = [networSegmentId];
        if (Array.isArray(networkSegmentDeplEnvMap[resource.resource_id]))
          networkSegmentDeplEnvMap[resource.resource_id].push(networSegmentId);
        else networkSegmentDeplEnvMap[resource.resource_id] = [networSegmentId];
        networkSegmentLocationMap[locationId] = Array.from(
          new Set(networkSegmentLocationMap[locationId])
        );
        networkSegmentDeplEnvMap[resource.resource_id] = Array.from(
          new Set(networkSegmentDeplEnvMap[resource.resource_id])
        );
      }
    }

    return resource;
  };

  // Traverse each location one by one and create hierarchical data structure around it.
  // Note: We are considering Location as top level entity (ie root) not Cloud.
  for (const location of locations) {
    location._type = 'location';
    location.lngLat = getCoordinates(
      location.resource_id,
      location.geo_coordinates
    );
    locationIds.push(location.resource_id);
    traverse(
      location as any,
      location.resource_id,
      location.resource_id,
      location.cloud_id
    );
  }

  // To generate location to location connection and the external connections that is rendered by react-xarrows
  for (const deployment of deployments) {
    const { location_id: sourceLocationId } = deployment;

    if (deployment?.application_id) {
      const application = resouceMap[deployment.application_id];
      deployment['network_segment_id'] =
        application?.network_segment_id ?? 'default-network-segment';
    }

    const sourceGeoCoordinates = coordinatesUsed[sourceLocationId] ?? {};
    const source = {
      resource_id: sourceLocationId,
      ...sourceGeoCoordinates,
    };

    if (Array.isArray(deployment._references)) {
      for (const reference of deployment._references) {
        const toService = resouceMap[reference._toUniqueId];

        if (toService && reference?._edgeType === 'DEPL_HAS_FLOW') {
          const { location_id: targetLocationId } = toService;
          const targetGeoCoordinates = coordinatesUsed[targetLocationId] ?? {};

          // For location to location connection via mapbox line layer
          const target = {
            resource_id: targetLocationId,
            ...targetGeoCoordinates,
          };
          connections.push({ source, target });

          // For app to location connection via react-xarrows
          const fromExternalConnection = {
            location_id: targetLocationId,
            resource_id: toService.resource_id,
            label: '', // TODO: We need to replace empty string with property that we need to show as react-xarrows's labels
          };

          // For service to location connection via react-xarrows
          const toExternalConnection = {
            location_id: sourceLocationId,
            resource_id: deployment.resource_id,
            label: '', // TODO: We need to replace empty string with property that we need to show as react-xarrows's labels
          };

          deployment.connections = Array.isArray(deployment.connections)
            ? deployment.connections.concat(fromExternalConnection)
            : [fromExternalConnection];

          toService.connections = Array.isArray(toService.connections)
            ? toService.connections.concat(toExternalConnection)
            : [toExternalConnection];
        }
      }
    }
  }

  return {
    locations,
    locationIds,
    connections,
    resouceMap,
    policies,
    namespaces,
    gateways,
    dataFlows,
    networkSegmentLocationMap,
    networkSegmentDeplEnvMap,
  };
};
