import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Grid32, List32 } from '@carbon/icons-react';
import {
  Button,
  Column,
  ContentSwitcher,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { useNavigate } from 'react-router-dom';
import ResourceCard from '../../components/ApplicationCard/ResourceCard';
import ConnectionAccessPolicyTable from '../../components/ConnectionAccessPolicyTable/ConnectionAccessPolicyTable';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import dateUtils from '../../lib/dates';
import sortData from '../../lib/tableSort';
import { manageFiltersFromStorage } from '../../lib/utils';
import CreatePolicy from './ConnectionAccessPoliciesCreate/CreatePolicy';
import { getDeploymentEnvs } from '../../controllers/deploymentEnv';
import { getPolicies } from '../../controllers/policyApi';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import {
  getApplicationServices,
  getApplications,
} from '../../controllers/applicationApis.js';
import {
  getDeploymentEnvsPartitions,
  getPartitions,
} from '../../controllers/deploymentEnv';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';

import { FlexGrid } from '@carbon/react';
import { AxiosError } from 'axios';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import Error403Card from '../../components/ErrorState/Error403Card';
import Error500Card from '../../components/ErrorState/Error500Card';
import useAnalytics from '../../lib/useAnalytics';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';
import images from '../../images/images';
import {
  ApplicationData,
  AppliedFilter,
  PolicyData,
  ResourceGroup,
  Service,
  PartitionData,
  NetworkSegment,
  Error500Type,
} from '../../models/master';
import { TableTypes } from './config';

import './ConnectionAccessPolicies.scss';
import analyticsData from '../../lib/analyticsEventData';
import GenericResponsiveMiddleTruncation from '../../components/GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

type LocationState = {
  resourceType: string;
  navigateBack: boolean;
};

const defaultPermissionMap = {
  policy: true,
  applications: true,
  service: true,
  resourceGroup: true,
  partitions: true,
  networkSegments: true,
};
interface currentPermissionMap {
  applications: boolean;
  service: boolean;
  resourceGroup: boolean;
  policy: boolean;
  partitions: boolean;
  networkSegments: boolean;
}
interface ServiceData extends Service {
  id: string;
  applicationName: string;
}
type View = 'table' | 'card';

const ConnectionAccessPolicies: React.FC = () => {
  const { t } = useTranslation('connectionAccessPolicies');

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [policies, setPolicies] = useState<PolicyData[] | [] | null>(null);
  const [
    showCreateConnectionAccessPolicy,
    setShowCreateConnectionAccessPolicy,
  ] = useState(false);
  const [policyDataLoading, togglePolicyDataLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<PolicyData[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [applications, setApplications] = useState<ApplicationData[] | null>(
    null
  );
  const [partitions, setPartitions] = useState<PartitionData[] | null>(null);
  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[] | null>(
    null
  );
  const [services, setServices] = useState<ServiceData[] | null>(null);
  const location = useLocation();
  const state = location.state as LocationState;
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const navigate = useNavigate();

  const { pageViewed, trackButtonClicked } = useAnalytics();

  const fetchPolicyAppServices = async (
    policies: PolicyData[],
    servicePermission: boolean,
    applicationPermission: boolean
  ) => {
    try {
      let response: ApplicationData[] = [];
      try {
        response = await getApplications(false, false);
        setApplications(response);
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 403) {
          applicationPermission = false;
        }
        console.error(error);
      }

      const policyToAppIds = policies?.map(
        policy => policy?.to?.service?.application_id
      );
      const uniquePolicyToAppIds = Array.from(new Set(policyToAppIds));
      const services: ServiceData[] = [];
      for (const appId of uniquePolicyToAppIds) {
        try {
          const response = await getApplicationServices(appId, true);
          services.push(...response?.svcs);
        } catch (error) {
          console.log(error);
        }
      }
      setPermissionMap({
        ...permissionMap,
        applications: applicationPermission,
        service: applicationPermission,
      });

      return {
        applications: response,
        services,
        servicePermission,
        applicationPermission,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPolicyPartitions = async (policies: PolicyData[]) => {
    try {
      const policyToDeplEnvIds = policies?.map(
        policy => policy?.from?.deployment_env?.deployment_env_id
      );
      const uniquePolicyToDeplEnvIds = Array.from(new Set(policyToDeplEnvIds));

      const partitions: PartitionData[] = [];
      let partitionsPermission = permissionMap.partitions;
      for (const deplEnvId of uniquePolicyToDeplEnvIds) {
        if (deplEnvId) {
          try {
            const response = await getPartitions(deplEnvId);
            partitions.push(
              ...response?.partitions?.map((partition: PartitionData) => {
                return { ...partition, deploymentEnvId: deplEnvId };
              })
            );
          } catch (error) {
            const err = error as AxiosError;
            if (err.response?.status === 403) {
              partitionsPermission = false;
            }
          }
        }
      }
      setPartitions(partitions);

      return { partitions, partitionsPermission };
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      setPolicies(null);
      setSortKey('');
      setSortDirection('NONE');
      setFilterApplied(manageFiltersFromStorage());

      let policies;
      let currentPermissionMap = permissionMap;
      let networkSegments: NetworkSegment[] = [];
      try {
        networkSegments = await getNetworkSegments();
        if (!currentPermissionMap.networkSegments) {
          currentPermissionMap = {
            ...currentPermissionMap,
            networkSegments: true,
          };
          setPermissionMap(currentPermissionMap);
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err?.response?.status === 403) {
          currentPermissionMap = {
            ...currentPermissionMap,
            networkSegments: false,
          };
          setPermissionMap(currentPermissionMap);
        }
      }
      let resourceGroupResponse: { resource_groups: ResourceGroup[] } | any =
        [];
      let resourceGroupList = [];

      // Since right now we are not rendering deployment table we no need to fetch it.
      // fetchDeployments();

      try {
        policies = await getPolicies(false, false);
        if (!permissionMap.policy) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            policy: true,
          }));
        }

        if (error500) {
          setError500(null);
        }

        try {
          resourceGroupResponse = await getResourceGroups(true);
          resourceGroupList = resourceGroupResponse?.resource_groups ?? [];
          setResourceGroups(resourceGroupList);
          if (!currentPermissionMap.resourceGroup) {
            currentPermissionMap = {
              ...currentPermissionMap,
              resourceGroup: true,
            };
            setPermissionMap(currentPermissionMap);
          }
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            currentPermissionMap = {
              ...currentPermissionMap,
              resourceGroup: false,
            };
            setPermissionMap(currentPermissionMap);
          }
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 403) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            policy: false,
          }));
        }

        if (err.response!?.status >= 500) {
          setError500(err.response!?.status?.toString() as Error500Type);
        }

        console.error(error);
      }

      if (policies) {
        let servicePermission = permissionMap.service;
        let applicationPermission = permissionMap.applications;
        let appServiceData;
        let partitionsData;
        try {
          // On the page load we are fetching only the app services present in the To session of policy to avoid unwanted network calls.
          appServiceData = await fetchPolicyAppServices(
            policies,
            servicePermission,
            applicationPermission
          );
          // On the page load we are fetching only the partitions present in the policy to avoid unwanted network calls.
          partitionsData = await fetchPolicyPartitions(policies);
          let pertitionPermissions =
            partitionsData?.partitionsPermission as boolean;
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            partitions: pertitionPermissions,
          }));
        } catch (error) {
          console.error(error);
        }

        const formattedPolicies = getFormattedPolicies(
          policies as PolicyData[],
          resourceGroupList,
          networkSegments,
          appServiceData?.applications,
          appServiceData?.services,
          partitionsData?.partitions,
          currentPermissionMap,
          appServiceData?.applicationPermission as boolean,
          partitionsData?.partitionsPermission as boolean
        );
        setPolicies(formattedPolicies as PolicyData[]);
      }
    } catch (error: any) {
      setPolicies([]);
    } finally {
      togglePolicyDataLoading(false);
    }
  };

  const getFormattedPolicies = (
    policies: PolicyData[],
    resourceGroupList: ResourceGroup[] | null,
    networkSegmentList: NetworkSegment[],
    applications: ApplicationData[] | undefined,
    services: ServiceData[] | undefined,
    partitions: PartitionData[] | undefined,
    currentPermissionMap: currentPermissionMap,
    applicationPermission: boolean,
    partitionsPermission: boolean
  ) => {
    if (Array.isArray(policies)) {
      for (const policy of policies) {
        const fromAppId = policy?.from?.application?.application_id;
        const svcId = policy?.to?.service?.service_id;
        const partitionId = policy?.from?.partition?.partition_id;
        const deploymentEnvId = policy?.from?.deployment_env?.deployment_env_id;
        const fromNetworkSegmentId =
          policy?.from?.network_segment?.network_segment_id;
        const type = policy?.from?.type;
        const resourceGroup = resourceGroupList?.find(
          resource => resource.resource_id === policy?.resource_group_id
        );
        const policyNetworkSegment =
          policy?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID
            ? defaultNetworkSegmentObject
            : networkSegmentList?.find(
                segments => segments.resource_id === policy?.network_segment_id
              ) ?? null;
        const application = applications?.find(
          application => application.resource_id === fromAppId
        );
        const service = services?.find(
          service => service.resource_id === svcId
        );
        const partition = partitions?.find(
          partition =>
            partition.resource_id === partitionId &&
            partition.deploymentEnvId === deploymentEnvId
        );

        const fromNetworkSegment =
          fromNetworkSegmentId === DEFAULT_NETWORK_SEGMENT_ID
            ? defaultNetworkSegmentObject
            : networkSegmentList?.find(
                segments => segments.resource_id === fromNetworkSegmentId
              ) ?? null;

        const getFromPermission = () => {
          if (type === 'application') {
            return applicationPermission
              ? !!application || policy.status === 'orphan'
              : false;
          } else if (type === 'partition') {
            return partitionsPermission
              ? !!partition || policy.status === 'orphan'
              : false;
          } else if (type === 'networkSegment') {
            return currentPermissionMap?.networkSegments;
          } else {
            return true;
          }
        };

        policy.network_segment_name = policyNetworkSegment?.name ?? '—';
        policy.resource_group_name = resourceGroup?.name ?? '—';
        policy.encryption = policy?.use_encrypted_path ? t('yes') : t('no');
        policy.from_app_name =
          type === 'application'
            ? application?.name || ''
            : type === 'partition'
            ? partition?.name || ''
            : type === 'networkSegment'
            ? fromNetworkSegment?.name || ''
            : '';
        policy.to_svc_name = service?.name ?? '';
        policy.resourceGroupsPermission = currentPermissionMap?.resourceGroup
          ? !!resourceGroup
          : false;
        policy.fromPermission = getFromPermission();
        policy.servicePermission =
          service || policy.status === 'orphan' ? true : false;
        policy.networkSegmentPermission = currentPermissionMap?.networkSegments
          ? !!policyNetworkSegment
          : false;
        policy.network_segment_id = policy?.network_segment_id ?? '';
      }
    }
    return policies;
  };

  const fetchApplications = async () => {
    let currentPermissionMap = permissionMap;
    try {
      // We don't need to fetch the app deployment
      const response: ApplicationData[] = await getApplications(false, true);
      setPermissionMap({
        ...permissionMap,
        applications: true,
        service: response?.[0]?.servicePermission ?? true,
      });
      const services = [];
      for (const application of response) {
        const applicationService = application.services.map(srv => ({
          ...srv,
          id: `${srv.resource_id}+${application.app_identity}`,
          ports: srv.ports,
          applicationName: application.name,
          application_id: application.resource_id,
          network_segment_id: application?.network_segment_id,
        }));
        services.push(...applicationService);
      }

      setApplications(response);
      setServices(services);

      return {
        applications: response,
        services,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        currentPermissionMap = {
          ...currentPermissionMap,
          applications: false,
          service: false,
        };
        setPermissionMap(currentPermissionMap);
      }
      console.error(error);
      return [];
    }
  };

  const fetchDeploymentEnvsPartitions = async () => {
    let currentPermissionMap = permissionMap;
    try {
      const envsData = await getDeploymentEnvsPartitions();
      if (!currentPermissionMap.partitions) {
        currentPermissionMap = {
          ...currentPermissionMap,
          partitions: true,
        };
        setPermissionMap(currentPermissionMap);
      }
      const partitions = [];
      for (const deplEnv of envsData) {
        const envPartitions = deplEnv.partitions.map((partition: any) => ({
          ...partition,
          id: `${partition.resource_id}`,
          resource_group_id: deplEnv.resource_group_id,
          resourceGroups: deplEnv.resource_group_id,
          deploymentEnvId: deplEnv.resource_id,
          cloud_name: deplEnv.cloud_name,
          location_name: deplEnv.location_name,
          environment: deplEnv.name,
        }));
        partitions.push(...envPartitions);
      }
      setPartitions(partitions as PartitionData[]);
      return partitions;
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        currentPermissionMap = {
          ...currentPermissionMap,
          partitions: false,
        };
        setPermissionMap(currentPermissionMap);
      }
      console.error(error);
      return [];
    }
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (Array.isArray(policies)) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedPolicies = Array.isArray(policies)
          ? policies.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setPolicies([...sortedPolicies]);
      } else {
        setSortDirection('DESC');
        const sortedPolicies = Array.isArray(policies)
          ? policies.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setPolicies([...sortedPolicies]);
      }
    }
  };

  const handleRefresh = (type: TableTypes) => {
    switch (type) {
      case 'applications': {
        setApplications(null);
        return fetchApplications();
      }
      case 'services': {
        setServices(null);
        return fetchApplications();
      }
      case 'partitions': {
        setPartitions(null);
        return fetchDeploymentEnvsPartitions();
      }
    }
  };

  useEffect(() => {
    if (state?.resourceType === 'POLICY') {
      setShowCreateConnectionAccessPolicy(true);
    }
  }, [state]);

  useEffect(() => {
    pageViewed('Policies');
    togglePolicyDataLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    Array.isArray(policies)
      ? togglePolicyDataLoading(false)
      : togglePolicyDataLoading(true);
  }, [policies]);

  const goToCreatePolicy = () => {
    trackButtonClicked(
      analyticsData['Policies'].events.createPolicyBtn.props,
      analyticsData['Policies'].events.createPolicyBtn.event
    );
    // Since initially we are only fetching the services and partitions associated with listed policies,
    // we need to fetch all the services and partitions when we open create policy form.
    handleRefresh('services');
    handleRefresh('partitions');

    setShowCreateConnectionAccessPolicy(true);
  };

  const removeDuplicateNetworkSegments = () => {
    let networkSegmentElements = new Set(
      policies
        ?.filter(policy => policy?.network_segment_name !== undefined)
        .map((item: any) => item.network_segment_name)
    );
    let segmentElements = Array.from(networkSegmentElements);
    return segmentElements;
  };

  const leftInlineMultiSelectFilter: any = [
    {
      key: 'policyNetworkSegment',
      type: 'multi',
      values: removeDuplicateNetworkSegments().map(item => {
        return {
          label: item,
          value: item,
        };
      }) as any,
      placeHolderText: removeDuplicateNetworkSegments().length,
    },
  ];

  const setTableFilters = () => [
    {
      key: 'applicationGroupPolicy',
      type: 'single',
      label: t('filter.applicationGroup'),
      placeHolderText: t('filter.applicationGroupPlaceholder'),
      values: [
        ...Array.from(
          new Set(
            policies
              ?.filter(policy => policy?.resource_group_name !== undefined)
              ?.map(policy => policy?.resource_group_name)
          )
        ),
      ],
    },
    {
      key: 'from',
      type: 'single',
      label: t('filter.from'),
      placeHolderText: t('filter.fromPlaceholder'),
      values: [
        ...Array.from(
          new Set(
            policies
              ?.filter(
                policy =>
                  policy?.from_app_name && policy?.from_app_name !== undefined
              )
              ?.map(policy => policy?.from_app_name)
          )
        ),
      ],
    },
    {
      key: 'to',
      type: 'single',
      label: t('filter.to'),
      placeHolderText: t('filter.toPlaceholder'),
      values: [
        ...Array.from(
          new Set(
            policies
              ?.filter(
                policy =>
                  policy?.to_svc_name && policy?.to_svc_name !== undefined
              )
              ?.map(policy => policy?.to_svc_name)
          )
        ),
      ],
    },
    {
      key: 'labels',
      type: 'multi',
      label: t('filter.labels'),
      placeHolderText: t('filter.labelsPlaceholder'),
      values: [
        ...Array.from(
          new Set(
            (policies ? policies : []).map(policy => policy?.labels).flat()
          )
        ),
      ],
    },
  ];

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={policies ? policies : []}
        filteredDataCallback={data =>
          setFilteredData(data as PolicyData[] | [])
        }
        persistFilter
        filteredData={
          policies ? (filterApplied.length > 0 ? filteredData : policies) : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={setTableFilters() as any}
        onRefresh={() => fetchData()}
        leftInlineMultiSelectFilter={leftInlineMultiSelectFilter}
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const closeCreatePolicyTearSheet = () => {
    setShowCreateConnectionAccessPolicy(false);
    if (state?.navigateBack) {
      navigate(-1);
    }
  };

  const learnMore = {
    url:
      navigator.language === 'en-US'
        ? 'https://ibm.biz/mesh-policies'
        : 'https://ibm.biz/mesh-policies-fr',
    label: t('learnMore'),
  };

  return (
    <div className='connection-access-policies-container'>
      <Header
        title={t('title')}
        subTitle={t('subTitle')}
        learnMoreLink={learnMore}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            name: 'createPolicy',
            onClick: () => goToCreatePolicy(),
            text: t('createPolicy'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='policies-switcher'>
          {view !== 'table' ? (
            <>
              {policyDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='development-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <ConnectionAccessPolicyTable
              rows={
                Array.isArray(policies)
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(policies, sortKey, sortDirection)
                  : null
              }
              elementCount={
                policies
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : policies.length
                  : 0
              }
              filteredDataSet={
                policies
                  ? filterApplied.length > 0
                    ? filteredData
                    : policies
                  : null
              }
              data={policies}
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              onPageChange={pageData => setPageChange(pageData)}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              filteredDataCallback={data =>
                data && setFilteredData(data as PolicyData[] | [])
              }
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              persistFilter
              onRefresh={() => fetchData()}
              filters={setTableFilters()}
              error403Flag={!permissionMap['policy']}
              error500Flag={!!error500}
              leftInlineMultiSelectFilter={leftInlineMultiSelectFilter}
            />
          ) : !permissionMap['policy'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : policyDataLoading ? (
            <div>
              {!policies && renderFilter()}
              <div className='skeleton-card-view'>
                <SkeletonPlaceholder className={'policy-skeleton'} />
                <SkeletonPlaceholder className={'policy-skeleton'} />
                <SkeletonPlaceholder className={'policy-skeleton'} />
                <SkeletonPlaceholder className={'policy-skeleton'} />
              </div>
            </div>
          ) : (
            <div>
              {policies && policies.length >= 0 ? renderFilter() : null}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-row'>
                    {Array.isArray(policies) &&
                    (filterApplied.length > 0 ? filteredData : policies)
                      .length > 0 ? (
                      Array.isArray(policies) &&
                      (filterApplied.length > 0 ? filteredData : policies).map(
                        policy => (
                          <Column lg={4} md={4} className='resource-card'>
                            <ResourceCard
                              key={policy.resource_id}
                              resourceType={'POLICY'}
                              header={policy.name}
                              subheader={'subheader'}
                              tags={policy.labels}
                              data={{
                                from_app_name: !policy.fromPermission ? (
                                  <GenericStatusField
                                    status={'notAuthorised'}
                                  />
                                ) : !!policy.from_app_name ? (
                                  <GenericResponsiveMiddleTruncation
                                    str={policy.from_app_name}
                                    isFullWidthText={true}
                                  />
                                ) : (
                                  '—'
                                ),
                                to_svc_name: !policy.servicePermission ? (
                                  <GenericStatusField
                                    status={'notAuthorised'}
                                  />
                                ) : !!policy.to_svc_name ? (
                                  <GenericResponsiveMiddleTruncation
                                    str={policy.to_svc_name}
                                    isFullWidthText={true}
                                  />
                                ) : (
                                  '—'
                                ),
                                network_segment_name:
                                  !policy.networkSegmentPermission ? (
                                    <GenericStatusField
                                      status={'notAuthorised'}
                                    />
                                  ) : (
                                    policy.network_segment_name
                                  ),
                                resource_group_name:
                                  !policy.resourceGroupsPermission ? (
                                    <GenericStatusField
                                      status={'notAuthorised'}
                                    />
                                  ) : (
                                    policy?.resource_group_name ?? '—'
                                  ),
                                from_type: policy.from?.type,
                              }}
                              updated={dateUtils.getUserFriendlyDate(
                                policy.updated_at
                              )}
                              path={`/connectionaccesspolicydetails?policyId=${policy.resource_id}`}
                            />
                          </Column>
                        )
                      )
                    ) : (
                      <CardEmptyState
                        filterApplied={filterApplied}
                        emptyState={{
                          icon: images.noPoliciesIcon(),
                          header: t('emptyState.emptyContainerHeader'),
                          description: t(
                            'emptyState.emptyContainerDescription'
                          ),
                        }}
                      />
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>

      {showCreateConnectionAccessPolicy && (
        <CreatePolicy
          open={showCreateConnectionAccessPolicy}
          applications={applications}
          services={services}
          partitions={partitions}
          resourceGroups={resourceGroups}
          onClose={closeCreatePolicyTearSheet}
          onAdd={() => fetchData()}
          policyList={policies}
          partitionPermission={permissionMap['partitions']}
          applicationPermission={permissionMap['applications']}
          servicePermission={permissionMap['service']}
          onRefresh={(t: TableTypes) => handleRefresh(t)}
        />
      )}
    </div>
  );
};

export default ConnectionAccessPolicies;
