import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SkeletonPlaceholder,
  DataTableSkeleton,
  Tile,
  Column,
  Toggle,
  Modal,
  Button,
  TooltipDefinition,
} from 'carbon-components-react';
import Header from '../../components/Header/Header';
import images from '../../images/images';

import './PartitionDetails.scss';
import { useEffect } from 'react';
import {
  getDeploymentEnv,
  getPartition,
  updatePartitionNamespace,
  updatePratitionSecurityGroup,
  getNamespaceDetails,
} from '../../controllers/deploymentEnv.js';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LabelTag from '../../components/LabelTag/LabelTag';
import {
  ApplicationData,
  AppliedFilter,
  AppDeployment,
  DeploymentEnvironment,
  ResourceGroup,
  NetworkSegments,
  Gateway,
  PolicyData,
  Error500Type,
  ProceduralActivityType,
  Procedure,
} from '../../models/master';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';

import dateUtils from '../../lib/dates.js';
import './PartitionDetails.scss';
import {
  getApplications,
  getApplicationsDeployments,
} from '../../controllers/applicationApis';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import sortData from '../../lib/tableSort';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { deletePartition } from '../../controllers/partitionApi';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { Edit16 } from '@carbon/icons-react';
import EditPartition from '../EditPartition/EditPartititon';
import { AxiosError } from 'axios';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';
import { getNetworkSegmentDetails } from '../../controllers/networksegmentsApi';
import { getGateways } from '../../controllers/gateawayApis';
import ProceduralStatus from '../../components/GatewayStatus/ProceduralStatus';
import {
  HealthStatusType,
  ProceduralStatusType,
  showProcedureAttribute,
} from '../../components/GatewayStatus/config';
import HealthStatus from '../../components/GatewayStatus/HealthStatus';
import { getPolicies } from '../../controllers/policyApi';
import Error500 from '../Errors/Error500';
import {
  policyFromTypes,
  PartitionTypes,
  QueryKeys,
  GatewayLifeCycleState,
} from '../../lib/enums';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';
import NamespaceRegister from '../NamespaceRegister/NamespaceRegister';
import ProceduralActivity from '../../components/GatewayStatus/ProceduralActivity';
import GatewayProcedure from '../../components/GatewayStatus/GatewayProcedure';

const defaultPermissionMap = {
  gateway: true,
};

const PartitionDetails = () => {
  const { t } = useTranslation('partitionDetails');

  const [deploymentEnvData, setDeploymentEnvData] =
    useState<DeploymentEnvironment | null>(null);
  const [partitionData, setPartitionData] = useState<any | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const partitionId = searchParams.get('partitionId');
  const depEnvId = searchParams.get('depEnvId');
  const [partitionDataLoading, togglePartitionDataLoading] = useState(false);
  const [appsDeployed, setAppsDeployed] = useState<any[] | null>(null);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [filteredData, setFilteredData] = useState<AppDeployment[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(10);
  const [openDeletePartitionPopUp, setOpenDeletePartitionPopUp] =
    useState<boolean>(false);
  const [disableButton, setDisableButton] = useState(false);
  const [nwSegment, setNetworkSegment] = useState<NetworkSegments | null>(null);
  const [gatewayList, setGatewayList] = useState<Gateway[] | null>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [policies, setPolicies] = useState<PolicyData[] | null>(null);
  const [hasConnectedPolicy, setConnectedPolicy] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [error500AppDeployment, setError500AppDeployment] =
    useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();

  const [openEditModal, setOpenEditModal] = useState(false);
  const tableHeaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: true,
    },
    {
      key: 'resource_group',
      originalKey: 'resource_group',
      header: t('tableHeaders.resourceGroup'),
      sort: true,
    },
    {
      key: 'labels',
      originalKey: 'labels',
      header: t('labels'),
    },
    {
      key: 'last_updated',
      originalKey: 'updated_at',
      sort: 'sortByLast_updated',
      header: t('tableHeaders.lastUpdated'),
      style: { minWidth: '12.5rem' },
    },
  ];

  const fetchGatewayList = async () => {
    try {
      setPermissionMap(permissionMap => ({
        ...permissionMap,
        gateway: true,
      }));
      const gateways = await getGateways();
      setGatewayList(gateways);
    } catch (error) {
      const err = error as AxiosError;
      if (
        err?.response?.status === 403 &&
        err?.response?.statusText === 'Forbidden'
      )
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          gateway: true,
        }));
      setGatewayList(null);
    }
  };

  const fetchPolicy = async () => {
    try {
      let response = await getPolicies(false, false);
      setPolicies(response as PolicyData[]);
      const hasConnectedPolicy =
        Array.isArray(response) &&
        response.filter(
          policy =>
            policy?.from?.type === policyFromTypes.partition &&
            policy?.from?.partition?.partition_id === partitionId
        ).length > 0;
      setConnectedPolicy(hasConnectedPolicy);
    } catch (error) {
      setPolicies(null);
    }
  };

  const refreshData = async () => {
    togglePartitionDataLoading(true);
    try {
      let partitionData;
      partitionData = await getPartitionData();
      if (partitionData && partitionData?.type === PartitionTypes.NAMESPACE) {
        const responseNamespaceDetails = await getNamespaceDetails(
          partitionData?.cloud_id,
          partitionData?.cluster_id,
          partitionData?.resource_id
        );

        setPartitionData({
          ...responseNamespaceDetails,
          type: partitionData?.type,
        });
      } else {
        setPartitionData(partitionData);
      }
      findPartitionNetworkSegmentData(partitionData);
      await fetchPolicy();
      await fetchGatewayList();
      const depEnvDetails = await getDeploymentEnv(depEnvId);
      setDeploymentEnvData(depEnvDetails);

      try {
        const deployments = await getApplicationsDeployments();
        const appsDeployed = deployments.filter(
          (eachDep: { partition_id: any }) =>
            eachDep.partition_id === partitionId
        );
        const appsList = await getApplications(false, false);
        const data: any = await getResourceGroups();
        setResourceGroupsData(data.resource_groups as ResourceGroup[]);
        setAppsDeployed(
          setRowsData(
            appsDeployed,
            appsList,
            data?.resource_groups as ResourceGroup[]
          )
        );
      } catch (error) {
        const err = error as AxiosError;

        if (err.response!?.status >= 500) {
          setError500(err.response!?.status?.toString() as Error500Type);
        }
      }
    } catch (error) {
      setAppsDeployed([]);
      console.error(error);
    } finally {
      togglePartitionDataLoading(false);
    }
  };

  const getPartitionData = async () => {
    try {
      return await getPartition(depEnvId, partitionId);
    } catch (error: any) {
      const err = error as AxiosError;

      if (err.response?.status === 404) navigate('/404');

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const findPartitionNetworkSegmentData = async (partitionData: any) => {
    try {
      const response = await getNetworkSegmentDetails(
        partitionData?.network_segment_id
      );
      setNetworkSegment(response);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        console.error(error);
      }
    }
  };

  const partitionDetailsLabels = () => {
    const labels: any = [];
    if (partitionData?.labels) {
      partitionData.labels.forEach((el: any) => {
        labels.push(el);
      });
    }
    if (partitionData?.discovered_labels) {
      partitionData.discovered_labels.forEach((el: any) => {
        labels.push({
          default: true,
          value: el,
        });
      });
    }
    return labels;
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const formatTags = (
    labels: string[] | null,
    discoverdLabels: string[] | null
  ) => {
    const labelsList: any = [];
    if (labels) {
      labels.forEach(el => {
        labelsList.push(el);
      });
    }
    if (discoverdLabels) {
      discoverdLabels.forEach(el => {
        labelsList.push({
          default: true,
          value: el,
        });
      });
    }
    return <LabelTag labelArray={labelsList} count={3} />;
  };

  const getEmptyState = () => ({
    icon: images.noAppDeploymentSmallIcon(),
    notFoundIcon: images.NotFoundSmall(),
    emptyStateHeader: t('noDeployment'),
    emptyStateDescription: t('noDeploymentDes', {
      type: partitionData?.type,
    }),
    link: false, // need to be made true on implementing add deployment functionality
    buttonText: t('addDeployment'),
    click: () => {},
  });

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const setRowsData = (
    data: AppDeployment[],
    appList: ApplicationData[],
    resourceList: ResourceGroup[]
  ) => {
    let formattedRows: {
      id: string;
      name: string;
      name__format: JSX.Element;
      resource_group: string | undefined;
      labels: JSX.Element | null | string;
      last_updated: string;
      updated_at: string;
    }[] = [];
    if (data && data.length === 0) return [];
    if (data)
      data.map((row: AppDeployment) => {
        const application = appList?.find(
          app => app.resource_id === row.application_id
        );
        const resourceGroup = resourceList?.find(
          resourceGroup =>
            resourceGroup.resource_id === application?.resource_group_id
        );
        formattedRows.push({
          id: row.resource_id,
          name: !!application?.name ? application?.name : '—',
          name__format: (
            <div className='name-column-value'>
              {!!application?.name ? (
                <Link
                  className='no-underline-link'
                  to={`/applicationDeplDetails?deplId=${row.resource_id}&appId=${row.application_id}`}
                  state={{ from: 'namespaceDetailsPage' }}
                >
                  {application?.name}
                  {row?.is_discovered && (
                    <div className='icon'>
                      <IconWithToolTip
                        icon={images.AutoDiscoverdLockIconSmall()}
                        iconDescription={t('autoDiscoveredAccessLimited')}
                      />
                    </div>
                  )}
                </Link>
              ) : (
                '—'
              )}
            </div>
          ),
          resource_group: !!resourceGroup?.name ? resourceGroup?.name : '—',
          labels:
            application?.labels || application?.discovered_labels
              ? formatTags(application.labels, application?.discovered_labels)
              : '—',
          last_updated: dateUtils.getUserFriendlyDate(row.updated_at),
          updated_at: row.updated_at,
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const getAudiscoverValue = () => (
    <div className='auto-discover-label'>
      <div className='auto-discover-label-item'>
        {partitionData?.auto_discover ? (
          <div className='autodicovery-icon'>
            {images.autoDiscoverLabelIcon()}
          </div>
        ) : null}
        <div className='value'>
          {partitionData?.auto_discover ? t('applications') : t('off')}
        </div>
      </div>
    </div>
  );

  const gatewayStatus = (status: string) => {
    let statusWithIcon;
    statusWithIcon = (
      <span className='procedural__status'>
        <GatewayProcedure status={status as Procedure} />
      </span>
    );
    return statusWithIcon;
  };

  const proceduralStatusField = (row: any) => {
    if (showProcedureAttribute[row?.procedure ?? ''] || false) {
      return gatewayStatus(row?.procedure);
    } else if (row?.procedural_activity)
      return (
        <ProceduralActivity
          status={row?.procedural_activity as ProceduralActivityType}
        />
      );
    else if (
      row?.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      row?.unmanaged
    ) {
      return <ProceduralActivity status={row?.lifecycle_state} />;
    } else return <>—</>;
  };

  const getDetailsData = () => {
    const resourceGroup = resourceGroupsData?.find(
      resourceGroup =>
        resourceGroup.resource_id === deploymentEnvData?.resource_group_id
    );
    let details;
    if (partitionData?.type === PartitionTypes.SECURITYGROUP) {
      details = [
        {
          key: 'name',
          value: (
            <GenericTruncateString
              str={partitionData?.name}
              tableView={false}
              limit={14}
            />
          ),
        },
        {
          key: 'infrastructureGroup',
          value: resourceGroup?.name,
        },
        {
          key: 'autodiscover',
          value: getAudiscoverValue(),
        },
        {
          key: 'lastUpdated',
          value: dateUtils.getUserFriendlyDate(partitionData?.updated_at),
        },
        {
          key: 'labels',
          value: partitionDetailsLabels(),
        },
      ];
      return details;
    }
    if (nwSegment && nwSegment?.compatibility_set === 'RHSI')
      details = [
        {
          key: 'name',
          value: (
            <GenericTruncateString
              str={partitionData?.name}
              tableView={false}
              limit={14}
            />
          ),
        },
        {
          key: 'networksegment',
          value: nwSegment?.name ? nwSegment?.name : '—',
        },
        {
          key: 'infrastructureGroup',
          value: resourceGroup?.name,
        },
        {
          key: 'lastUpdated',
          value: dateUtils.getUserFriendlyDate(partitionData?.updated_at),
        },
        {
          key: 'labels',
          value: partitionDetailsLabels(),
        },
        {
          key: 'gatewaySet',
          value:
            nwSegment && nwSegment?.compatibility_set
              ? t(nwSegment?.compatibility_set)
              : '—',
        },
      ];
    else
      details = [
        {
          key: 'name',
          value: (
            <GenericTruncateString
              str={partitionData?.name}
              tableView={false}
              limit={14}
            />
          ),
        },
        {
          key: 'networksegment',
          value: nwSegment?.name ? nwSegment?.name : '—',
        },
        {
          key: 'lastUpdated',
          value: dateUtils.getUserFriendlyDate(partitionData?.updated_at),
        },
        {
          key: 'autodiscover',
          value: getAudiscoverValue(),
        },
        {
          key: 'infrastructureGroup',
          value: resourceGroup?.name,
        },
        {
          key: 'gatewaySet',
          value:
            nwSegment && nwSegment?.compatibility_set
              ? t(nwSegment?.compatibility_set)
              : '—',
        },
        {
          key: 'labels',
          value: partitionDetailsLabels(),
        },
      ];
    if (partitionData?.gateway_id) {
      const gatewayData = gatewayList
        ? gatewayList.find(
            (eachGateway: any) =>
              eachGateway.resource_id === partitionData?.gateway_id
          )
        : null;
      const showProceduralStatus = gatewayData;
      //  &&
      // gatewayData?.status !== 'operational' &&
      // gatewayData?.status !== 'notdeployed';
      details = [
        {
          key: 'name',
          value: (
            <GenericTruncateString
              str={partitionData?.name}
              tableView={false}
              limit={14}
            />
          ),
        },
        {
          key: 'gatewayName',
          value: (
            <div className='healthStatusContainer'>
              <div>
                <GenericTruncateString
                  str={gatewayData?.name ?? ('—' as any)}
                />
              </div>
            </div>
          ),
        },
        {
          key: 'gatewayHealth',
          value: (
            <div className='healthStatusContainer'>
              <HealthStatus
                status={gatewayData?.health_status as HealthStatusType}
                showLabel={true}
              />
            </div>
          ),
        },
        {
          key: 'lastUpdated',
          value: dateUtils.getUserFriendlyDate(partitionData?.updated_at),
        },
        {
          key: 'networksegment',
          value: nwSegment?.name ? nwSegment?.name : '—',
        },
        {
          key: 'infrastructureGroup',
          value: resourceGroup?.name,
        },
        {
          key: 'labels',
          value: partitionDetailsLabels(),
        },
        {
          key: 'gatewaySet',
          value:
            nwSegment && nwSegment?.compatibility_set
              ? t(nwSegment?.compatibility_set)
              : '—',
        },
      ];
      if (
        showProceduralStatus &&
        gatewayData?.procedural_status.endsWith('ing')
      )
        details.splice(6, 0, {
          key: 'status',
          value: proceduralStatusField(gatewayData),
          // !gatewayData?.procedural_status ||
          // gatewayData?.procedural_status === ''
          //   ? '—'
          //   : ((
          //       <ProceduralStatus
          //         status={
          //           gatewayData?.procedural_status as ProceduralStatusType
          //         }
          //         gatewayName={gatewayData?.name as any}
          //       />
          //     ) as any),
        });
      else
        details.splice(6, 0, {
          key: 'emptyVal',
          value: '',
        });
    }
    return details;
  };

  const handleManagedChange = async (value: boolean) => {
    try {
      if (partitionData) {
        const { type, cloud_id, cluster_id, vpc_id, resource_id } =
          partitionData;
        const data = { ...partitionData, unmanaged: !value };

        if (type === PartitionTypes.NAMESPACE) {
          await updatePartitionNamespace(
            cloud_id,
            cluster_id,
            resource_id,
            data
          );
        }

        if (type === 'securitygroup') {
          await updatePratitionSecurityGroup(
            cloud_id,
            vpc_id,
            resource_id,
            data
          );
        }

        setPartitionData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const disableManagedToggle = () => {
    if (partitionData?.unmanaged) {
      return deploymentEnvData?.unmanaged ? true : false;
    }

    const managedDeploymentCount = Array.isArray(appsDeployed)
      ? appsDeployed.length
      : 0;

    return partitionData?.auto_discover || managedDeploymentCount > 0;
  };
  const managedToggle = !partitionData?.unmanaged;

  const renderManageToggleButton = () => {
    const ToggleBtn = () => (
      <Toggle
        id='managed'
        labelA={t('toggleOff')}
        labelB={t('toggleOn')}
        size='md'
        toggled={!partitionData?.unmanaged}
        onToggle={handleManagedChange}
        disabled={
          disableManagedToggle() ||
          (partitionData?.gateway_id && !partitionData?.unmanaged)
        }
      />
    );

    return (
      <div className='tooltip-container'>
        <div className='label'>{t('toggleLabelManaged')}</div>
        {disableManagedToggle() && (
          <TooltipDefinition
            direction='bottom'
            align='end'
            tooltipText={
              partitionData?.unmanaged && deploymentEnvData?.unmanaged
                ? t('tooltiplabelPartitionManaged', {
                    parentType:
                      partitionData?.type === PartitionTypes.NAMESPACE
                        ? t('cluster')
                        : t('vpc'),
                    tooltipType:
                      partitionData?.type === PartitionTypes.NAMESPACE
                        ? t('namespaceTooltip')
                        : t('securityGroupTooltip'),
                  })
                : (t('tooltiplabelPartitionUnmanaged', {
                    tooltipType:
                      partitionData?.type === PartitionTypes.NAMESPACE
                        ? t('namespaceTooltip')
                        : t('securityGroupTooltip'),
                  }) as string)
            }
          >
            {ToggleBtn()}
          </TooltipDefinition>
        )}
        {!disableManagedToggle() && ToggleBtn()}
      </div>
    );
  };

  const getHeaderIcon = () => {
    if (!managedToggle && partitionData?.is_discovered) {
      return (
        <div className='header-icon-contaniner'>
          <IconWithToolTip
            icon={images.AutoDiscoverdLockIcon()}
            iconDescription={t('autoDiscoveredAccessLimited')}
          />
        </div>
      );
    }

    if (partitionData?.is_discovered) {
      return (
        <div className='autodiscovered-icon-contaniner'>
          <IconWithToolTip
            icon={images.AutoDiscoverdLockIcon()}
            iconDescription={t('autoDiscoveredAccessLimited')}
          />
        </div>
      );
    }

    return false;
  };
  const confirmDeletePartition = async () => {
    try {
      setDisableButton(true);
      await deletePartition(
        deploymentEnvData?.cloud_id,
        depEnvId,
        partitionId,
        partitionData.type,
        deploymentEnvData?.type
      );
      notification.onTrigger('TOAST', {
        title: t('delete.successMsg.title'),
        subtitle: t('delete.successMsg.desc', {
          partitionName: partitionData.name,
        }),
      });

      inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);

      navigate(`/deploymentEnvironmentDetails?deplId=${depEnvId}`);
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('delete.error.authErrorTitle'),
          kind: 'error',
          subtitle: t('delete.error.authErrorSubtitle'),
        });
      }

      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      notification.onTrigger('TOAST', {
        title: t('delete.error.title'),
        kind: 'error',
        subtitle:
          errorMessage.length > 0
            ? errorMessage
            : t('delete.error.errorMsg', {
                partitionName: partitionData.name,
              }),
      });
    } finally {
      setDisableButton(false);
      setOpenDeletePartitionPopUp(false);
    }
  };

  const getSubTitle = () => {
    return (
      <div className='sub-heading-section'>
        <div
          className={
            'header-icon-contaniner ' +
            (partitionData?.unmanaged ? 'unmanaged' : 'managed')
          }
        >
          <div
            className={
              !partitionData?.unmanaged ? 'managed-icon' : 'unmanaged-icon'
            }
          />
          <div className='icon-text'>
            {!partitionData?.unmanaged
              ? t('managedTooltip')
              : t('unmanagedTooltip')}
          </div>
        </div>
      </div>
    );
  };

  const renderDeleteBtn = (disabled: boolean) => (
    <div className='delete-partition'>
      <Button
        kind='danger--ghost'
        disabled={disabled}
        onClick={() => {
          setOpenDeletePartitionPopUp(true);
        }}
      >
        {partitionData?.type === PartitionTypes.NAMESPACE
          ? t('delete.partitionBtnNamespace')
          : t('delete.partitionBtnSecurityGroup')}
      </Button>
    </div>
  );

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='partition-details'>
      <Header
        loading={partitionDataLoading}
        title={partitionData?.name ?? ''}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
          {
            url: '/deploymentenvironments',
            label: t('deploymentEnvironments'),
          },
          {
            url: `/deploymentEnvironmentDetails?deplId=${depEnvId}`,
            label: deploymentEnvData?.name ?? '',
          },
        ]}
        subTitle={getSubTitle()}
        headerIcon={getHeaderIcon()}
        renderCustomActions={
          partitionData?.type === PartitionTypes.NAMESPACE
            ? () => <div></div>
            : renderManageToggleButton
        }
      />

      {partitionDataLoading ? (
        <div className='page-content'>
          <SkeletonPlaceholder className='details-tile-skeleton' />
          <DataTableSkeleton />
        </div>
      ) : (
        <div className='page-content'>
          <Tile className='details-tile'>
            <div className='header'>
              <div className='text'>
                {partitionData?.type === PartitionTypes.NAMESPACE
                  ? t('detailsNamespace')
                  : t('detailsSecurity')}
              </div>
              <div className='edit'>
                {partitionData?.unmanaged &&
                partitionData?.type === PartitionTypes.NAMESPACE ? (
                  <Button
                    kind='ghost'
                    onClick={() => setOpenEditModal(true)}
                    data-testid='manage-namespace-btn'
                  >
                    {t('Manage')}
                  </Button>
                ) : (
                  <Button
                    className={`edit-link ${
                      deploymentEnvData?.unmanaged ? 'disabled' : 'enabled'
                    }`}
                    renderIcon={Edit16}
                    onClick={() =>
                      deploymentEnvData?.unmanaged
                        ? null
                        : setOpenEditModal(true)
                    }
                    iconDescription={
                      deploymentEnvData?.unmanaged
                        ? t('editDisabled')
                        : t('edit')
                    }
                    hasIconOnly
                    tooltipPosition='bottom'
                    tooltipAlignment='end'
                  ></Button>
                )}
                {/* <CarbonLink
                  className='edit-link'
                  onClick={() => setOpenEditModal(true)}
                >
                  <Edit16 className='edit-svg' />
                </CarbonLink> */}
              </div>
            </div>
            <div className='data-container'>
              {getDetailsData().map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column key={index} className='section' lg={4}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <LabelTag
                          labelArray={obj.value as string[]}
                          count={3}
                        ></LabelTag>
                      </div>
                    </Column>
                  );
                } else {
                  return (
                    <Column key={index} lg={4} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value || '—'}</div>
                    </Column>
                  );
                }
              })}
            </div>
          </Tile>
          <div className='DeploymentTableContainer'>
            <div className='deployment_table'>
              <GenericTableWithFilters
                title={t('applicationDeploymentTitle', {
                  count: appsDeployed ? appsDeployed.length : 0,
                })}
                description={t('applicationDeploymentDesc', {
                  type: partitionData?.type,
                })}
                id='application-deployment-table'
                rows={
                  appsDeployed
                    ? filterApplied.length > 0
                      ? [...sortData(filteredData, sortKey, sortDirection)]
                      : [...sortData(appsDeployed, sortKey, sortDirection)]
                    : []
                }
                data={
                  appsDeployed
                    ? filterApplied.length > 0
                      ? filteredData
                      : appsDeployed
                    : null
                }
                headers={tableHeaders}
                sortRows={(
                  data: { id: string; text: string },
                  direction: 'ASC' | 'DESC' | 'NONE'
                ) => handleTableSort(data, direction)}
                isSortable={true}
                totalElementsCount={
                  appsDeployed
                    ? filterApplied.length > 0
                      ? filteredData.length
                      : appsDeployed.length
                    : 0
                }
                fullData={appsDeployed}
                onTableRefresh={() => refreshData()}
                filters={[]}
                showRefresh={false}
                currentPageNumber={currentPageNumber}
                currentPageSize={currentPageSize}
                onPageChange={(pageData: any) => setPageChange(pageData)}
                emptyState={getEmptyState()}
                selectedFiltersVal={filterApplied}
                setFilterApplied={data => {
                  setFilterApplied(data);
                }}
                filteredDataCallback={(data: AppDeployment[]) => {
                  setFilteredData(data as AppDeployment[] | []);
                }}
                render500Container={!!error500AppDeployment}
              />
            </div>
            {(appsDeployed && appsDeployed.length > 0) ||
            partitionData?.auto_discover ||
            (Array.isArray(policies) &&
              policies?.length > 0 &&
              hasConnectedPolicy) ? (
              <TooltipDefinition
                tooltipText={
                  t('delete.disableTooltipText', {
                    type: partitionData?.type ?? '',
                  }) as string
                }
                direction='top'
              >
                {renderDeleteBtn(true)}
              </TooltipDefinition>
            ) : (
              renderDeleteBtn(false)
            )}
          </div>
        </div>
      )}
      <Modal
        open={openDeletePartitionPopUp}
        danger
        modalLabel={
          partitionData?.type === PartitionTypes.NAMESPACE
            ? t('delete.confirmationHeadingNamespace')
            : t('delete.confirmationHeadingSecurityGroup')
        }
        primaryButtonText={t('deletebtn')}
        secondaryButtonText={t('cancelBtn')}
        preventCloseOnClickOutside={true}
        onRequestSubmit={() => {
          confirmDeletePartition();
        }}
        onRequestClose={() => {
          setOpenDeletePartitionPopUp(false);
        }}
        className='deletePartitionModal'
        size='sm'
        primaryButtonDisabled={disableButton}
      >
        {t('delete.deleteConfirmationModalText', {
          partitionName: partitionData?.name ?? '',
        })}
      </Modal>
      {openEditModal && (
        <EditPartition
          open={
            openEditModal && partitionData?.type !== PartitionTypes.NAMESPACE
          }
          onClose={() => setOpenEditModal(false)}
          partitionData={partitionData}
          depEnvData={deploymentEnvData}
          refreshDetailsData={() => {
            setOpenEditModal(false);
            refreshData();
          }}
          mode='EDIT'
        />
      )}
      {openEditModal && (
        <NamespaceRegister
          open={
            openEditModal && partitionData?.type === PartitionTypes.NAMESPACE
          }
          onClose={() => setOpenEditModal(false)}
          partitonType={PartitionTypes.NAMESPACE}
          deploymentdata={deploymentEnvData}
          createPartition={() => {
            setOpenEditModal(false);
            refreshData();
          }}
          partitionData={partitionData}
          mode='EDIT'
          appsDeployed={appsDeployed}
          hasConnectedPolicy={hasConnectedPolicy}
        />
      )}
    </div>
  );
};

export default PartitionDetails;
