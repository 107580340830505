import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TooltipDefinition } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import HealthStatus from '../../../components/GatewayStatus/HealthStatus';
import {
  HealthStatusType,
  proceduralStatusField,
} from '../../../components/GatewayStatus/config';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import images from '../../../images/images.js';
import dateUtils from '../../../lib/dates';
import {
  AppliedFilter,
  Gateway,
  GatewayCustomData,
  ResourceGroup,
} from '../../../models/master';

import './GatewaysTable.scss';
import useAnalytics from '../../../lib/useAnalytics';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';
import { GatewayLifeCycleState } from '../../../lib/enums';

interface GatewayTableRows {
  id: any;
  gateway_id: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  name:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  name__format: JSX.Element;
  type?: string;
  health_status: JSX.Element | undefined | string;
  cloud_location_field: JSX.Element;
  resourceGroup: JSX.Element | undefined | string;
  resource_name: JSX.Element | undefined | string;
  updated_at: JSX.Element | string;
  network_segment_name: JSX.Element | string | undefined;
  environment_partition_field: JSX.Element | undefined;
  procedural_status: JSX.Element | undefined;
  clickable_button: JSX.Element | undefined;
}

interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: string[];
}

const GatewaysTable = (props: {
  filteredDataCallback: (data: any) => void;
  openDeployGateway: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: GatewayCustomData[] | null;
  data: GatewayCustomData[] | null;
  filteredDataSet: GatewayCustomData[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  resourceGroupsList: ResourceGroup[] | null;
  leftInlineFilters?: Filter[];
  leftInlineMultiSelectFilter?: Filter[];
  visibilityFlag?: string;
  emptyStateClick: any;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  // openConnectGatewayClusterTearSheet: (gateway: Gateway) => void;
}) => {
  const { t } = useTranslation('gatewaysContainer');
  const { rows, onRefresh, emptyStateClick } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const { trackButtonClicked } = useAnalytics();

  const capitalizeFirstLetterInString = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const myheaders = [
    {
      key: 'name__format',
      header: t('tableHeaders.name'),
      sort: true,
      originalKey: 'name',
    },
    {
      key: 'health_status',
      header: t('tableHeaders.health'),
      sort: true,
      originalKey: 'health_status',
      style: { minWidth: '9rem' },
    },
    {
      key: 'cloud_location_field',
      originalKey: 'cloud_location_field',
      header: t('tableHeaders.cloud') + ' / ' + t('tableHeaders.location'),
      sort: true,
    },
    {
      key: 'network_segment_name',
      originalKey: 'network_segment_name',
      header: t('tableHeaders.networkSegment'),
      sort: true,
    },
    {
      key: 'environment_partition_field',
      originalKey: 'environment_partition_field',
      header:
        t('tableHeaders.environment') + ' / ' + t('tableHeaders.partition'),
      sort: true,
    },
    {
      key: 'procedural_status',
      originalKey: 'procedural_activity',
      header: t('tableHeaders.proceduralStatus'),
      sort: true,
    },
    {
      key: 'updated_at',
      originalKey: 'updated_at',
      header: t('tableHeaders.updated_at'),
      sort: true,
    },
    {
      key: 'clickable_button',
      header: '',
    },
  ];

  const emptyStateData = {
    icon: images.noGatewayLargeIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    dropdown: {
      label: t('addGateway'),
      items: [
        { id: 'edge', text: t('edgeGateway') },
        { id: 'waypoint', text: t('waypointOption') },
      ],
    },
    dropdownClick: (item: any) => {
      emptyStateClick(item);
    },
  };

  const performActionButton = (row: Gateway) => {
    if (
      row?.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      row?.unmanaged
    ) {
      return (
        <div className='deploy-gateway-btn'>
          <Button kind='ghost' onClick={() => props.openDeployGateway(row)}>
            {t('performActionButton.deployGateway')}
          </Button>
        </div>
      );
    }
    if (
      row?.lifecycle_state === GatewayLifeCycleState.GW_DEPLOYED &&
      row?.procedural_activity === 'waiting-connection'
    ) {
      return (
        <div className='connect-gateway-cluster-btn'>
          <Button
            kind='ghost'
            disabled={true}
            // onClick={() => props.openConnectGatewayClusterTearSheet(row)}
          >
            {t('performActionButton.connectCluster')}
          </Button>
        </div>
      );
    }
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {GatewayTableRows[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(props.resourceGroupsList) &&
      props.resourceGroupsList.find(
        resouceGroup => resouceGroup.resource_id === id
      );

    return resouceGroup ? resouceGroup.name : '—';
  };

  const setRowsData = () => {
    let formattedRows: GatewayTableRows[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];

    if (rows)
      rows.map((row: GatewayCustomData, index) => {
        formattedRows.push({
          id: row.resource_id + index,
          gateway_id: row.resource_id,
          cloud_id: row.cloud_id,
          location_id: row.location_id,
          name: !!row.name ? capitalizeFirstLetterInString(row.name) : '—',
          name__format: (
            <div className='name-container'>
              <Link
                className='no-underline-link'
                onClick={() =>
                  trackButtonClicked({
                    CTA: 'Gateways, Open gateway details',
                    action: 'View the gateway details',
                  })
                }
                to={`/gatewayDetails?gatewayId=${row.resource_id}`}
              >
                <TooltipDefinition
                  tooltipText={t(row.type) + t('gateway')}
                  direction={
                    rows.length - 1 === index ||
                    (index + 1) % props.currentPageSize === 0
                      ? 'top'
                      : 'bottom'
                  }
                  align='start'
                ></TooltipDefinition>
                <div
                  className={
                    'table__gateway__name' + (row.unmanaged ? ' unmanaged' : '')
                  }
                >
                  {row.unmanaged ? <div className='unmanaged-icon'></div> : ''}
                  <div className='gateway-name'>
                    <div className='name'>
                      <GenericTruncateString str={row?.name} tableView={true} />
                    </div>
                    <div className='gateway-type'>
                      {row.subtype ? t(`gatewayTypes.${row.subtype}`) : '—'}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ),
          health_status: !!row?.health_status ? (
            <div
              className={'health-status ' + (row.unmanaged ? ' unmanaged' : '')}
            >
              <HealthStatus
                status={row?.health_status as HealthStatusType}
                showLabel={true}
                viewEventsLink={true}
                gatewayName={row.name}
                tooltipPosition='top'
              />
            </div>
          ) : (
            '—'
          ),
          cloud_location_field: (
            <div
              className={
                'cloud-and-location-field' + (row.unmanaged ? ' unmanaged' : '')
              }
            >
              {row?.cloudPermission &&
              row?.locationPermission &&
              !row.location_name &&
              !row.cloud_name ? (
                <div>—</div>
              ) : (
                <>
                  {!row.cloudPermission ? (
                    <GenericStatusField status='notAuthorised'></GenericStatusField>
                  ) : (
                    <div className='cloud-field'>
                      {row?.cloud_id ? (
                        <Link
                          className='no-underline-link'
                          to={`/cloudDetails?cloudId=${row.cloud_id}`}
                        >
                          <GenericTruncateString
                            str={row?.cloud_name || '—'}
                            tableView={true}
                          />
                        </Link>
                      ) : (
                        <div>—</div>
                      )}
                    </div>
                  )}
                  {!row.locationPermission ? (
                    <GenericStatusField status='notAuthorised'></GenericStatusField>
                  ) : (
                    <div className='location-field'>
                      {row?.location_id && row?.cloud_id ? (
                        <Link
                          className='no-underline-link'
                          to={`/locationDetails?cloudId=${row.cloud_id}&locationId=${row.location_id}`}
                        >
                          <GenericTruncateString
                            str={row?.location_name || '—'}
                            tableView={true}
                          />
                        </Link>
                      ) : row?.location_id ? (
                        <GenericTruncateString
                          str={row?.location_name || '—'}
                          tableView={true}
                        />
                      ) : (
                        <div>—</div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          ),
          resourceGroup: !row.resourceGroupsPermission ? (
            <GenericStatusField status='notAuthorised' />
          ) : (
            getResourceGroupName(row.resource_group_id)
          ),
          resource_name: !row.resourceGroupsPermission ? (
            <GenericStatusField status='notAuthorised' />
          ) : !!row.resource_name ? (
            row.resource_name
          ) : (
            '—'
          ),
          network_segment_name: !row.networkSegmentPermission ? (
            <GenericStatusField status='notAuthorised'></GenericStatusField>
          ) : !!row.network_segment_name ? (
            row.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
              row.network_segment_name
            ) : (
              <Link
                className={
                  'no-underline-link network-segment' +
                  (row.unmanaged ? ' unmanaged' : '')
                }
                to={`/networkSegmentDetails?nwSegId=${row.network_segment_id}`}
              >
                {row.network_segment_name}
              </Link>
            )
          ) : (
            '—'
          ),
          updated_at: !!row?.updated_at ? (
            <div
              className={
                'updated-at-field-row' + (row.unmanaged ? ' unmanaged' : '')
              }
            >
              {dateUtils.getUserFriendlyDate(row?.updated_at)}
            </div>
          ) : (
            '—'
          ),
          environment_partition_field: (
            <div
              className={
                'environment-and-partition-field' +
                (row?.unmanaged ? ' unmanaged' : '')
              }
            >
              <>
                {!row.deploymentEnvironmentPermission ? (
                  <GenericStatusField status='notAuthorised'></GenericStatusField>
                ) : (
                  <div className='environment-field'>
                    {row?.deployed_in_depl_env_id ? (
                      <Link
                        className='no-underline-link'
                        to={`/deploymentEnvironmentDetails?deplId=${row.deployed_in_depl_env_id}`}
                      >
                        <GenericTruncateString
                          str={row?.deployment_env_name || '—'}
                          tableView={true}
                        />
                      </Link>
                    ) : (
                      <div>—</div>
                    )}
                  </div>
                )}
                <div className='partition-field'>
                  {row?.deployed_in_depl_env_id &&
                  row?.deployed_in_partition_id ? (
                    <Link
                      className='no-underline-link'
                      to={`/partitionDetails?depEnvId=${row.deployed_in_depl_env_id}&partitionId=${row.deployed_in_partition_id}`}
                    >
                      <GenericTruncateString
                        str={row?.partition_name || '—'}
                        tableView={true}
                      />
                    </Link>
                  ) : row?.namespace_name ? (
                    <div className='partition-name'>
                      <GenericTruncateString
                        str={row?.namespace_name || '—'}
                        tableView={true}
                      />
                    </div>
                  ) : (
                    <div>—</div>
                  )}
                </div>
              </>
            </div>
          ),

          procedural_status: (
            <div
              className={
                'procedural-status-row' + (row.unmanaged ? ' unmanaged' : '')
              }
            >
              {proceduralStatusField(row)}
            </div>
          ),
          clickable_button: performActionButton(row),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  return (
    <GenericTableWithFilters
      id='table-with-filter-panel'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={myheaders}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: GatewayTableRows[]) =>
        props.filteredDataCallback(data)
      }
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: AppliedFilter[]) =>
        props.filtersAppliedCallback(data)
      }
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      leftInlineFilters={props.leftInlineFilters}
      visibilityFlag={props.visibilityFlag}
      persistFilter
      render403Container={props.error403Flag}
      render500Container={props.error500Flag}
    />
  );
};

export default GatewaysTable;
