const analyticsData = {
  Home: {
    path: '/',
    events: {
      getStartedLink: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Get started link clicked',
          action: 'Get started link clicked from home page',
        },
      },
      canvasViewLink: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Canvas view link clicked',
          action: 'Canvas view link clicked from home page',
        },
      },
      registerAppLink: {
        event: 'Created Object',
        props: {
          action: 'Register application link clicked',
          objectType: 'button',
        },
      },
      registerEnvLink: {
        event: 'Created Object',
        props: {
          action: 'Register environment link clicked',
          objectType: 'button',
        },
      },
      registerCloudLink: {
        event: 'Created Object',
        props: { action: 'Register cloud link clicked', objectType: 'button' },
      },
      createPolicyLink: {
        event: 'Created Object',
        props: { action: 'Create policy link clicked', objectType: 'button' },
      },
      registerLocationLink: {
        event: 'Created Object',
        props: {
          action: 'Register location link clicked',
          objectType: 'button',
        },
      },
      registerGatewayLink: {
        event: 'Created Object',
        props: {
          action: 'Register gateway link clicked',
          objectType: 'button',
        },
      },
      mapViewLink: {
        event: 'UI Interaction',
        props: {
          CTA: 'Map view link clicked',
          action: 'Map view link clicked from home page',
        },
      },
    },
  },
  'Admin Dropdown': {
    path: '/admin',
    events: {
      openResourceGroup: {
        event: 'Read Object',
        props: { action: 'Resource group open', objectType: 'button' },
      },
      openApiKey: {
        event: 'Read Object',
        props: { action: 'API key open', objectType: 'button' },
      },
      openSecrets: {
        event: 'Read Object',
        props: { action: 'Secrets open', objectType: 'button' },
      },
      openIdentities: {
        event: 'Read Object',
        props: { action: 'Identities open', objectType: 'button' },
      },
      openRoles: {
        event: 'Read Object',
        props: { action: 'Roles open', objectType: 'button' },
      },
      openSubscriptionUsage: {
        event: 'Read Object',
        props: { action: 'Subscription usage open', objectType: 'button' },
      },
    },
  },
  'Admin Resource Groups': {
    path: '/admin',
    events: {
      openResourceGroupModal: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Create resource group modal',
          action: 'Open create a resource group modal',
        },
      },
      createResourceGroup: {
        event: 'Created Object',
        props: { action: 'Create resource group', objectType: 'button' },
      },
      editResourceGroup: {
        event: 'Created Object',
        props: { action: 'Edit resource group', objectType: 'button' },
      },
      viewResourceGroup: {
        event: 'Created Object',
        props: { action: 'View resource group', objectType: 'button' },
      },
      deleteResourceGroup: {
        event: 'Created Object',
        props: { action: 'Delete resource group', objectType: 'button' },
      },
    },
  },
  'Admin API Keys': {
    path: '/admin',
    events: {
      viewAPIKey: {
        event: 'Read Object',
        props: { action: 'View API key', objectType: 'button' },
      },
      editAPIKey: {
        event: 'Read Object',
        props: { action: 'Edit API key', objectType: 'button' },
      },
      createAPIKey: {
        event: 'CTA Clicked',
        props: { CTA: 'create API key', action: 'Launch create API key modal' },
      },
      generateAPIKey: {
        event: 'Created Object',
        props: { action: 'Generate API key', objectType: 'button' },
      },
      deleteAPIKey: {
        event: 'Deleted Object',
        props: { action: 'Delete API key', objectType: 'button' },
      },
    },
  },
  'Admin Secrets': {
    path: '/admin',
    events: {
      viewSecret: {
        event: 'Read Object',
        props: { action: 'View secret', objectType: 'button' },
      },
      launchRegSecret: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register secret modal',
          action: 'Launch the register secret modal',
        },
      },
      registerSecretBtn: {
        event: 'Created Object',
        props: { action: 'Register secret', objectType: 'button' },
      },
      editSecret: {
        event: 'Updated Object',
        props: { action: 'Edit secret', objectType: 'button' },
      },
      deleteSecret: {
        event: 'Deleted Object',
        props: { action: 'Delete secret', objectType: 'button' },
      },
    },
  },
  'Admin Identities': {
    path: '/admin',
    events: {
      viewIdentities: {
        event: 'Read Object',
        props: { action: 'View identities', objectType: 'button' },
      },
      createIdentity: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Create identity modal',
          action: 'Launch create identity modal',
        },
      },
      createIdentityBtn: {
        event: 'Created Object',
        props: { action: 'Create identity', objectType: 'button' },
      },
      editIdentity: {
        event: 'Updated Object',
        props: { action: 'Edit identity', objectType: 'button' },
      },
      deleteIdentity: {
        event: 'Deleted Object',
        props: { action: 'Delete identity', objectType: 'button' },
      },
    },
  },
  'Admin Roles': {
    path: '/admin',
    events: {
      viewRoles: {
        event: 'Read Object',
        props: { action: 'View role', objectType: 'button' },
      },
      createRole: {
        event: 'CTA Clicked',
        props: { CTA: 'Create role modal', action: 'Launch create role modal' },
      },
      createRoleBtn: {
        event: 'Created Object',
        props: { action: 'Create role', objectType: 'button' },
      },
      editRole: {
        event: 'Updated Object',
        props: { action: 'Edit role', objectType: 'button' },
      },
      deleteRole: {
        event: 'Deleted Object',
        props: { action: 'Delete role', objectType: 'button' },
      },
    },
  },
  'Side Navigation': {
    path: '/admin',
    events: {
      appNavLink: {
        event: 'UI Interaction',
        props: {
          CTA: 'Applications & Services nav clicked',
          action: 'Open applications and services',
        },
      },
      environmentNav: {
        event: 'UI Interaction',
        props: {
          CTA: 'Environments nav clicked',
          action: 'Open deployment environments',
        },
      },
      policyNav: {
        event: 'UI Interaction',
        props: { CTA: 'Policies nav clicked', action: 'Open policies' },
      },
      topologyNav: {
        event: 'UI Interaction',
        props: { CTA: 'Topology nav clicked', action: 'Open topology' },
      },
      locationNav: {
        event: 'UI Interaction',
        props: { CTA: 'Locations nav clicked', action: 'Open locations' },
      },
      cloudNav: {
        event: 'UI Interaction',
        props: { CTA: 'Clouds nav clicked', action: 'Open clouds' },
      },
      gatewayNav: {
        event: 'UI Interaction',
        props: { CTA: 'Gateways nav clicked', action: 'Open gateways' },
      },
      eventsNav: {
        event: 'UI Interaction',
        props: { CTA: 'Events nav clicked', action: 'Open events' },
      },
      nwSegmentsNav: {
        event: 'UI Interaction',
        props: {
          CTA: 'Network segments nav clicked',
          action: 'Open network segments',
        },
      },
    },
  },
  Cloud: {
    path: '/cloud',
    events: {
      registerCloudBtn: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register cloud',
          action: 'Register cloud button clicked from clouds page',
        },
      },
      registerCloudLink: {
        event: 'Created Object',
        props: { action: 'Register cloud clicked', objectType: 'button' },
      },
      cloudTypeSelected: {
        event: 'UI Interaction',
        props: {
          CTA: 'Next clicked',
          action: 'Next button clicked after cloud selected',
        },
      },
      cloudAutodiscoverOn: {
        event: 'UI Interaction',
        props: {
          CTA: 'Autodiscover on',
          action: 'Autodiscover switch to on from register cloud',
        },
      },
    },
  },
  'Cloud Details': {
    path: '/cloudDetails',
    events: {
      cloudEdit: {
        event: 'Updated Object',
        props: { action: 'Edit icon', objectType: 'button' },
      },
      locationDetails: {
        event: 'Read Object',
        props: { action: 'Location details', objectType: 'button' },
      },
      manageLocation: {
        event: 'UI Interaction',
        props: {
          CTA: 'Manage cloud location',
          action: 'Manage cloud location switched on from cloudDetails page',
        },
      },
      manageVpc: {
        event: 'UI Interaction',
        props: {
          CTA: 'Manage cloud environments vpc',
          action:
            'Manage cloud environment vpc switched on from cloudDetails page',
        },
      },
      registerDepEnv: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register deployment environment',
          action:
            'Register deployment environment button clicked from cloudDetails page',
        },
      },
      manageCluster: {
        event: 'UI Interaction',
        props: {
          CTA: 'Manage cloud environment cluster',
          action:
            'Manage cloud environment cluster switched on from cloudDetails page',
        },
      },
      autodiscoverNamespace: {
        event: 'UI Interaction',
        props: {
          CTA: 'Autodiscover namespace cluster',
          action:
            'Autodicover namespace cluster switched on from cloudDetails page',
        },
      },
      registerCluster: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register deployment environment cluster',
          action:
            'Register deployment environment cluster button clicked from cloudDetails page',
        },
      },
      depEnvDetails: {
        event: 'Read Object',
        props: {
          action: 'Deployment environment details',
          objectType: 'button',
        },
      },
      locationLink: {
        event: 'UI Interaction',
        props: {
          CTA: 'Cloud location',
          action: 'Cloud location link clicked from cloudDetails page',
        },
      },
      manageNamespace: {
        event: 'UI Interaction',
        props: {
          CTA: 'Manage autodiscover namespace cluster',
          action:
            'Manage autodiscover namespace cluster switched on from deployment environment configuration modal',
        },
      },
      connectGateway: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Connect a gateway cluster',
          action:
            'Connect a gateway cluster button clicked from deployment environment configuration modal',
        },
      },
      installationMethod: {
        event: 'UI Interaction',
        props: {
          CTA: 'Gateway installation method',
          action:
            'Gateway installation method radio button selected from connect edge gateway modal',
        },
      },
      deployGateway: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Deploy edge gateway',
          action:
            'Deploy edge gateway button clicked from connect edge gateway modal',
        },
      },
      registerGateway: {
        event: 'Created Object',
        props: { action: 'Register edge gateway', objectType: 'button' },
      },
      deleteCloud: {
        event: 'Deleted Object',
        props: { action: 'Delete a cloud', objectType: 'button' },
      },
      namespacedetailsLink: {
        event: 'UI Interaction',
        props: {
          CTA: 'Namespace details click',
          action: 'Namespace details link clicked from cloudDetails page',
        },
      },
    },
  },
  Locations: {
    path: '/locations',
    events: {
      registerLocationBtn: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register location',
          action: 'Register location button clicked from locations page',
        },
      },
      registerLocation: {
        event: 'Created Object',
        props: { action: 'Register location modal', objectType: 'button' },
      },
      locationTypeSelected: {
        event: 'UI Interaction',
        props: {
          CTA: 'Location type selected',
          action: 'Type selected from register location modal',
        },
      },
    },
  },
  'Locations Details': {
    path: '/cloud/locationDetails',
    events: {
      manageLocation: {
        event: 'UI Interaction',
        props: {
          CTA: 'Managed deployment environment',
          action:
            'Managed deployment environment link clicked from locationDetails page',
        },
      },
      registerDepEnv: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register deployment environment from location',
          action:
            'Register deployment environment link clicked from locationDetails page',
        },
      },
      registerDepEnvFromCloud: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register deployment environment from cloud',
          action:
            'Register deployment environment link clicked from cloudDetails page',
        },
      },
      deleteLocation: {
        event: 'Deleted Object',
        props: { action: 'Deleting location', objectType: 'button' },
      },
    },
  },
  'Deployment Environment': {
    path: '/deploymentenvironments',
    events: {
      registerDepEnvBtn: {
        event: 'UI Interaction',
        props: {
          CTA: 'Register deployment environment',
          action:
            'Register environment button clicked from deployment environments',
        },
      },
      registerDepEnv: {
        event: 'Created Object',
        props: {
          action: 'Register deployment environment model',
          objectType: 'button',
        },
      },
      autodiscoverNamespace: {
        event: 'UI Interaction',
        props: {
          CTA: 'Autodiscover namespace on',
          action:
            'Autodiscover namespace switch on from register deployment environment',
        },
      },
    },
  },
  'Deployment Environment Details': {
    path: '/deploymentEnvironmentDetails',
    events: {
      editDepEnv: {
        event: 'Updated Object',
        props: {
          action: 'Edit deployment environment details',
          objectType: 'button',
        },
      },
      registerNamespace: {
        event: 'Created Object',
        props: { action: 'Register namespace', objectType: 'button' },
      },
      autodiscoverApp: {
        event: 'UI Interaction',
        props: {
          CTA: 'Autodiscover namespace applications',
          action: 'Autodiscover applications in the namespace',
        },
      },
      updateNamespace: {
        event: 'Updated Object',
        props: {
          action: 'Save changes to the namespace',
          objectType: 'button',
        },
      },
      deleteDepEnv: {
        event: 'Deleted Object',
        props: {
          action: 'Delete deployment environment',
          objectType: 'button',
        },
      },
    },
  },
  Applications: {
    path: '/applications',
    events: {
      registerAppBtn: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register application',
          action: 'Register application button clicked from applications page',
        },
      },
      registerApp: {
        event: 'Created Object',
        props: { action: 'Register application modal', objectType: 'button' },
      },
    },
  },
  'Application Details': {
    path: '/applicationDetails',
    events: {
      editApp: {
        event: 'Updated Object',
        props: { action: 'Edit application details', objectType: 'button' },
      },
      registerServiceLink: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register service',
          action: 'Register service link clicked from applicationDetails page',
        },
      },
      registerService: {
        event: 'Created Object',
        props: { action: 'Register service modal', objectType: 'button' },
      },
      registerDeploymentBtn: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Register deployment',
          action: 'Register deployment button clicked from applications page',
        },
      },
      registerDeployment: {
        event: 'Created Object',
        props: {
          action: 'Register application deployment modal',
          objectType: 'button',
        },
      },
      deploymentInstanceLink: {
        event: 'UI Interaction',
        props: {
          CTA: 'Deployment instance selected',
          action:
            'Deployment instance link clicked from applicationDetails page',
        },
      },
      deleteService: {
        event: 'Deleted Object',
        props: { action: 'Deleting service', objectType: 'button' },
      },
      deleteApp: {
        event: 'Deleted Object',
        props: { action: 'Deleting application', objectType: 'button' },
      },
    },
  },
  'Application Deployment Details': {
    path: '/applicationDeploymentDetails',
    events: {
      registerInstance: {
        event: 'Created Object',
        props: { action: 'Register instance', objectType: 'button' },
      },
      registerService: {
        event: 'Created Object',
        props: { action: 'Register service', objectType: 'button' },
      },
      registerServiceEndpoint: {
        event: 'Created Object',
        props: { action: 'Register service endpoint', objectType: 'button' },
      },
      deleteInstance: {
        event: 'Deleted Object',
        props: {
          action: 'Deleting application instance',
          objectType: 'button',
        },
      },
      deleteServiceEndpoint: {
        event: 'Deleted Object',
        props: { action: 'Deleting service endpoint', objectType: 'button' },
      },
    },
  },
  Policies: {
    path: '/connectionAccessPolicies',
    events: {
      createPolicyBtn: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Create policy',
          action: 'Create Policy button clicked from connectionAccessPolicies',
        },
      },
      createPolicy: {
        event: 'Created Object',
        props: { action: 'Create Policy modal', objectType: 'button' },
      },
      openPolicy: {
        event: 'Read Object',
        props: { action: 'Open policy', objectType: 'button' },
      },
    },
  },
  'Policy Details': {
    path: '/connectionaccesspolicydetails',
    events: {
      editPolicyDetails: {
        event: 'Updated Object',
        props: { action: 'Edit policy details', objectType: 'button' },
      },
      editPolicyConnection: {
        event: 'Updated Object',
        props: { action: 'Edit Policy connection', objectType: 'button' },
      },
      deletePolicy: {
        event: 'Deleted Object',
        props: { action: 'Delete policy', objectType: 'button' },
      },
    },
  },
  Topology: {
    path: '/topologies',
    events: {
      search: {
        event: 'Updated Object',
        props: { action: 'Search', objectType: 'button' },
      },
      canvasLink: {
        event: 'UI Interaction',
        props: {
          CTA: 'Canvas view',
          action: 'Clicked on the canvas view in topology',
        },
      },
      mapView: {
        event: 'UI Interaction',
        props: {
          CTA: 'Map view',
          action: 'Clicked on the map view in topology',
        },
      },
      viewFilter: {
        event: 'UI Interaction',
        props: {
          CTA: 'View filter',
          action: 'Clicked on the view filter in topology',
        },
      },
      expandTo: {
        event: 'UI Interaction',
        props: {
          CTA: 'Expand to',
          action: 'Clicked on the expand to in topology',
        },
      },
      openMetrics: {
        event: 'UI Interaction',
        props: { CTA: 'Open Metrics', action: 'Open the metrics panel' },
      },
      openSidePanel: {
        event: 'UI Interaction',
        props: {
          CTA: 'Expand to',
          action: 'Click on a node in topology to open details panel',
        },
      },
    },
  },
  Events: {
    path: '/event',
    events: {
      openEvents: {
        event: 'Read Object',
        props: { action: 'Open event', objectType: 'button' },
      },
      assignEvents: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Assign event',
          action: 'Assign event flow triggered',
        },
      },
    },
  },
  'Event Details': {
    path: '/eventDetails',
    events: {
      commentEvent: {
        event: 'CTA Clicked',
        props: { CTA: 'Comment on event', action: 'Comment on an event' },
      },
      deleteEvent: {
        event: 'Deleted Object',
        props: { action: 'Deleting event', objectType: 'button' },
      },
    },
  },
  Gateways: {
    path: '/gateways',
    events: {
      createGatewayBtn: {
        event: 'CTA Clicked',
        props: {
          CTA: 'Create gateway modal',
          action: 'Launch create gateway modal',
        },
      },
      openGatewayDetails: {
        event: 'Read Object',
        props: { action: 'Open gateway details', objectType: 'button' },
      },
      createGateway: {
        event: 'Created Object',
        props: { action: 'Create gateway', objectType: 'button' },
      },
    },
  },
  'Gateway Details': {
    path: '/gatewayDetails',
    events: {
      editGateway: {
        event: 'Updated Object',
        props: { action: 'Edit gateway', objectType: 'button' },
      },
      deleteGateway: {
        event: 'Deleted Object',
        props: { action: 'Delete gateway', objectType: 'button' },
      },
    },
  },
  'Login & Logout': {
    path: '',
    events: {
      userLogin: {
        event: 'Service Login',
        props: { action: 'User login', objectType: 'button' },
      },
      userLogout: {
        event: 'Service Logout',
        props: { action: 'User logout', objectType: 'button' },
      },
    },
  },
};
export default analyticsData;
