import moment from 'moment';
import { TFunction } from 'react-i18next';
import {
  MetricsQueryDirection,
  NetworkSegmentCompatibilitySet,
} from '../../../../lib/enums';

export type Group =
  | 'flp_connectionBytes'
  | 'flp_connectionBytes_Incomming'
  | 'flp_connectionBytes_Outgoing'
  | 'flp_connectionPackets'
  | 'flows_total'
  | 'avg_latency';

export type History =
  | 'oneHour'
  | 'oneDay'
  | 'oneWeek'
  | 'twoWeek'
  | 'threeWeek';

export type Resolution =
  | 'oneMinute'
  | 'fiveMinute'
  | 'oneHour'
  | 'thirtyMinute'
  | 'oneDay'
  | 'twentyFourHour';

export interface Data {
  group: Group;
  key: Date | number;
  value: number;
}
interface ParamsForMetricsQuery {
  appId: string;
  serviceId?: string;
  appNamespace?: string;
  svcNamespace?: string;
}
export interface AppMetric {
  metric: {
    app_cloud: string;
    app_cluster: string;
    app_id: string;
    app_infra_resource_group_id: string;
    app_location: string;
    app_name: string;
    app_namespace: string;
    app_resource_group_id: string;
    dstIP: string;
    srcIP: string;
    svc_cluster: string;
    svc_id: string;
    svc_name: string;
    svc_namespace: string;
    svc_resource_group_id: string;
    tenant_id: string;
  };
  values: { timestamp: number; value: string }[];
}

export const BYTES_UNIT = 'G';
export const PACKETS_UNIT = 'K';
export const FLOWS_UNIT = 'count';
export const LATENCY_UNIT = 'ms';

export const DEFAULT_HISTORY: History = 'oneHour';
export const DEFAULT_RESOLUTION: Resolution = 'fiveMinute';
export const DEFAULT_STEP = '24h';

export const typeToGroupMap = {
  bytesIncomming: 'flp_connectionBytes_Incomming',
  bytesOutgoing: 'flp_connectionBytes_Outgoing',
  totalBytes: 'flp_connectionBytes',
  totalPackets: 'flp_connectionPackets',
  totalFlows: 'flows_total',
  avgLatency: 'avg_latency',
};

export const typeToAxisText = {
  totalBytes: 'kilobytesTransfered',
  totalPackets: 'kilopacketsTransfered',
  totalFlows: 'flowCount',
  avgLatency: 'latencyms',
};

export const getTypeDropDown = (
  t: TFunction<'topologySidepanelApplicationServiceConnection', undefined>,
  compatibilitySet: string
) =>
  compatibilitySet === NetworkSegmentCompatibilitySet.Axon ||
  compatibilitySet === ''
    ? [
        {
          text: t('inOutbytes'),
          id: 'totalBytes',
          xAxisText: t('kilobytesTransfered'),
        },
        {
          text: t('totalPackets'),
          id: 'totalPackets',
          xAxisText: t('kilopacketsTransfered'),
        },
      ]
    : [
        {
          text: t('inOutbytes'),
          id: 'totalBytes',
          xAxisText: t('kilobytesTransfered'),
        },
        {
          text: t('flows_total'),
          id: 'totalFlows',
          xAxisText: t('totalFlows'),
        },
        {
          text: t('avg_latency'),
          id: 'avgLatency',
          xAxisText: t('avgLatency'),
        },
      ];

export const getDefaultHistoryResolution = (history: History) => {
  const config = {
    oneHour: 'fiveMinute',
    oneDay: 'oneHour',
    oneWeek: 'oneDay',
    twoWeek: 'oneDay',
    threeWeek: 'oneDay',
  };

  return config[history] as Resolution;
};

export const getHistoryDropDown = (
  t: TFunction<'topologySidepanelApplicationServiceConnection', undefined>
) => [
  {
    text: t('oneHour'),
    id: 'oneHour',
  },
  {
    text: t('oneDay'),
    id: 'oneDay',
  },
  {
    text: t('oneWeek'),
    id: 'oneWeek',
  },
  {
    text: t('twoWeek'),
    id: 'twoWeek',
  },
  {
    text: t('threeWeek'),
    id: 'threeWeek',
  },
];

export const getResolutionDropDown = (
  t: TFunction<'topologySidepanelApplicationServiceConnection', undefined>,
  history: History
) => {
  const config = {
    oneHour: [
      {
        text: t('oneMinute'),
        id: 'oneMinute',
      },
      {
        text: t('fiveMinute'),
        id: 'fiveMinute',
      },
      {
        text: t('thirtyMinute'),
        id: 'thirtyMinute',
      },
    ],
    oneDay: [
      {
        text: t('oneMinute'),
        id: 'oneMinute',
      },
      {
        text: t('fiveMinute'),
        id: 'fiveMinute',
      },
      {
        text: t('thirtyMinute'),
        id: 'thirtyMinute',
      },
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
    ],
    oneWeek: [
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
      {
        text: t('oneDay'),
        id: 'oneDay',
      },
    ],
    twoWeek: [
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
      {
        text: t('oneDay'),
        id: 'oneDay',
      },
    ],
    threeWeek: [
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
      {
        text: t('oneDay'),
        id: 'oneDay',
      },
    ],
  };

  return config[history];
};

export const getQueryParameters = (
  history: History,
  resolution: Resolution
) => {
  const dataKeys = {
    oneHour_oneMinute: {
      duration: 1,
      type: 'h',
      step: '1m',
    },
    oneHour_fiveMinute: {
      duration: 1,
      type: 'h',
      step: '5m',
    },
    oneHour_thirtyMinute: {
      duration: 1,
      type: 'h',
      step: '30m',
    },
    oneDay_oneMinute: {
      duration: 1,
      type: 'd',
      step: '1m',
    },
    oneDay_fiveMinute: {
      duration: 1,
      type: 'd',
      step: '5m',
    },
    oneDay_thirtyMinute: {
      duration: 1,
      type: 'd',
      step: '30m',
    },
    oneDay_oneHour: {
      duration: 1,
      type: 'd',
      step: '1h',
    },
    oneDay_twentyFourHour: {
      duration: 1,
      type: 'd',
      step: '24h',
    },
    oneWeek_oneHour: {
      duration: 1,
      type: 'w',
      step: '1h',
    },
    oneWeek_oneDay: {
      duration: 1,
      type: 'w',
      step: '1d',
    },
    twoWeek_oneHour: {
      duration: 2,
      type: 'w',
      step: '1h',
    },
    twoWeek_oneDay: {
      duration: 2,
      type: 'w',
      step: '1d',
    },
    threeWeek_oneHour: {
      duration: 3,
      type: 'w',
      step: '1h',
    },
    threeWeek_oneDay: {
      duration: 3,
      type: 'w',
      step: '1d',
    },
  };

  const key = `${history}_${resolution}`;

  return (dataKeys as any)[key] ? (dataKeys as any)[key] : null;
};

export const getMetricsQuery = (
  group: Group,
  history: History,
  resolution: Resolution,
  { appId, serviceId, appNamespace, svcNamespace }: ParamsForMetricsQuery
) => {
  const { duration, type, step } = getQueryParameters(history, resolution);
  const start = moment()
    .startOf('minute')
    .subtract(duration, type)
    .toISOString();
  const end = moment().startOf('minute').toISOString();
  let queryType;
  let direction;
  switch (group) {
    case typeToGroupMap.bytesIncomming:
      direction = MetricsQueryDirection.INCOMING;
      queryType = typeToGroupMap.totalBytes;
      return `sum by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)(increase(${queryType}{direction="${direction}", app_id="${appId}", app_namespace="${appNamespace}", svc_id="${serviceId}", svc_namespace="${svcNamespace}"}[${step}]))&start=${start}&end=${end}&step=${step}`;

    case typeToGroupMap.bytesOutgoing:
      direction = MetricsQueryDirection.OUTGOING;
      queryType = typeToGroupMap.totalBytes;
      return `sum by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)(increase(${queryType}{direction="${direction}", app_id="${appId}", app_namespace="${appNamespace}", svc_id="${serviceId}", svc_namespace="${svcNamespace}"}[${step}]))&start=${start}&end=${end}&step=${step}`;

    case typeToGroupMap.totalPackets:
      queryType = typeToGroupMap.totalPackets;
      return `increase(${queryType}{app_id="${appId}"}[${step}])&start=${start}&end=${end}&step=${step}`;

    case typeToGroupMap.totalFlows:
      queryType = typeToGroupMap.totalFlows;
      direction = MetricsQueryDirection.INCOMING;
      return `sum by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)(increase(${queryType}{direction="${direction}", app_id="${appId}", app_namespace="${appNamespace}", svc_id="${serviceId}", svc_namespace="${svcNamespace}"}[${step}]))&start=${start}&end=${end}&step=${step}`;

    case typeToGroupMap.avgLatency:
      const dir = MetricsQueryDirection.INCOMING;
      return (
        `avg by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)` +
        `(` +
        `increase(flow_latency_microseconds_sum{app_id="${appId}",svc_id="${serviceId}",app_namespace="${appNamespace}",svc_namespace="${svcNamespace}",direction="${dir}"}[${step}])` +
        '/' +
        `increase(flow_latency_microseconds_count{app_id="${appId}",svc_id="${serviceId}",app_namespace="${appNamespace}",svc_namespace="${svcNamespace}",direction="${dir}"}[${step}]))&start=${start}&end=${end}&step=${step}`
      );
  }
};

export const getTotalMetricsQuery = (
  { appId, serviceId, appNamespace, svcNamespace }: ParamsForMetricsQuery,
  group: Group,
  time?: string
) => {
  let queryType;
  let direction;

  switch (group) {
    case typeToGroupMap.totalBytes:
      queryType = typeToGroupMap.totalBytes;
      return `sum by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, tenant_id, app_resource_group_id, svc_resource_group_id)(increase(${queryType}{app_id="${appId}", app_namespace="${appNamespace}", svc_id="${serviceId}", svc_namespace="${svcNamespace}"}[${DEFAULT_STEP}]))`;

    case typeToGroupMap.totalPackets:
      queryType = typeToGroupMap.totalPackets;
      return `${queryType}{app_id="${appId}"}&time=${time}`;

    case typeToGroupMap.totalFlows:
      queryType = typeToGroupMap.totalFlows;
      direction = MetricsQueryDirection.INCOMING;
      return `sum by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)(increase(${queryType}{direction="${direction}", app_id="${appId}", app_namespace="${appNamespace}", svc_id="${serviceId}", svc_namespace="${svcNamespace}"}[${DEFAULT_STEP}]))`;

    case typeToGroupMap.avgLatency:
      direction = MetricsQueryDirection.INCOMING;
      return (
        `avg by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)` +
        `(` +
        `avg_over_time(flow_latency_microseconds_sum{app_id="${appId}",svc_id="${serviceId}",app_namespace="${appNamespace}",svc_namespace="${svcNamespace}",direction="${direction}"}[${DEFAULT_STEP}])` +
        '/' +
        `avg_over_time(flow_latency_microseconds_count{app_id="${appId}",svc_id="${serviceId}",app_namespace="${appNamespace}",svc_namespace="${svcNamespace}",direction="${direction}"}[${DEFAULT_STEP}]))`
      );
  }
};
