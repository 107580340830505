import React from 'react';
import { Tag, Tile, Link as CarbonLink } from 'carbon-components-react';

import './GatewayEnvironmentList.scss';
import { useNavigate } from 'react-router-dom';
import {
  removeFiltersFromStorage,
  removeViewStateFromStorage,
} from '../../../lib/utils';

interface Props {
  depEnvId: string;
  name: string;
  resGrp: any;
  labels: string[] | null;
  isNamespace?: boolean;
  partitionId?: string;
  depEnvName?: string | undefined;
}

const GwDeploymentEnvironmentList = (props: Props) => {
  const {
    depEnvId,
    name,
    resGrp,
    labels,
    isNamespace = false,
    partitionId,
    depEnvName,
    ...rest
  } = props;
  const navigate = useNavigate();
  const renderResGrp = () => {
    if (true) {
      return <div className='pairs'>{resGrp}</div>;
    }
  };

  const renderLabels = () => {
    if (!labels) {
      return <div className='labels no-labels' />;
    }
    if (!labels) {
      return <div className='labels no-labels' />;
    }
    if (labels.length > 3) {
      return (
        <div className='labels'>
          {labels.slice(0, 3).map(label => (
            <Tag type='green'>{label}</Tag>
          ))}
          <Tag type='green'>{`+${labels.slice(3).length}`}</Tag>
        </div>
      );
    } else {
      return (
        <div className='labels'>
          {labels.map(label => (
            <Tag type='green'>{label}</Tag>
          ))}
        </div>
      );
    }
  };

  const handleDeplEnvNavigate = () => {
    isNamespace
      ? navigate(
          `/partitionDetails?depEnvId=${depEnvId}&partitionId=${partitionId}`
        )
      : navigate(`/deploymentEnvironmentDetails?deplId=${depEnvId}`);

    removeFiltersFromStorage();
    removeViewStateFromStorage();
  };

  return (
    <Tile
      className={
        'gateway-env-service-tile' +
        (labels && labels && labels.length > 0 ? ' labels' : '')
      }
      {...rest}
    >
      <div className='service-info'>
        <div
          className='service-name'
          onClick={() => {
            handleDeplEnvNavigate();
          }}
        >
          {depEnvName ? `${depEnvName}/${name}` : name}
        </div>
        {renderResGrp()}
        {renderLabels()}
      </div>
    </Tile>
  );
};

export default GwDeploymentEnvironmentList;
