import { useEffect, useRef, useState } from 'react';
import {
  TooltipDefinition,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import MiddleEllipsis from './MiddleEllipsis/MiddleEllipsis';
import { splitStringToArray } from '../../lib/utils';

import './GenericResponsiveMiddleTruncation.scss';

interface Props {
  str: string;
  maxWidth?: number;
  minWidth?: number;
  hasTooltip?: boolean;
  strLength?: number;
  className?: string;
  isFullWidthText?: boolean;
}

const GenericResponsiveMiddleTruncation: React.FC<Props> = ({
  str,
  maxWidth,
  minWidth,
  hasTooltip = true,
  strLength = 0,
  isFullWidthText,
}) => {
  const strRef = useRef<HTMLParagraphElement>(null);
  const truncatedRef = useRef<HTMLParagraphElement>(null);
  const [strWidth, setStrWidth] = useState(0);
  const [truncatedWidth, setTruncatedWidth] = useState(0);

  const getStrWidthInPixels = (ref: HTMLParagraphElement) =>
    ref?.getBoundingClientRect()?.width;
  const getTruncatedWidthInPixels = (ref: HTMLParagraphElement) =>
    ref?.getBoundingClientRect()?.width;

  const truncateMiddle = (str: string) => {
    if (!str) return str;

    const stringArray = splitStringToArray(str);

    let truncateStringPositionFirst = '';
    let truncateStringPositionEnd = '';
    if (isFullWidthText) {
      truncateStringPositionFirst = stringArray
        .slice(0, stringArray?.length / 2)
        .join('');
      truncateStringPositionEnd = stringArray
        .slice(stringArray?.length / 2, str?.length)
        .join('');
    } else {
      if (str.length >= strLength) {
        truncateStringPositionFirst = stringArray
          .slice(0, (strLength - 3) / 2)
          .join('');
        truncateStringPositionEnd = stringArray
          .slice((strLength - 3) / 2, strLength + 3)
          .join('');
      } else {
        truncateStringPositionFirst = str;
        truncateStringPositionEnd = '';
      }
    }

    return (
      <div className='text-truncation'>
        <p className='truncate-text-start'>{truncateStringPositionFirst}</p>
        <p className={`truncate-text-end`}>{truncateStringPositionEnd}</p>
      </div>
    );
  };

  const truncatedText = truncateMiddle(str);

  useEffect(() => {
    setStrWidth(getStrWidthInPixels(strRef?.current!));
    setTruncatedWidth(getTruncatedWidthInPixels(truncatedRef?.current!));
  }, [strRef, truncatedRef, str]);

  const truncationStyles: any = {
    whiteSpace: 'nowrap',
    minWidth: minWidth || 0,
    maxWidth: maxWidth || truncatedWidth + 4,
  };

  return (
    <div className='generic-responsive-middle-truncation'>
      <p className='hidden-text' ref={strRef}>
        {str}
      </p>
      <p className='truncated-text opacity' ref={truncatedRef}>
        {truncatedText}
      </p>
      {truncatedWidth === 0 && <SkeletonPlaceholder className='skeleton' />}
      {truncatedWidth !== 0 &&
        Math.floor(strWidth) &&
        Math.floor(truncatedWidth) && (
          <div style={truncationStyles}>
            <MiddleEllipsis>
              <span>{str}</span>
              {hasTooltip ? (
                <TooltipDefinition
                  tooltipText={str}
                  direction='bottom'
                  align='center'
                  style={{ width: truncatedWidth ? truncatedWidth : '100%' }}
                >
                  {truncatedText}
                </TooltipDefinition>
              ) : null}
            </MiddleEllipsis>
          </div>
        )}
    </div>
  );
};

export default GenericResponsiveMiddleTruncation;
