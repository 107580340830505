import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SkeletonPlaceholder } from '@carbon/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  HealthStatusType,
  ProceduralStatusType,
} from '../../components/GatewayStatus/config';
import InlineNotification from '../../components/Notifications/Inline/Notification';
import dateUtils from '../../lib/dates';
import GwDeploymentEnvironmentsList from '../GatewayDetails/GatewayEnvironments/GatewayEnvironmentsList';
import RemoteConnectionsTable from './Remoteconnection/RemoteconnectionDetails/RemoteConnectionTable';
import GatewayDetailsVew from './GatewayDetailsView/GatewayDetailsView';
import Header from '../../components/Header/Header';
import { AxiosError } from 'axios';
import {
  Button,
  ButtonSkeleton,
  TooltipDefinition,
} from 'carbon-components-react';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import useAnalytics from '../../lib/useAnalytics';
import GatewayInstanceTable from './GatewayInstance/GatewayInstanceDetails/GatewayInstanceTable';
import GatewayNamespaceList from './GatewayEnvironments/GatewayNamespaceList';
import {
  ResourceGroup,
  Gateway,
  NetworkSegments,
  Cloud,
  Location,
  DeploymentEnvironment,
  Namespace,
  RemoteConnections,
  GatewayImageVersion,
  Error500Type,
  SkupperComputeProfile,
} from '../../models/master';
import {
  EnvironmentTypes,
  GatewaySubTypes,
  GatewayTypes,
  PartitionTypes,
  ResourceGroupTypes,
  VisibilityFlags,
} from '../../lib/enums';

import { getResourceGroups } from '../../controllers/resourceGroupApi';
import {
  getGateway,
  getGatewayDeploymentenvs,
  getGateways,
  getGatewayRemoteConnections,
  updateGateway,
  getRhsiComputeProfile,
} from '../../controllers/gateawayApis';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';
import { getCloudLocation } from '../../controllers/locationApis';
import { getCloud } from '../../controllers/cloudApis';
import {
  getDeploymentEnv,
  getDeploymentEnvs,
  getNamespace,
  getDeploymentEnvSubtypes,
  getNamespaceDetails,
} from '../../controllers/deploymentEnv';
import DeleteGateway from './DeleteGateway/DeleteGateway';
import Error500 from '../Errors/Error500';

import './GatewayDetailsPage.scss';
import analyticsData from '../../lib/analyticsEventData';
import EditGateway from '../Gateways/EditGateway/EditGateway';
import SkupperConfiguration from './ConfigurationSkupperSettings/SkupperConfiguration';
import {
  RHSICustomComputeProfile,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

const GatewayDetailsPage: React.FC = () => {
  const { t } = useTranslation('gatewayDetails');
  const [gwDetailsData, setGwDetailsData] = useState<Gateway | null>(null);
  const [deploymentEnvsData, setDeploymentEnvsData] = useState<any | []>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('gatewayId');
  const [gwDataLoading, setGwDataLoading] = useState(false);
  const [showEditGateway, setShowEditGateway] = useState(false);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [existingGatewayNames, setExistingGatewayNames] = useState<string[]>(
    []
  );
  const [showGatewayStatusError, setShowGatewayStatusError] = useState(false);
  const [gatewaysList, setGatewaysList] = useState<Gateway[] | null>(null);
  const [openDeleteGatewayModal, setOpenDeleteGatewayModal] = useState(false);
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegments[] | null
  >(null);
  const [rhsiComputeProfiles, setRhsiComputeProfiles] = useState<
    SkupperComputeProfile[]
  >([]);
  const [cloud, setCloud] = useState<Cloud | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [deployedEnv, setDeployedEnv] = useState<DeploymentEnvironment | null>(
    null
  );
  const [namespace, setNamespace] = useState<Namespace | null>(null);
  const [namespaceLoading, setNamespaceLoading] = useState(true);
  const [deploymentEnvsList, setDeployomentEnvList] = useState<
    DeploymentEnvironment[] | null
  >(null);
  const [remoteConnectionsData, setRemoteConnectionsData] = useState<
    RemoteConnections[] | null
  >(null);
  const [upgradeTearsheetOpen, toggleUpgradeTearsheet] = useState(false);
  const [upgradeError, setUpgradeError] = useState(false);
  const [upgradeErrorMessage, setUpgradeErrorMessage] = useState('');
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const [headerData, setHeaderData] = useState<any>({});

  const [depEnvSubTypesList, setDepEnvSubTypesList] = useState<any>(null);
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);
  const { trackButtonClicked, pageViewed } = useAnalytics();

  const breadcrumbs = [
    {
      url: '/',
      label: t('home'),
    },
    {
      url: '/gateways',
      label: t('gwServicesBreadcrumb'),
    },
  ];
  const wayptBreadcrumbs = [
    {
      url: '/',
      label: t('home'),
    },
    {
      url: '/gateways',
      label: t('wayptGwServicesBreadcrumb'),
    },
  ];

  const formatGwDetailsData = (data: Gateway) => {
    return {
      name: data?.name,
      infrasturctureGroup: getResourceGroupName(data?.resource_group_id),
      gatewaystatus: data?.procedural_status as ProceduralStatusType,
      lastUpdated: dateUtils.getUserFriendlyDate(data?.updated_at),
      labels: data?.labels as string[],
      description: data?.description ? data?.description : '—',
      cloud: getCloudData(),
      location: getLocationData(),
      healthStatus: data?.health_status as HealthStatusType,
      gwType: data?.subtype,
      intendedVersion: data?.intended_version,
      gatewaySubTypeName: t(data?.subtype),
      deployedIn: getDeployedIn(),
      deploymentType: data?.auto_deploy ? t('autodeploy') : t('manual'),
      terminate: data?.terminate,
      lifecycle_state: data?.lifecycle_state,
      procedure: data?.procedure,
      procedural_activity: data?.procedural_activity,
      unmanaged: data?.unmanaged,
      network_segment_id: data?.network_segment_id,
    };
  };

  const getResourceGroupName = (id: string | undefined) => {
    const resourceGroup =
      id && Array.isArray(resourceGroupsData)
        ? resourceGroupsData?.find(
            resourceGroup => resourceGroup.resource_id === id
          )
        : null;

    return resourceGroup ? resourceGroup.name : '—';
  };

  const getCloudData = () => {
    if (cloud) {
      return { resource_id: cloud.resource_id, name: cloud.name };
    } else if (deployedEnv) {
      return {
        resource_id: deployedEnv.cloud_id,
        name: deployedEnv.cloud_name,
      };
    }

    return null;
  };

  const getLocationData = () => {
    if (location) {
      return {
        resource_id: location.resource_id,
        cloud_id: location.cloud_id,
        name: location.name,
      };
    } else if (deployedEnv) {
      return {
        resource_id: deployedEnv.location_id,
        cloud_id: deployedEnv.cloud_id,
        name: deployedEnv.location_name,
      };
    }

    return null;
  };

  const fetchDepEnvSubTypes = async () => {
    try {
      const envSubtypesList = await getDeploymentEnvSubtypes();
      setDepEnvSubTypesList(envSubtypesList);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRhsiGwComputeProfiles = async () => {
    try {
      const computeProfiles = await getRhsiComputeProfile();
      Array.isArray(computeProfiles) && setRhsiComputeProfiles(computeProfiles);
    } catch (error) {
      console.log(error);
    }
  };

  const getNetworkSegmentData = () => {
    if (Array.isArray(networkSegments) && gwDetailsData) {
      if (
        gwDetailsData?.subtype === GatewaySubTypes.AXON_EDGE ||
        gwDetailsData?.subtype === GatewaySubTypes.AXON_WP
      ) {
        return {
          resource_id: defaultNetworkSegmentObject.resource_id,
          name: defaultNetworkSegmentObject.name,
        };
      } else {
        if (namespace) {
          const networkSegment = networkSegments.find(
            networkSegment =>
              networkSegment.resource_id === namespace.network_segment_id
          );

          if (networkSegment) {
            return {
              resource_id: networkSegment.resource_id,
              name: networkSegment.name,
            };
          }
        }
      }
    }

    return null;
  };

  const getDeployedIn = () => {
    if (deployedEnv) {
      return {
        name: deployedEnv.name,
        depEnvId: deployedEnv.resource_id,
        partitionId: '',
      };
    } else if (namespace) {
      return {
        name: namespace.name,
        depEnvId: namespace.cluster_id,
        partitionId: namespace.resource_id,
      };
    }

    return null;
  };

  const getConnectedNamespace = () => {
    if (id && namespace) {
      return namespace.gateway_id === id ? namespace : null;
    }

    return null;
  };

  const fetchGatewayData = async () => {
    //Gateway details data
    try {
      const gatewayData = await getGateway(id);
      setGwDetailsData(gatewayData);

      if (gatewayData.deployed_in_type && gatewayData.deployed_in_depl_env_id) {
        if (
          (gatewayData.deployed_in_type === EnvironmentTypes.VPC ||
            gatewayData.deployed_in_type === EnvironmentTypes.CLUSTER) &&
          gatewayData.deployed_in_depl_env_id
        ) {
          fetchDeployedEnv(gatewayData.deployed_in_depl_env_id);
        } else if (
          gatewayData.deployed_in_type === PartitionTypes.NAMESPACE &&
          gatewayData.cloud_id &&
          gatewayData.deployed_in_depl_env_id
        ) {
          if (gatewayData.deployed_in_partition_id) {
            fetchNamespace(
              gatewayData.cloud_id,
              gatewayData.deployed_in_depl_env_id,
              gatewayData.deployed_in_partition_id
            );
          } else {
            getNamespaceFromCluster();
          }
        }
      } else {
        if (
          gatewayData.subtype === GatewaySubTypes.AXON_EDGE ||
          gatewayData.subtype === GatewaySubTypes.AXON_WP
        ) {
          if (gatewayData.deployed_in_vpc) {
            fetchDeployedEnv(gatewayData.deployed_in_vpc);
          }
        } else if (gatewayData.subtype === GatewaySubTypes.RHSI_EDGE) {
          getNamespaceFromCluster();
        }
      }

      if (gatewayData.cloud_id) {
        fetchCloud(gatewayData.cloud_id);

        if (gatewayData.location_id) {
          fetchLocation(gatewayData.cloud_id, gatewayData.location_id);
        }
      }

      if (
        gatewayData.subtype === GatewaySubTypes.AXON_EDGE ||
        gatewayData.subtype === GatewaySubTypes.AXON_WP
      ) {
        fetchDeplEnvsData();
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 404) {
        navigate('/404');
      }
    }
  };

  const getNamespaceFromCluster = async () => {
    try {
      const deploymentenvironments: DeploymentEnvironment[] =
        await getDeploymentEnvs(VisibilityFlags.MANAGED, true);

      if (Array.isArray(deploymentenvironments)) {
        const clusters = deploymentenvironments.filter(
          deplenv => deplenv?.type === EnvironmentTypes.CLUSTER
        );

        for (const cluster of clusters) {
          try {
            const namespaceList = await getNamespace(
              cluster.cloud_id,
              cluster.resource_id
            );

            if (Array.isArray(namespaceList?.namespaces)) {
              const namespace = namespaceList?.namespaces.find(
                (namespace: Namespace) => namespace?.gateway_id === id
              );

              if (namespace) {
                setNamespace(namespace);
                return;
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNamespaceLoading(false);
    }
  };

  const fetchDeplEnvsData = async () => {
    //Gateway's supported environment list
    try {
      const gatewayDeplEnvData = await getGatewayDeploymentenvs(id);
      setDeploymentEnvsData(gatewayDeplEnvData);
    } catch (error) {
      setDeploymentEnvsData([]);
    }
  };

  const fetchNetworkSegments = async () => {
    try {
      const response = await getNetworkSegments();
      setNetworkSegments(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCloud = async (cloudId: string) => {
    try {
      const response = await getCloud(cloudId);
      setCloud(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLocation = async (cloudId: string, locationId: string) => {
    try {
      const response = await getCloudLocation(cloudId, locationId);
      setLocation(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeployedEnv = async (deplId: string) => {
    try {
      const response = await getDeploymentEnv(deplId);
      setDeployedEnv(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNamespace = async (
    cloudId: string,
    deplId: string,
    partitionId: string
  ) => {
    try {
      const response = await getNamespaceDetails(cloudId, deplId, partitionId);
      setNamespace(response);
    } catch (error) {
      console.error(error);
    } finally {
      setNamespaceLoading(false);
    }
  };

  const fetchDeploymentEnvsList = async () => {
    try {
      const deploymentEnvsList = await getDeploymentEnvs();
      setDeployomentEnvList(deploymentEnvsList);
    } catch (error) {
      setDeployomentEnvList([]);
    }
  };

  const fetchRemoteConnections = async (gateways: Gateway[] | null) => {
    try {
      const remoteGwConnectionsResponse = await getGatewayRemoteConnections(id);
      const remoteConnections = remoteGwConnectionsResponse?.map(
        (item: any) => ({
          ...item,
          name:
            gateways && Array.isArray(gateways)
              ? gateways?.find(
                  resource => resource.resource_id === item?.remote_gw_id ?? ''
                )?.name ?? ''
              : '',
        })
      );
      setRemoteConnectionsData(remoteConnections);
    } catch (error) {
      setRemoteConnectionsData([]);
    }
  };

  const fetchData = async () => {
    try {
      setGwDataLoading(true);

      const gateways = await getGateways();
      setGatewaysList(gateways);
      const existingGatewayNamesList = gateways.map(
        (gateway: Gateway) => gateway.name
      );
      setExistingGatewayNames(existingGatewayNamesList);

      await fetchGatewayData();

      fetchNetworkSegments();

      await fetchRemoteConnections(gateways);

      fetchDepEnvSubTypes();

      fetchDeploymentEnvsList();

      fetchRhsiGwComputeProfiles();

      const resourceGroupList = await getResourceGroups();
      setResourceGroupsData(
        resourceGroupList.resource_groups as ResourceGroup[]
      );
    } catch (error) {
      console.error(error);

      const err = error as AxiosError;
      if (err.response!?.status >= 500) {
        setError500(err.response!?.status.toString() as Error500Type);
      }
    } finally {
      setGwDataLoading(false);
    }
  };

  const requestUpgrade = async (data: any) => {
    let gatewayData = {
      intended_version: String(data.version),
      intended_compute_profile: data.profile,
    };
    try {
      setUpgradeLoading(true);
      await updateGateway(id, gatewayData);
      notification.onTrigger('TOAST', {
        title: t('upgradeVersion.successNotification.title'),
        subtitle: t('upgradeVersion.successNotification.subtitle', {
          name: gwDetailsData?.name,
        }),
      });
      toggleUpgradeTearsheet(false);
      fetchData();
    } catch (error) {
      const err = error as AxiosError;
      console.log('There was an error: ', error);
      setUpgradeError(true);
      setUpgradeErrorMessage(err?.message);
    } finally {
      setUpgradeLoading(false);
    }
  };

  useEffect(() => {
    pageViewed('Gateway Details');
  }, []);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (gwDetailsData?.status === 'errored' && !showGatewayStatusError) {
      setShowGatewayStatusError(true);
    } else {
      if (showGatewayStatusError) {
        setShowGatewayStatusError(false);
      }
    }
  }, [gwDetailsData?.status]);

  const closeTearsheet = () => {
    setShowEditGateway(false);
    fetchData();
  };

  useEffect(() => {
    const dataSet = {
      gwDetails: gwDetailsData,
    };
    setHeaderData(dataSet);
  }, [gwDetailsData]);

  const formatGwDataToEdit = (data: Gateway) => {
    const subType = data?.subtype;
    const deploymentEnv = deploymentEnvsList?.find(
      deplEnv => deplEnv?.resource_id === data?.deployed_in_depl_env_id
    );
    let deployedIn = getDeployedIn();
    let cloud = getCloudData();
    let location = getLocationData();
    if (subType === GatewaySubTypes.RHSI_EDGE) {
      return {
        namespaceName: deployedIn?.name ? deployedIn?.name : '',
        clusterName: deploymentEnv?.name ? deploymentEnv?.name : '',
        cloudName: cloud?.name ? cloud?.name : '',
        locationName: location?.name ? location?.name : '',
        ingressEnabled: false,
        ...data,
      };
    }
  };

  const openEditEdgeGatewayModal = (gatewayId: string) => {
    if (id === gwDetailsData?.resource_id) {
      setShowEditGateway(true);
    }
  };

  const getDeleteButton = () => {
    const namespaces = getConnectedNamespace();

    // namespaceLoading flag is added to make sure that namespace are fetched before enabling the delete gateway button
    if (namespaceLoading) {
      return <ButtonSkeleton />;
    }

    if (namespaces) {
      return (
        <TooltipDefinition
          className='tooltipDeleteGateway'
          direction='top'
          tooltipText={t('deleteGateway.tooltipText') as string}
        >
          <Button
            className='gatewayDeleteDisabled'
            disabled
            kind='danger--ghost'
          >
            {t('deleteGateway.deleteGatewayBtn')}
          </Button>
        </TooltipDefinition>
      );
    }

    return (
      <div className='delete-gateway'>
        <Button
          kind='danger--ghost'
          onClick={() => {
            trackButtonClicked(
              analyticsData['Gateway Details'].events.deleteGateway.props,
              analyticsData['Gateway Details'].events.deleteGateway.event
            );
            setOpenDeleteGatewayModal(true);
          }}
        >
          {t('deleteGateway.deleteGatewayBtn')}
        </Button>
      </div>
    );
  };

  const deploymentEnvironment = useMemo(() => {
    if (namespace && deploymentEnvsList && deploymentEnvsList?.length > 0) {
      return deploymentEnvsList.find(
        deplEnv => deplEnv.resource_id === namespace.cluster_id
      );
    }
    return undefined;
  }, [namespace, deploymentEnvsList]);

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='gateway-details-container'>
      <Header
        title={gwDetailsData ? headerData : ''}
        breadcrumbs={
          gwDetailsData?.type === GatewayTypes.EDGE
            ? breadcrumbs
            : wayptBreadcrumbs
        }
        loading={gwDataLoading}
      />
      <div className='gateway-details-section'>
        {gwDataLoading ? (
          <div className='tiles'>
            <SkeletonPlaceholder className='gateway-details-skeleton' />
            <SkeletonPlaceholder className='gateway-details-skeleton' />
          </div>
        ) : (
          <>
            {showGatewayStatusError && (
              <InlineNotification
                kind={'error'}
                hideCloseButton={true}
                title={t('gatewayStatusError.title')}
                subtitle={
                  <div className='gateway-status-error-notification'>
                    <div>
                      {t('gatewayStatusError.subtitle', {
                        gatewayName: gwDetailsData?.name,
                      })}
                    </div>
                    <div
                      className='link'
                      onClick={e => {
                        e.stopPropagation();
                        window.open(
                          window.location.origin +
                            process.env.PUBLIC_URL +
                            `/event?resource_name=${gwDetailsData?.name}`
                        );
                      }}
                    >
                      {t('gatewayStatusError.link')}
                    </div>
                  </div>
                }
              />
            )}

            <div className='tiles'>
              <GatewayDetailsVew
                data={gwDetailsData ? formatGwDetailsData(gwDetailsData) : null}
                isEdge={gwDetailsData?.type === GatewayTypes.EDGE}
                isWyPt={gwDetailsData?.type !== GatewayTypes.EDGE}
                isEditable={true}
                openEditModal={() => {
                  trackButtonClicked(
                    analyticsData['Gateway Details'].events.editGateway.props,
                    analyticsData['Gateway Details'].events.editGateway.event
                  );
                  gwDetailsData?.resource_id &&
                    openEditEdgeGatewayModal(gwDetailsData?.resource_id);
                }}
                openUpgradeTearsheet={() => toggleUpgradeTearsheet(true)}
              />

              {gwDetailsData?.type === GatewayTypes.EDGE &&
              gwDetailsData?.subtype === GatewaySubTypes.RHSI_EDGE &&
              !namespaceLoading ? (
                <GatewayNamespaceList
                  namespace={getConnectedNamespace()}
                  networkSegment={getNetworkSegmentData()}
                  deploymentEnvironment={deploymentEnvironment}
                />
              ) : (
                <SkeletonPlaceholder className='connected-namespace-skeleton' />
              )}
            </div>
          </>
        )}
        {showEditGateway && gwDetailsData && (
          <EditGateway
            deploymentEnvsList={deploymentEnvsList}
            depEnvSubTypesList={depEnvSubTypesList}
            resourceGroupsData={
              resourceGroupsData
                ? resourceGroupsData?.filter(
                    (group: any) =>
                      group.type === ResourceGroupTypes.INFRASTRUCTURE
                  )
                : null
            }
            gwDetailsData={gwDetailsData}
            gatewayDataToEdit={formatGwDataToEdit(gwDetailsData)}
            gatewayType={gwDetailsData?.type}
            gatewaySubtype={gwDetailsData?.subtype}
            gatewayNamesList={existingGatewayNames}
            open={showEditGateway}
            onClose={() => closeTearsheet()}
            gatewaysList={gatewaysList}
            onGatewayCreate={() => {
              setShowEditGateway(false);
              fetchData();
            }}
          />
        )}

        {gwDataLoading && (
          <SkeletonPlaceholder className='skupper-configuration-skeleton' />
        )}

        {gwDetailsData?.subtype === GatewaySubTypes.RHSI_EDGE &&
          !gwDataLoading && (
            <SkupperConfiguration
              skupperSiteConfig={
                gwDetailsData?.site_configuration
                  ? JSON.parse(gwDetailsData?.site_configuration)
                  : null
              }
              gatewaySizing={
                gwDetailsData?.intended_compute_profile ===
                RHSICustomComputeProfile.profile
                  ? RHSICustomComputeProfile
                  : rhsiComputeProfiles.find(
                      data =>
                        data?.profile ===
                        gwDetailsData?.intended_compute_profile
                    ) ?? null
              }
            />
          )}

        <RemoteConnectionsTable
          gatewayData={gwDetailsData}
          gatewayID={id}
          gatewaysList={gatewaysList}
          networkSegment={getNetworkSegmentData()}
        />
        {getDeleteButton()}
      </div>
      <DeleteGateway
        gatewayDetails={gwDetailsData}
        remoteConnections={remoteConnectionsData}
        deploymentEnvs={deploymentEnvsData}
        namespace={getConnectedNamespace()}
        open={openDeleteGatewayModal}
        cancelDeleteGatewayCallback={setOpenDeleteGatewayModal}
      />
      {/* <UpgradeGatewayVersion
        gatewayDetails={{
          name: gwDetailsData?.name ?? '',
          cloud: getCloudData()?.name ?? '',
          location: getLocationData()?.name ?? '',
          env: deployedEnv?.name ?? '',
          currentVersion: gwDetailsData?.intended_version ?? '',
          computeProfile: gwDetailsData?.intended_compute_profile ?? '',
        }}
        versions={[]}
        open={upgradeTearsheetOpen}
        requestUpgrade={(data: any) => requestUpgrade(data)}
        cancelUpgrade={() => toggleUpgradeTearsheet(false)}
        showErrorNotification={upgradeError}
        errorMessage={upgradeErrorMessage}
        upgradeLoading={upgradeLoading}
        closeErrorNotification={() => setUpgradeError(false)}
      /> */}
    </div>
  );
};

export default GatewayDetailsPage;
