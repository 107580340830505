export enum VisibilityFlags {
  ALL = 'all',
  MANAGED = 'managed',
  UNMANAGED = 'unmanaged',
}

export const policyFromTypes = {
  partition: 'partition',
  application: 'application',
};

export enum EnvironmentTypes {
  CLUSTER = 'cluster',
  VPC = 'vpc',
  NODE = 'node',
}

export enum CloudTypes {
  AWS = 'AWS',
  IBM = 'IBM',
  AZURE = 'AZURE',
  GCP = 'GCP',
  OTHER = 'other',
}

export enum eventSeverity {
  CRITICAL = 'critical',
  MINOR = 'minor',
  MAJOR = 'major',
  INFORMATION = 'information',
  CLEARED = 'cleared',
}

export enum QueryKeys {
  APPLICATIONS = 'applications',
  POLICIES = 'policies',
  DEPLOYMENTENVIRONMENTS = 'deploymentEnvironments',
  GATEWAYS = 'gateways',
  CLOUDS = 'clouds',
  LOCATIONS = 'locations',
  LOCATIONSDETAILS = 'locationsDetails',
  RESOURCEGROUPS = 'resourceGroups',
  TOPOLOGY = 'topology',
  DEPLOYMENTENVIRONMENTS_SUBTYPES = 'deploymentEnvironmentsSubtypes',
  CLOUD_DEPLOYMENTENVIRONMENTS = 'cloudDeploymentEnvironments',
  APPLICATIONDEPLOYMENTS = 'applications_deployments',
  LOCATIONSUNDERCLOUD = 'locations_under_cloud',
  CLOUDDETAILS = 'cloud_details',
  CLOUDTYPES = 'cloud_types',
  SUBSCRIPTION_USAGE = 'subscriptionUsage',
  USAGE_HISTORY = 'usageHistory',
  NAMESPACES = 'namespaces',
  PARTITIONDETAILS = 'partition_details',
  CLOUD_NAMESPACES = 'cloudNamespaces',
  NETWORKSEGMENTS = 'networksegments',
  GATEWAYAGENTS = 'gatewayagents',
  GATEWAYINGRESSTYPES = 'gatewayIngressTypes',
  GATEWAYCOMPUTEPROFILES = 'gatewayComputeProfiles',
  DEPENV_NAMESPACES = 'namespacesUnderDepEnv',
  GATEWAYREMOTECONNECTIONSLIST = 'gatewayRemoteconnectionsList',
  GATEWAYRDETAILSDATA = 'gatewayDetailsData',
}

export enum MutationKeys {
  ADDCLOUD = 'add_cloud',
  EDITCLOUD = 'update_cloud',
  DELETECLOUD = 'delete_cloud',
  LOCATIONADD = 'addlocation',
  LOCATIONUPDATE = 'updateLocation',
  LOCATIONDELETE = 'deleteLoctaion',
}

export enum filterTypes {
  ALLLABELS = 'allLabels',
  LABELS = 'labels',
  LOCATION = 'location',
  PORTS = 'ports',
}

export enum GatewayTypes {
  EDGE = 'edge',
  WAYPOINT = 'waypoint',
}

export enum GatewaySubTypes {
  AXON_EDGE = 'AXON-EDGE',
  AXON_WP = 'AXON-WP',
  RHSI_EDGE = 'RHSI-EDGE',
}

export enum HealthStatuses {
  OPERATIONAL = 'operational',
  MINOR = 'minor',
  CRITICAL = 'critical',
  UNREACHABLE = 'unreachable',
  NOTDEPLOYED = 'notdeployed',
  PENDING = 'pending',
}

export enum NetworkSegmentCompatibilitySet {
  ServiceInterconnect = 'RHSI',
  Axon = 'AXON',
}

export enum Connections {
  FROM = 'from',
  TO = 'to',
}

export enum PartitionTypes {
  NAMESPACE = 'namespace',
  SECURITYGROUP = 'securitygroup',
}

export enum RemoteConnectionDirection {
  outbound = 'Outbound to',
  inbound = 'Inbound from',
}

export enum ResourceGroupTypes {
  INFRASTRUCTURE = 'infrastructure',
  APPLICATION = 'application',
}

export enum ResourceTypes {
  CLOUD = 'CLOUD',
  APPLICATION = 'APPLICATION',
  POLICY = 'POLICY',
  ENVIRONMENT = 'ENVIRONMENT',
  GATEWAY = 'GATEWAY',
  LOCATION = 'LOCATION',
}

export enum AutodiscoveryStatus {
  COMPLETED = 'completed',
  INPROGRESS = 'in-progress',
  PENDING = 'pending',
  ERRORED = 'errored',
}

export enum ViewTypes {
  TABLE = 'table',
  CARD = 'card',
}

export enum TooltipPositionTypes {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum LocationTypes {
  SITE = 'site',
  ZONE = 'zone',
  REGION = 'region',
}

export enum ActionTypes {
  UPDATE = 'update',
  ADD = 'add',
}

export enum IngressTypes {
  ROUTER = 'router',
  LOADBALANCER = 'loadbalancer',
  NGINXINGRESS = 'nginx-ingress-v1',
  INGRESS = 'ingress',
  NONE = 'none',
}

export enum SubscriptionUsageBarColors {
  CURRENTMONTH = '#6929c4',
  PREVMONTHS = '#1192e8',
}

export enum ResourcesList {
  GATEWAYS = 'gateways',
  APPLICATIONS = 'applications',
  NAMESPACES = 'namespaces',
  POLICIES = 'policies',
}

export enum FromModule {
  CLOUD = 'cloud',
  LOCATION = 'location',
}

export enum DiscoveryStatus {
  COMPLETED = 'completed',
  INPROGRESS = 'in-progress',
  PENDING = 'pending',
  ERRORED = 'errored',
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export enum MetricsQueryDirection {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export enum MetricColors {
  PURPLE_70 = '#6929c4',
  CYAN_50 = '#1192e8',
  TEAL_70 = '#005d5d',
  BLUE_80 = '#002d9c',
}

export enum ConnectionSelectedTypes {
  FROM = 'from',
  TO = 'to',
}
export enum PolicyFromToData {
  NAMESPACES = 'namespaces',
  SERVICES = 'services',
  NETWORKSEGMENTS = 'networkSegments',
}
export enum PolicyDataype {
  SERVICE = 'service',
  NAMESPACE = 'namespace',
  NETWORKSEGMENT = 'networkSegment',
}
export const NotAuthorised = 'notAuthorised';

export enum RHSIComputeProfiles {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  CUSTOM = 'custom',
}

export enum GatewayLifeCycleState {
  UNKNOWN = 'unknown',
  GW_NOT_DEPLOYED = 'gw-not-deployed',
  GW_DEPLOYED = 'gw-deployed',
}

export enum ProceduralStatus {
  NOT_STARTED = 'not-started',
  SUCCEEDED = 'succeeded',
  IN_PROGRESS = 'in-progress',
  FAILED = 'failed',
  ERRORED = 'errored',
}
