import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Tooltip, TextInput, Toggle, ComboBox } from '@carbon/react';
import { Information16 } from '@carbon/icons-react';
import InlineNotification from '../../../components/Notifications/Inline/Notification';

import images from '../../../images/images';
import { Secret, CloudType } from '../../../models/master';
import {
  Button,
  RadioButton,
  RadioButtonGroup,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { NewTab16 } from '@carbon/icons-react';
import './RegisterCloud.scss';
import useAnalytics from '../../../lib/useAnalytics';
import { getThirdPartyCloudIcons } from '../ThirdPartyCloudIcons';
import analyticsData from '../../../lib/analyticsEventData';
import { CloudTypes } from '../../../lib/enums';

interface Props {
  selectedCloud: CloudType | null;
  formData: any;
  groups: any[];
  onChange: any;
  formValid: boolean;
  error: boolean;
  secretLoading: boolean;
  openRegisterSecret?: boolean;
  errorType: string;
  secrets: Secret[] | null;
  onCloseError: () => void;
  showAutoDiscover: boolean;
  onMount?: () => void;
  onRegisterSecret: () => void;
  customErrorMsg: string;
}

const AddDetails: React.FC<Props> = ({
  selectedCloud,
  formData,
  groups,
  onChange,
  formValid,
  error,
  errorType,
  secrets,
  onCloseError,
  showAutoDiscover,
  onMount,
  secretLoading,
  onRegisterSecret,
  openRegisterSecret = false,
  customErrorMsg,
}) => {
  const { t } = useTranslation('registerCloud');
  const { trackButtonClicked } = useAnalytics();
  const {
    name,
    description,
    credentials_key,
    is_private,
    infrastructureGroup,
    auto_discover,
  } = formData;

  return (
    <CreateTearsheetStep
      className='add-details-step'
      fieldsetLegendText={t('addDetails.fieldsetLegendText')}
      title={t('addDetails.title')}
      disableSubmit={!formValid}
      onNext={() => {}}
      onMount={onMount}
    >
      {error && (
        <InlineNotification
          onClose={() => onCloseError() as any}
          kind={'error'}
          title={
            errorType === '403'
              ? (t('failureNotification.authErrorTitle') as string)
              : errorType === 'generic'
              ? (t('failureNotification.genericTitle') as string)
              : (t('failureNotification.title') as string)
          }
          subtitle={
            errorType === '403'
              ? (t('failureNotification.authErrorSubtitle') as string)
              : customErrorMsg.length > 0
              ? customErrorMsg
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      {secretLoading ? (
        <div className='skeleton-card-view'>
          <SkeletonPlaceholder className={'cloudSelect-skeleton'} />
          <SkeletonPlaceholder className={'cloudSelect-skeleton'} />
          <SkeletonPlaceholder className={'cloudSelect-skeleton'} />
          <SkeletonPlaceholder className={'cloudSelect-skeleton'} />
        </div>
      ) : (
        <div className='add-details-container'>
          <div className='cloud-card cloud-card-border'>
            {selectedCloud !== null && (
              <>
                <div className='title'>
                  {selectedCloud.name}
                  <Tooltip description={selectedCloud.description}>
                    <Information16 />
                  </Tooltip>
                </div>
                <div className='cloud-selected'>
                  {images.selectedTickIcon()}
                </div>
                <div className='cloud-icon'>
                  {getThirdPartyCloudIcons(selectedCloud?.code)}
                </div>
              </>
            )}
          </div>
          <TextInput
            className='form-item'
            id='register-cloud-name'
            labelText={t('addDetails.name.label')}
            placeholder={t('addDetails.name.placeholder')}
            name='name'
            value={name.value}
            onChange={(e: any) => onChange('name', e.target.value)}
            maxLength={60}
            invalid={name.error}
            invalidText={name.errorMessage}
          />

          {selectedCloud?.code === 'other' && (
            <>
              <RadioButtonGroup
                className='form-item'
                legendText={t('addDetails.availability.label')}
                name='private_cloud'
                defaultSelected='false'
                onChange={(e: any) => onChange('is_private', e === 'true')}
                valueSelected={is_private.toString()}
              >
                <RadioButton
                  labelText={t('addDetails.availability.public')}
                  value='false'
                  id='public'
                />
                <RadioButton
                  labelText={t('addDetails.availability.private')}
                  value='true'
                  id='private'
                />
              </RadioButtonGroup>
            </>
          )}
          <TextInput
            className='form-item'
            id='register-cloud-description'
            labelText={t('addDetails.description.label')}
            placeholder={t('addDetails.description.placeholder')}
            name='description'
            value={description}
            onChange={(e: any) => onChange('description', e.target.value)}
            maxCount={300}
            enableCounter={true}
          />
          {showAutoDiscover && (
            <div className='autodiscover-group'>
              <Toggle
                className='form-item'
                id='register-cloud-autodiscover'
                labelText={t('addDetails.autodiscover.label')}
                labelA={t('addDetails.autodiscover.off')}
                labelB={t('addDetails.autodiscover.on')}
                toggled={auto_discover}
                disabled={!(secrets != null && secrets.length > 0)}
                onToggle={(val: any) => {
                  if (val)
                    trackButtonClicked(
                      analyticsData['Cloud'].events.cloudAutodiscoverOn.props,
                      analyticsData['Cloud'].events.cloudAutodiscoverOn.event
                    );
                  onChange('auto_discover', val);
                }}
              />
              {secrets != null && secrets.length > 0 ? (
                <ComboBox
                  className='secretInput'
                  disabled={!auto_discover}
                  placeholder={t('addDetails.secret.placeholder')}
                  id='register-cloud-secret'
                  titleText={t('addDetails.secret.label')}
                  translateWithId={t}
                  size='lg'
                  items={Array.isArray(secrets) ? secrets : []}
                  itemToString={(item: any) => item?.name}
                  onChange={(e: any) => {
                    onChange('credentials_key', e.selectedItem);
                  }}
                  selectedItem={credentials_key}
                />
              ) : (
                <div className='noSecretsContent'>
                  <span className='secretLabel'>{t('noSecret')}</span>
                  <p className='no-secret-text'>{t('noSecretDescription')}</p>
                  <Button
                    id='register-secret-btn'
                    onClick={() => onRegisterSecret()}
                    disabled={openRegisterSecret}
                  >
                    <div className='secret-link'>
                      {t('registersSecretLink')}
                      <NewTab16 />
                    </div>
                  </Button>
                </div>
              )}
            </div>
          )}
          <ComboBox
            className='form-item'
            id='infrastructureGroup-combobox'
            titleText={t('addDetails.group.label')}
            name='infrastructureGroup'
            placeholder={t('addDetails.group.placeholder')}
            items={groups}
            itemToString={(item: any) => item?.name}
            selectedItem={infrastructureGroup}
            translateWithId={t}
            onChange={(e: any) =>
              onChange('infrastructureGroup', e.selectedItem)
            }
          />
        </div>
      )}
    </CreateTearsheetStep>
  );
};

export default AddDetails;
