import React from 'react';
import {
  TextInput,
  Row,
  FormLabel,
  Column,
  TextArea,
  InlineNotification,
} from 'carbon-components-react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Dropdown } from '@carbon/react';
import { useTranslation } from 'react-i18next';

import {
  ResourceGroup,
  DeploymentEnvironmentSubtype,
} from '../../../models/master';

import AddLabels from '../../../components/AddLabels/AddLabels';
import { getThirdPartyCloudIconsDropdown } from '../../Cloud/ThirdPartyCloudIcons';
import GenericManageButton from '../../../components/GenericManageButton/GenericManageButton';

const NamespaceDetailsForm = (props: {
  onChange: any;
  formData: any;
  appResourceGroups: ResourceGroup[] | null;
  partitionType: any;
  deploymentdata: any;
  appsDeployed?: any;
  mode: 'ADD' | 'EDIT';
  partitionData?: any;
  hasConnectedPolicy: boolean;
  isSubmitDisabled: boolean;
  showFailNotification: boolean;
  gatewayFailNotification: boolean;
  showUpdateFailNotification: boolean;
  handleErrorBarClose: () => void;
  handleGatewayErrorBarClose: () => void;
  handleNamespaceUpdateErrorBarClose: () => void;
  authError: boolean;
  gatewayAuthError: boolean;
  isSubmitting: boolean;
  allDepEnvSubTypes: DeploymentEnvironmentSubtype[];
  id: string;
}) => {
  const {
    onChange,
    formData,
    partitionType,
    deploymentdata,
    appsDeployed,
    id,
  } = props;
  const { t } = useTranslation('namespaceRegister');

  const nameSpaceLabel = () => {
    return (
      <FormLabel className='label namespace-label'>
        {t('namespacedetails.namespaceName')}
      </FormLabel>
    );
  };

  return (
    <CreateTearsheetStep
      className='namespace-details-tearsheet'
      fieldsetLegendText={
        <div className='tearsheet-header'>
          {t('namespaceDetails')}
          <GenericManageButton
            isCheckboxDisabled={
              formData.managedStatus.value &&
              (formData.gatewayName?.value ||
                props.hasConnectedPolicy ||
                (appsDeployed && appsDeployed.length > 0))
              // (gatewayConnectedRemoteConnections &&
              //   gatewayConnectedRemoteConnections.length > 0)
            }
            checked={formData.managedStatus.value}
            showTooltip={
              formData.managedStatus.value &&
              (formData.gatewayName?.value ||
                props.hasConnectedPolicy ||
                (appsDeployed && appsDeployed.length > 0))
            }
            key='managedStatus'
            toolTipText={t('manageTooltipDisabled')}
            id={`managedBtn-${id}`}
            handleCheckbox={(checked: boolean) => {
              onChange('managedStatus', checked);
            }}
          />
        </div>
      }
      title={t('defineNamespace')}
      disableSubmit={props.isSubmitting || props.isSubmitDisabled}
    >
      {props.showFailNotification && (
        <InlineNotification
          onClose={() => props.handleErrorBarClose() as any}
          kind={'error'}
          title={
            props.authError
              ? (t('failureNotification.authTitle') as string)
              : (t('failureNotification.title') as string)
          }
          subtitle={
            props.authError
              ? (t('failureNotification.authSubtitle') as string)
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      {props.gatewayFailNotification && (
        <InlineNotification
          onClose={() => props.handleGatewayErrorBarClose() as any}
          kind={'error'}
          title={
            props.gatewayAuthError
              ? (t('gatewayFailureNotification.authTitle') as string)
              : (t('gatewayFailureNotification.title') as string)
          }
          subtitle={
            props.gatewayAuthError
              ? (t('gatewayFailureNotification.authSubtitle') as string)
              : (t('gatewayFailureNotification.subtitle') as string)
          }
        />
      )}
      {props.showUpdateFailNotification && (
        <InlineNotification
          onClose={() => props.handleNamespaceUpdateErrorBarClose() as any}
          kind={'error'}
          title={
            props.authError
              ? (t('namespaceUpdate.failureNotification.authTitle') as string)
              : (t('namespaceUpdate.failureNotification.title') as string)
          }
          subtitle={
            props.authError
              ? (t(
                  'namespaceUpdate.failureNotification.authSubtitle'
                ) as string)
              : (t('namespaceUpdate.failureNotification.subtitle') as string)
          }
        />
      )}
      <div className='register-namespace-form'>
        <Row className='row'>
          <Column md={4} className='namespacedetailsCol cloud-column'>
            <Dropdown
              id='namespace_cloud_name'
              className='form-field'
              titleText={t('namespacedetails.cloud') as string}
              readOnly
              selectedItem={props?.allDepEnvSubTypes?.find(
                (type: DeploymentEnvironmentSubtype) =>
                  type.type_code === deploymentdata?.subtype
              )}
              renderSelectedItem={(selectedType: any) => (
                <div className='nameSpaceVal'>
                  <span className='cloud-icon-cont'>
                    {getThirdPartyCloudIconsDropdown(
                      selectedType?.cloud_type_code
                    )}
                  </span>
                  <span className='cloud-name'>
                    {deploymentdata?.cloud_name ?? '—'}
                  </span>
                </div>
              )}
              items={props?.allDepEnvSubTypes}
            />
          </Column>
          <Column md={4} className='namespacedetailsCol'>
            <TextInput
              className='form-field'
              labelText={t('namespacedetails.location') as string}
              value={deploymentdata?.location_name}
              id='namespace_location_name'
              readOnly
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={4} className='namespacedetailsCol'>
            <TextInput
              className='form-field'
              labelText={t('namespacedetails.depenvName') as string}
              value={deploymentdata?.name}
              id='namespace_depEnv_name'
              readOnly
            />
          </Column>
          <Column md={4} className='namespacedetailsCol'>
            <TextInput
              id='addNamespaceName'
              className='form-field'
              name='name'
              placeholder={
                partitionType === 'namespace'
                  ? t('form.name.placeholder')
                  : t('form.name.placeholderSecurity')
              }
              labelText={nameSpaceLabel()}
              value={formData.name.value}
              onChange={e => onChange('name', e.target.value)}
              readOnly={
                formData?.networkSegment?.value &&
                formData?.gateway?.value &&
                !formData?.gateway?.isDisconnected
              }
              invalid={formData.name.error}
              invalidText={formData.name.errorMessage}
              maxLength={60}
            />
          </Column>
        </Row>
        {formData?.gateway?.value && !formData?.gateway?.isDisconnected ? (
          <Row className='row autodiscoverIntoRow'>
            <Column md={4}>
              <Dropdown
                light
                items={props?.appResourceGroups ?? []}
                itemToString={(item: ResourceGroup) => item?.name}
                id={`select-appresource-dropdown`}
                selectedItem={formData.appResourceGroup?.value}
                onChange={(e: any) =>
                  onChange('appResourceGroup', e?.selectedItem)
                }
                label={t('form.autodiscoverInto.label')}
                titleText={t('form.autodiscoverInto.label')}
              />
            </Column>
          </Row>
        ) : null}
        <Row className='row'>
          <Column md={8}>
            <AddLabels
              id='registerNamespaceLabels'
              labelText={t('form.labels.label')}
              placeholder={t('form.labels.placeholder')}
              onChange={data => onChange('labels', data)}
              btnText={t('form.labels.buttonText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={formData.labels.value}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8} className='descriptionContainer'>
            <TextArea
              id='register-namespace-desciption'
              name='description'
              autoComplete='off'
              labelText={t('form.description.label')}
              placeholder={t('form.description.placeholder')}
              onChange={(e: { target: { value: any } }) =>
                onChange('description', e.target.value)
              }
              value={formData?.description.value}
              maxCount={300}
              enableCounter={true}
            />
          </Column>
        </Row>
      </div>
    </CreateTearsheetStep>
  );
};

export default NamespaceDetailsForm;
