import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Column,
  Button,
  TooltipDefinition,
  Row,
  SkeletonPlaceholder,
  OnChangeData,
  Grid,
} from 'carbon-components-react';
import {
  Add16,
  ArrowDown16,
  ArrowUp16,
  ArrowsVertical16,
} from '@carbon/icons-react';

import images from '../../images/images';
import CloudSearch from './CloudSearch';
import LocationCard from './LocationCard';
import CloudLocationDeplEnv from './CloudLocationDeplEnv';
import Overflow from '../../components/Overflow/Overflow';
import Error403Card from '../../components/ErrorState/Error403Card';
import RegisterLocation from '../Locations/RegisterLocation/RegisterLocation';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import RegisterDeploymentEnv from '../DeploymentEnvsContainer/RegisterDeploymentEnv/RegisterDeploymentEnv';

import sortData from '../../lib/tableSort';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

import { deleteCloud } from '../../controllers/cloudApis';
import {
  useCloudDeploymentEnvs,
  useDeploymentEnvSubtypes,
} from '../../hooks/useDeploymentEnvs';
import { useLocationsUnderCloud } from '../../hooks/useLocations';
import { useCloudNamespaces } from '../../hooks/useNamespace';
import { useNetworksegments } from '../../hooks/useNetworksegment';
import { useGateways } from '../../hooks/useGateways';
import { usePoliciesData } from '../../hooks/usePolicies';
import { useApplicationsDeploymentsData } from '../../hooks/useApplications';

import {
  ActionTypes,
  Direction,
  FromModule,
  LocationTypes,
  QueryKeys,
  VisibilityFlags,
  policyFromTypes,
} from '../../lib/enums';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';
import {
  AppliedFilter,
  Cloud,
  Deployment,
  DeploymentEnvironment,
  Location,
  Namespace,
  ResourceGroup,
} from '../../models/master';

import './CloudLocationList.scss';
import NamespaceRegister from '../NamespaceRegister/NamespaceRegister';

interface Props {
  cloudDetails: Cloud;
  resourceGroups: ResourceGroup[] | null;
  resourceGroupPermission: boolean;
}

const CloudLocationList = forwardRef(
  ({ cloudDetails, resourceGroups, resourceGroupPermission }: Props, ref) => {
    const { t } = useTranslation('cloudDetails');

    const navigate = useNavigate();

    const notification = useContext(NotificationContext);

    const { trackButtonClicked } = useAnalytics();

    const queryClient = useQueryClient();

    const locationBodyRef = useRef<HTMLDivElement | null>(null);

    const [selectedLocation, setSelectedLocation] = useState<Location | null>(
      null
    );
    const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>(
      []
    );
    const [filteredData, setFilteredData] = useState<Location[] | []>([]);
    const [showRegisterLocation, setShowRegisterLocation] = useState(false);
    const [showEditLocation, setShowEditLocation] = useState(false);
    const [showRegisterDeploymentEnv, setShowRegisterDeploymentEnv] =
      useState(false);
    const [showEditDeploymentEnv, setShowEditDeploymentEnv] = useState(false);
    const [selectedDeploymentEnv, setSelectedDeploymentEnv] =
      useState<DeploymentEnvironment | null>(null);
    const [showRegisterNamespace, setShowRegisterNamespace] = useState(false);
    const [showEditNamespace, setShowEditNamespace] = useState(false);
    const [selectedNamespace, setSelectedNamespace] =
      useState<Namespace | null>(null);
    const [disableCloudDeleteBtn, setDisableCloudDeleteBtn] = useState(false);
    const [deleteCloudModal, toggleDeleteCloudModal] = useState(false);
    const [locationVisibility, setLocationVisibility] =
      useState<VisibilityFlags>(VisibilityFlags.ALL);
    const [locationSortDirection, setLocationSortDirection] = useState(
      Direction.NONE
    );
    const [deplEnvSortDirection, setDeplEnvSortDirection] = useState(
      Direction.NONE
    );

    const defaultPermissionMap = {
      location: true,
      deploymentEnv: true,
      namespace: true,
      networkSegmant: true,
      gateway: true,
    };

    const {
      data: locations = [],
      isLoading: isLocationsLoading,
      refetch: refreshLocation,
      isRefetching: isRefetchingLocation,
      error: locationError,
      isError: isLocationError,
    } = useLocationsUnderCloud(
      cloudDetails.resource_id ?? '',
      VisibilityFlags.ALL,
      {
        refetchOnWindowFocus: false,
        enabled: !!cloudDetails,
      }
    );

    if (isLocationError) {
      const error = locationError as AxiosError;
      defaultPermissionMap.location =
        error?.response?.status === 403 ? false : true;
    }

    const locationLoading = isLocationsLoading || isRefetchingLocation;

    const {
      data: deploymentEnvironments,
      isLoading: isDeploymentEnvsLoading,
      refetch: refetchDeploymentEnvs,
      isRefetching: isRefetchingDeploymentEnvs,
      error: deploymentEnvironmentsError,
      isError: isDeploymentEnvironmentsError,
    } = useCloudDeploymentEnvs(
      cloudDetails.resource_id ?? '',
      VisibilityFlags.ALL
    );

    if (isDeploymentEnvironmentsError) {
      const error = deploymentEnvironmentsError as AxiosError;
      defaultPermissionMap.deploymentEnv =
        error?.response?.status === 403 ? false : true;
    }

    const deploymentEnvLoading =
      isDeploymentEnvsLoading || isRefetchingDeploymentEnvs;

    const generateDepEnvLocationMap = () => {
      const deplEnvLocationMap: { [key: string]: DeploymentEnvironment[] } = {};

      if (Array.isArray(locations) && Array.isArray(deploymentEnvironments)) {
        for (const location of locations) {
          const filteredDeplEnv = deploymentEnvironments?.filter(
            (deplEnv: DeploymentEnvironment) =>
              deplEnv.location_id === location.resource_id
          );

          deplEnvLocationMap[location.resource_id] = filteredDeplEnv;
        }
      }

      return deplEnvLocationMap;
    };

    const deplEnvLocationMap = useMemo(
      () => generateDepEnvLocationMap(),
      [locations, deploymentEnvironments, defaultPermissionMap]
    );

    const getDepEnvInLocation = (locationId: string) => {
      if (!defaultPermissionMap['deploymentEnv']) {
        return [];
      }

      const deploymentEnvList = deplEnvLocationMap[locationId];

      return deploymentEnvList ?? [];
    };

    const {
      data: namespaces,
      isLoading: isNamespaceLoading,
      refetch: refetchNamespaces,
      isRefetching: isRefetchingNamespace,
      error: namespaceError,
      isError: isNamespaceErrorError,
    } = useCloudNamespaces(
      cloudDetails.resource_id ?? '',
      deploymentEnvironments,
      VisibilityFlags.ALL,
      { enabled: Array.isArray(deploymentEnvironments) }
    );

    if (isNamespaceErrorError) {
      const error = namespaceError as AxiosError;
      defaultPermissionMap.namespace =
        error?.response?.status === 403 ? false : true;
    }

    const nameSpaceLoading = isNamespaceLoading || isRefetchingNamespace;

    const generateNamespaceDepEnvMap = () => {
      const namespaceDeplEnvMap: { [key: string]: Namespace[] } = {};

      if (Array.isArray(deploymentEnvironments) && Array.isArray(namespaces)) {
        for (const deploymentEnvironment of deploymentEnvironments) {
          const filteredNamespaces = namespaces?.filter(
            namespace =>
              namespace.cluster_id === deploymentEnvironment.resource_id
          );

          namespaceDeplEnvMap[deploymentEnvironment.resource_id] =
            filteredNamespaces;
        }
      }

      return namespaceDeplEnvMap;
    };

    const namespaceDeplEnvMap = useMemo(
      () => generateNamespaceDepEnvMap(),
      [deploymentEnvironments, namespaces]
    );

    const { data: deploymentEnvironmentSubtypes = [] } =
      useDeploymentEnvSubtypes();

    const {
      data: networksegments = [],
      refetch: refetchNetworksegmants,
      error: networkSegmantError,
      isError: isNetworkSegmantError,
    } = useNetworksegments();

    if (isNetworkSegmantError) {
      const error = networkSegmantError as AxiosError;
      defaultPermissionMap.networkSegmant =
        error?.response?.status === 403 ? false : true;
    }

    const {
      data: gateways,
      refetch: refetchGateways,
      error: gatewayError,
      isError: isGatewayError,
    } = useGateways();
    if (isGatewayError) {
      const error = gatewayError as AxiosError;
      defaultPermissionMap.gateway =
        error?.response?.status === 403 ? false : true;
    }

    const { data: policies } = usePoliciesData({
      fetchServices: false,
      fetchApplications: false,
      count: 0,
    });

    const checkConnectedPolicy = () => {
      const hasConnectedPolicy =
        Array.isArray(policies) &&
        selectedNamespace &&
        policies.filter(
          policy =>
            policy?.from?.type === policyFromTypes.partition &&
            policy?.from?.partition?.partition_id ===
              selectedNamespace?.resource_id
        ).length > 0;

      return hasConnectedPolicy ? true : false;
    };

    const hasConnectedPolicy = useMemo(
      () => checkConnectedPolicy(),
      [policies, selectedNamespace]
    );

    const { data: appDeployments } = useApplicationsDeploymentsData();

    const getPolicyAppDeployments = () => {
      let policyAppDeployments: Deployment[] = [];

      if (Array.isArray(appDeployments) && selectedNamespace) {
        policyAppDeployments = appDeployments.filter(
          (appDeployment: Deployment) =>
            appDeployment.partition_id === selectedNamespace.resource_id
        );
      }

      return policyAppDeployments;
    };

    const policyAppDeployments = useMemo(
      () => getPolicyAppDeployments(),
      [appDeployments, selectedNamespace]
    );

    const getCloudRegionMap = () => {
      const cloudRegionMap = new Map();

      if (Array.isArray(locations)) {
        locations
          .filter(
            (location: Location) => location.type === LocationTypes.REGION
          )
          .forEach((location: Location) => {
            cloudRegionMap.set(location.name, [
              location.resource_id,
              location.cloud_location_code,
              location.cloud_id,
            ]);
          });
      }

      return cloudRegionMap;
    };

    const cloudRegionMap = useMemo(() => getCloudRegionMap(), [locations]);

    useImperativeHandle(ref, () => ({
      refetchLocationAndDeploymentEnv() {
        refreshLocation();
        refetchDeploymentEnvs();
      },
    }));

    const deleteLocationCloud = async () => {
      try {
        trackButtonClicked(
          analyticsData['Cloud Details'].events.deleteCloud.props,
          analyticsData['Cloud Details'].events.deleteCloud.event
        );

        setDisableCloudDeleteBtn(true);

        await deleteCloud(cloudDetails.resource_id);

        // Trigger success toastbar
        notification.onTrigger('TOAST', {
          title: t('cloudNotificationMessages.successNotificationTitle'),
          subtitle: t('cloudNotificationMessages.removeCloudSuccess', {
            name: cloudDetails.name,
          }),
        });

        inValidateListCacheFn(QueryKeys.CLOUDS);
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CLOUDS],
        });

        navigate('/clouds');
      } catch (error: any) {
        const err = error as AxiosError;
        if (err.response?.status === 403) {
          notification.onTrigger('TOAST', {
            kind: 'error',
            title: `${t('cloudNotificationMessages.authErrorTitle')}`,
            subtitle: t('cloudNotificationMessages.authErrorSubtitle'),
          });
        }

        const errorMessage: string =
          error.response !== undefined
            ? error.response['customErrorMessage']
            : '';
        notification.onTrigger('TOAST', {
          kind: 'error',
          title: `${t('cloudNotificationMessages.errorNotificationTitle')}`,
          subtitle:
            errorMessage.length > 0
              ? errorMessage
              : t('cloudNotificationMessages.removeCloudFail'),
        });
      } finally {
        toggleDeleteCloudModal(false);
        setDisableCloudDeleteBtn(false);
      }
    };

    const getLocationBodyHeight = () => {
      if (locationBodyRef.current) {
        const element = locationBodyRef.current.getBoundingClientRect();
        const height = element.height;
        return height > 184 ? `${height}px` : '184px';
      }

      return 'unset';
    };

    const scrollLocationIntoView = (locationId: string) => {
      const locationCard = document.getElementById(
        `location-card-${locationId}`
      );

      if (locationCard) {
        locationCard.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const leftInlineFilters: any = [
      {
        key: 'view',
        label: t('view'),
        type: '',
        values: [
          {
            value: VisibilityFlags.ALL,
            label: t('all'),
          },
          {
            value: VisibilityFlags.MANAGED,
            label: t('managed'),
          },
          {
            value: VisibilityFlags.UNMANAGED,
            label: t('unmanaged'),
          },
        ],
        filterCallback: (e: OnChangeData<any>) => {
          setSelectedLocation(null);
          setLocationVisibility(e.selectedItem.value);
        },
      },
    ];

    const getLocationFilters = (locations: Location[]) => [
      {
        key: 'type',
        label: t('filter.location.locationType'),
        placeHolderText: t('filter.location.locationTypePlaceholder'),
        translationReqd: true,
        translationData: {
          site: t('locationTypes.site'),
          region: t('locationTypes.region'),
          zone: t('locationTypes.zone'),
        },
        type: 'single',
        values: [
          ...Array.from(
            new Set(locations?.map((location: Location) => location?.type))
          ),
        ],
      },
      {
        key: 'city',
        label: t('filter.location.city'),
        placeHolderText: t('filter.location.cityPlaceholder'),
        type: 'multi',
        values: [
          ...Array.from(
            new Set(locations?.map((location: Location) => location?.city))
          ),
        ],
      },
      {
        key: 'country',
        label: t('filter.location.country'),
        placeHolderText: t('filter.location.countryPlaceholder'),
        type: 'multi',
        values: [
          ...Array.from(
            new Set(locations?.map((location: Location) => location?.country))
          ),
        ],
      },
      {
        key: 'labels',
        type: 'multi',
        label: t('filter.location.labels'),
        placeHolderText: t('filter.location.labelsPlaceholder'),
        values: [
          ...Array.from(
            new Set(
              (locations ? locations : [])
                .map((location: Location) => location?.labels)
                .flat()
            )
          ),
        ],
      },
    ];

    const getDeplEnvSubTypeName = (deploymentEnv: DeploymentEnvironment) => {
      if (Array.isArray(deploymentEnvironmentSubtypes)) {
        const subType = deploymentEnvironmentSubtypes.find(
          subType => subType.type_code === deploymentEnv?.subtype
        );

        return subType?.type_name ?? '—';
      }

      return '';
    };

    const handleLocationSelect = (location: Location) => {
      trackButtonClicked(
        analyticsData['Cloud Details'].events.locationDetails.props,
        analyticsData['Cloud Details'].events.locationDetails.event
      );

      setSelectedLocation(location);
    };

    const showRegisterLocationForm = () => {
      setSelectedLocation(null);
      setShowRegisterLocation(true);
    };

    const handleLocationAdd = (location: Location) => {
      if (location) {
        setSelectedLocation(location);

        queryClient.setQueryData(
          [
            QueryKeys.LOCATIONSUNDERCLOUD,
            cloudDetails.resource_id ?? '',
            VisibilityFlags.ALL,
          ],
          (prevLocations: Location[] | undefined) =>
            Array.isArray(prevLocations)
              ? [...prevLocations, location]
              : [location]
        );

        setTimeout(() => {
          scrollLocationIntoView(location.resource_id);
        });
      }
    };

    const showEditLocationForm = (location: Location) => {
      setSelectedLocation(location);
      setShowEditLocation(true);
    };

    const handleLocationEdit = (location: Location) => {
      if (location) {
        queryClient.setQueryData(
          [
            QueryKeys.LOCATIONSUNDERCLOUD,
            cloudDetails.resource_id ?? '',
            VisibilityFlags.ALL,
          ],
          (prevLocations: Location[] | undefined) => {
            if (Array.isArray(prevLocations)) {
              return prevLocations.map(prevLocation => {
                if (prevLocation.resource_id === location.resource_id) {
                  // If the visibility of location changed and if it is not visible anymore we need to unselect it
                  if (
                    locationVisibility !== VisibilityFlags.ALL &&
                    prevLocation.unmanaged !== location.unmanaged
                  ) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(location);
                  }

                  return location;
                }

                return prevLocation;
              });
            }

            return prevLocations;
          }
        );

        setTimeout(() => {
          scrollLocationIntoView(location.resource_id);
        });
      }
    };

    const showRegisterDeploymentEnvForm = () => {
      trackButtonClicked(
        analyticsData['Cloud Details'].events.registerDepEnv.props,
        analyticsData['Cloud Details'].events.registerDepEnv.event
      );

      setShowRegisterDeploymentEnv(true);
    };

    const handleDeploymentEnvAdd = (deplEnv: DeploymentEnvironment) => {
      if (deplEnv) {
        refetchDeploymentEnvs();

        // Select and scroll to location of that deployment env if it is not already selected
        if (
          deplEnv.location_id &&
          deplEnv.location_id !== selectedLocation?.resource_id &&
          Array.isArray(locations)
        ) {
          const location = locations.find(
            location => location.resource_id === deplEnv.location_id
          );

          setSelectedLocation(location);

          setTimeout(() => {
            scrollLocationIntoView(location.resource_id);
          });
        }
      }
    };

    const getDeploymentType = () => {
      if (
        selectedDeploymentEnv &&
        Array.isArray(deploymentEnvironmentSubtypes)
      ) {
        const selectedDepEnvSubType = deploymentEnvironmentSubtypes.find(
          depEnvSubtype =>
            depEnvSubtype.type_code === selectedDeploymentEnv.subtype
        );

        return (
          selectedDepEnvSubType?.resource_type ?? selectedDeploymentEnv?.type
        );
      }

      return '';
    };

    const getSelectedVPC = (vpcId: string) => {
      if (Array.isArray(deploymentEnvironments)) {
        const vpc = deploymentEnvironments.find(
          deplEnv => deplEnv.resource_id === vpcId
        );

        if (vpc) {
          return vpc;
        }
      }
    };

    const getEditDeploymentEnvData = () => {
      if (
        selectedDeploymentEnv &&
        Array.isArray(deploymentEnvironmentSubtypes)
      ) {
        const selectedVPC = selectedDeploymentEnv?.deployed_in_vpc
          ? getSelectedVPC(selectedDeploymentEnv.deployed_in_vpc)
          : null;

        return {
          name: {
            value: selectedDeploymentEnv?.name || '',
            error: false,
            errorMessage: '',
          },
          description: selectedDeploymentEnv?.description || '',
          auto_discover: selectedDeploymentEnv?.auto_discover || false,
          location: {
            id: selectedDeploymentEnv?.location_id || '',
            name: selectedDeploymentEnv?.location_name || '',
            type: selectedDeploymentEnv?.type || '',
          },
          resource_group: {
            id: selectedDeploymentEnv?.resource_group_id || '',
            name: Array.isArray(resourceGroups)
              ? resourceGroups.find(
                  (resourceGroup: any) =>
                    resourceGroup?.resource_id ===
                    selectedDeploymentEnv?.resource_group_id
                )?.name ?? ''
              : '',
          },
          is_discovered: selectedDeploymentEnv?.is_discovered || false,
          unmanaged: (selectedDeploymentEnv?.unmanaged as boolean) ?? true,
          labels:
            selectedDeploymentEnv?.labels !== null
              ? selectedDeploymentEnv?.labels || []
              : [],
          cloud_id: selectedDeploymentEnv?.cloud_id || '',
          type: getDeploymentType(),
          subtype: selectedDeploymentEnv?.subtype || '',
          deployed_in_vpc: {
            id: selectedDeploymentEnv?.deployed_in_vpc || '',
            name: selectedVPC?.name ?? '',
          },
          infra_only: selectedDeploymentEnv?.infra_only
            ? selectedDeploymentEnv?.infra_only
            : false,
        };
      }
    };

    const showEditDeploymentEnvForm = (
      deploymentEnv: DeploymentEnvironment
    ) => {
      setSelectedDeploymentEnv(deploymentEnv);
      setShowEditDeploymentEnv(true);
    };

    const handleDeploymentEnvEdit = (deplEnv: DeploymentEnvironment) => {
      if (deplEnv) {
        setShowEditDeploymentEnv(false);
        refetchDeploymentEnvs();
      }
    };

    const showRegisterNamespaceForm = (
      deploymentEnv: DeploymentEnvironment
    ) => {
      setSelectedDeploymentEnv(deploymentEnv);
      setShowRegisterNamespace(true);
    };

    const handleNamespaceAdd = () => {
      setSelectedDeploymentEnv(null);
      setShowRegisterNamespace(false);

      refetchNamespaces();
      refetchNetworksegmants();
      refetchGateways();
    };

    const showEditNamespaceForm = (
      deploymentEnv: DeploymentEnvironment,
      namespace: Namespace
    ) => {
      setSelectedNamespace(namespace);
      setSelectedDeploymentEnv(deploymentEnv);
      setShowEditNamespace(true);
    };

    const handleNamespaceEdit = () => {
      setSelectedNamespace(null);
      setSelectedDeploymentEnv(null);
      setShowEditNamespace(false);

      refetchNamespaces();
      refetchNetworksegmants();
      refetchGateways();
    };

    const handleLocationSort = () => {
      if (
        locationSortDirection === Direction.DESC ||
        locationSortDirection === Direction.NONE
      ) {
        setLocationSortDirection(Direction.ASC);
      } else {
        setLocationSortDirection(Direction.DESC);
      }
    };

    const handleDeplEnvSort = () => {
      if (
        deplEnvSortDirection === Direction.DESC ||
        deplEnvSortDirection === Direction.NONE
      ) {
        setDeplEnvSortDirection(Direction.ASC);
      } else {
        setDeplEnvSortDirection(Direction.DESC);
      }
    };

    const handleLocationSearch = (data: Location) => {
      setSelectedLocation(data);

      scrollLocationIntoView(data.resource_id);
    };

    const handleDeplEnvSearch = (
      location: Location,
      deplEnv: DeploymentEnvironment
    ) => {
      setSelectedLocation(location);

      scrollLocationIntoView(location.resource_id);

      setTimeout(() => {
        const deplEnvCard = document.getElementById(
          `deploymentEnv-card-${deplEnv.resource_id}`
        );

        if (deplEnvCard) {
          deplEnvCard.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    };

    const handleNamespaceSearch = (
      location: Location,
      cluster: DeploymentEnvironment,
      namespace: Namespace
    ) => {
      setSelectedLocation(location);

      scrollLocationIntoView(location.resource_id);

      setTimeout(() => {
        const deplEnvCard = document.getElementById(
          `deploymentEnv-card-${cluster.resource_id}`
        );

        if (deplEnvCard) {
          deplEnvCard.scrollIntoView({ behavior: 'smooth' });

          const namespaceChevron = document.getElementById(
            `namespace-chevron-${cluster.resource_id}`
          );

          if (namespaceChevron) {
            const chevronText =
              namespaceChevron.querySelector('.label')?.firstChild?.nodeValue;

            if (chevronText === t('expand')) {
              namespaceChevron.click();
              namespaceChevron.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
              });
            }
          }
        }
      }, 1000);
    };

    const getSortIcon = (direction: 'ASC' | 'DESC' | 'NONE') => {
      const config = {
        ASC: ArrowUp16,
        DESC: ArrowDown16,
        NONE: ArrowsVertical16,
      };

      return config[direction];
    };

    const getSortIconTooltip = (direction: 'ASC' | 'DESC' | 'NONE') => {
      const config = {
        ASC: t('sortAsc'),
        DESC: t('sortDesc'),
        NONE: t('sortNone'),
      };

      return config[direction];
    };

    const selectedLocationEnvs = selectedLocation
      ? getDepEnvInLocation(selectedLocation?.resource_id)
      : [];

    const locationList =
      filterApplied?.length > 0 ? filteredData : (locations as Location[]);

    const getVisibiltyFilteredLocations = () => {
      if (!defaultPermissionMap['location']) {
        return [];
      }

      return Array.isArray(locationList)
        ? locationList.filter(location => {
            if (locationVisibility === VisibilityFlags.MANAGED) {
              return !location.unmanaged;
            } else if (locationVisibility === VisibilityFlags.UNMANAGED) {
              return location.unmanaged;
            }

            return location;
          })
        : [];
    };

    const filteredLocations: Location[] = useMemo(
      () => getVisibiltyFilteredLocations(),
      [locations, locationList, locationVisibility, defaultPermissionMap]
    );

    const sortedLocationList = Array.isArray(filteredLocations)
      ? sortData([...filteredLocations], 'name', locationSortDirection)
      : [];

    const isLocationEmpty = filteredLocations.length === 0;

    const locationCount = filteredLocations.length;

    const haveZone = Array.isArray(locations)
      ? locations?.some(
          (location: Location) =>
            location.type === LocationTypes.ZONE &&
            location.parent_location_id === selectedLocation?.resource_id
        )
      : false;

    const deploymentEnvs = selectedLocation
      ? getDepEnvInLocation(selectedLocation.resource_id)
      : null;

    const sortedDeplEnvs = Array.isArray(deploymentEnvs)
      ? sortData([...deploymentEnvs], 'name', deplEnvSortDirection)
      : deploymentEnvs;

    const renderCustomSearch = () => (
      <CloudSearch
        locations={locationList}
        deploymentEnvs={deploymentEnvironments}
        getDeplEnvSubTypeName={getDeplEnvSubTypeName}
        namespaces={namespaces}
        onLocationSearch={handleLocationSearch}
        onDeplEnvSearch={handleDeplEnvSearch}
        onNamespaceSearch={handleNamespaceSearch}
      />
    );

    return (
      <div className='cloud-locations-list'>
        {locationLoading && (
          <SkeletonPlaceholder className='cloud-location-skeleton' />
        )}

        {!locationLoading && (
          <div>
            <Row>
              <Column>
                <FindAndFilterBar
                  data={Array.isArray(locations) ? locations : []}
                  filteredDataCallback={data => {
                    setFilteredData(data as Location[] | []);
                  }}
                  filteredData={
                    locations
                      ? Array.isArray(filterApplied) && filterApplied.length > 0
                        ? filteredData
                        : locations
                      : null
                  }
                  filtersApplied={filterApplied as any}
                  filtersAppliedCallback={data => {
                    setSelectedLocation(null);
                    setFilterApplied(data);
                  }}
                  filters={getLocationFilters(filteredLocations) as any}
                  showRefresh={false}
                  onRefresh={() => null}
                  leftInlineFilters={leftInlineFilters}
                  visibilityFlag={locationVisibility}
                  customSearch={renderCustomSearch()}
                />
              </Column>
            </Row>

            <Row>
              <Grid fullWidth className='data-container'>
                <Row>
                  <Column xlg={5} lg={6} md={5} className='location-session'>
                    <div className='header'>
                      <h3 className='title'>
                        {t('locations')} ({locationCount})
                      </h3>
                      <div className='button-group'>
                        {!cloudDetails.auto_discover &&
                          defaultPermissionMap['location'] && (
                            <Button
                              kind='ghost'
                              iconDescription={t('registerLocation')}
                              renderIcon={Add16}
                              onClick={showRegisterLocationForm}
                              data-testid='register-location-btn'
                            >
                              {t('registerLocation')}
                            </Button>
                          )}
                        <Button
                          hasIconOnly
                          kind='ghost'
                          iconDescription={getSortIconTooltip(
                            locationSortDirection
                          )}
                          renderIcon={getSortIcon(locationSortDirection)}
                          onClick={handleLocationSort}
                          data-testid='sort-location-btn'
                        />
                      </div>
                    </div>

                    <aside className='body' ref={locationBodyRef}>
                      {isLocationEmpty && (
                        <div className='no-locations-empty-state'>
                          {!defaultPermissionMap['location'] ? (
                            <Error403Card />
                          ) : (
                            <CardEmptyState
                              filterApplied={filterApplied}
                              emptyState={{
                                icon: images.noLocationsLarge(),
                                header: t('emptyState.emptyLocationsHeader'),
                                description: t(
                                  'emptyState.emptyLocationsDescription'
                                ),
                              }}
                            />
                          )}
                        </div>
                      )}

                      {!isLocationEmpty && (
                        <div className='locations-cards'>
                          {Array.isArray(sortedLocationList) &&
                            sortedLocationList.map(location => (
                              <LocationCard
                                key={location.resource_id}
                                location={location}
                                deploymentEnvs={getDepEnvInLocation(
                                  location.resource_id
                                )}
                                selected={
                                  selectedLocation?.resource_id ===
                                  location.resource_id
                                }
                                onSelect={handleLocationSelect}
                                onEdit={showEditLocationForm}
                              />
                            ))}
                        </div>
                      )}
                    </aside>

                    <div className='cloud-delete-button'>
                      {Array.isArray(locations) &&
                      locations.length === 0 &&
                      !cloudDetails.auto_discover ? (
                        <Button
                          kind='danger--ghost'
                          onClick={() => toggleDeleteCloudModal(true)}
                        >
                          {t('deleteButton')}
                        </Button>
                      ) : (
                        <TooltipDefinition
                          direction='top'
                          className='delete-cloud-tooltip'
                          tooltipText={t('deleteCloudTooltip') as string}
                        >
                          <Button kind='danger--ghost' disabled>
                            {t('deleteButton')}
                          </Button>
                        </TooltipDefinition>
                      )}
                    </div>
                  </Column>

                  <Column
                    xlg={11}
                    lg={10}
                    md={8}
                    className='deploymentenv-session'
                  >
                    <div className='header'>
                      <h3 className='title'>
                        <div className='name'> {t('deploymentEnvs')}</div>
                        {selectedLocation && (
                          <div className='in'>{t('in')}</div>
                        )}
                        {selectedLocation && (
                          <Overflow
                            align='start'
                            toolTipDirection='bottom'
                            key={selectedLocation.resource_id}
                          >
                            <span className='location-name'>
                              {selectedLocation.name}
                            </span>
                          </Overflow>
                        )}
                        <div className='count'>
                          ({selectedLocationEnvs?.length})
                        </div>
                      </h3>
                      <div className='button-group'>
                        {!selectedLocation &&
                          !cloudDetails.auto_discover &&
                          defaultPermissionMap['location'] && (
                            <Button
                              kind='ghost'
                              iconDescription={t('registerEnvironment')}
                              renderIcon={Add16}
                              onClick={showRegisterDeploymentEnvForm}
                              data-testid='register-deployment-env-btn'
                            >
                              {t('registerEnvironment')}
                            </Button>
                          )}
                        {selectedLocation &&
                          !selectedLocation.unmanaged &&
                          !cloudDetails.auto_discover &&
                          defaultPermissionMap['location'] && (
                            <Button
                              kind='ghost'
                              iconDescription={t('registerEnvironment')}
                              renderIcon={Add16}
                              onClick={showRegisterDeploymentEnvForm}
                              data-testid='register-deployment-env-btn-selected'
                            >
                              {t('registerEnvironment')}
                            </Button>
                          )}
                        <Button
                          hasIconOnly
                          kind='ghost'
                          iconDescription={getSortIconTooltip(
                            deplEnvSortDirection
                          )}
                          renderIcon={getSortIcon(deplEnvSortDirection)}
                          onClick={handleDeplEnvSort}
                          data-testid='sort-deployment-env-btn'
                        />
                      </div>
                    </div>

                    <section className='body'>
                      {isLocationEmpty && (
                        <div className='no-locations-depl-empty-state'>
                          {!defaultPermissionMap['deploymentEnv'] ? (
                            <Error403Card />
                          ) : (
                            <CardEmptyState
                              filterApplied={[]}
                              emptyState={{
                                icon: images.NoDeploymentEmptySmall(),
                                header: t(
                                  'emptyState.emptyEnvironmentsPartitionHeader'
                                ),
                                description: t(
                                  'emptyState.emptyEnvironmentsPartitionDescription'
                                ),
                              }}
                            />
                          )}
                        </div>
                      )}

                      {!isLocationEmpty && !selectedLocation && (
                        <div className='no-location-selected-state'>
                          <CardEmptyState
                            filterApplied={[]}
                            emptyState={{
                              icon: images.noLocationsLarge(),
                              header: t('emptyState.noLocationSelectedHeader'),
                              description: t(
                                'emptyState.noLocationSelectedDescription'
                              ),
                            }}
                          />
                        </div>
                      )}

                      {!isLocationEmpty &&
                        selectedLocation &&
                        deploymentEnvLoading && (
                          <SkeletonPlaceholder
                            className='cloud-location-skeleton'
                            style={{ minHeight: getLocationBodyHeight() }}
                          />
                        )}

                      {!isLocationEmpty &&
                        selectedLocation &&
                        !deploymentEnvLoading &&
                        selectedLocationEnvs?.length === 0 && (
                          <div
                            className='no-deployment-env-state'
                            style={{ minHeight: getLocationBodyHeight() }}
                          >
                            {!defaultPermissionMap['deploymentEnv'] ? (
                              <Error403Card />
                            ) : (
                              <CardEmptyState
                                filterApplied={[]}
                                emptyState={{
                                  icon: images.NoDeploymentEmptySmall(),
                                  header: t(
                                    'emptyState.emptyEnvironmentsPartitionHeader'
                                  ),
                                  description: t(
                                    'emptyState.emptyEnvironmentsPartitionLocationDescription'
                                  ),
                                }}
                              />
                            )}
                          </div>
                        )}

                      {!isLocationEmpty &&
                        selectedLocation &&
                        !deploymentEnvLoading &&
                        selectedLocationEnvs?.length > 0 && (
                          <div
                            className='location-deployment-envs'
                            style={{ minHeight: getLocationBodyHeight() }}
                          >
                            {Array.isArray(sortedDeplEnvs) &&
                              sortedDeplEnvs.map(
                                (
                                  deploymentEnv: DeploymentEnvironment,
                                  index
                                ) => (
                                  <CloudLocationDeplEnv
                                    key={deploymentEnv.resource_id}
                                    index={index}
                                    location={selectedLocation}
                                    gateways={gateways}
                                    resourceGroups={resourceGroups}
                                    deploymentEnv={deploymentEnv}
                                    networksegments={networksegments}
                                    namespaceDeplEnvMap={namespaceDeplEnvMap}
                                    nameSpaceLoading={nameSpaceLoading}
                                    getDeplEnvSubTypeName={
                                      getDeplEnvSubTypeName
                                    }
                                    defaultPermissionMap={{
                                      ...defaultPermissionMap,
                                      resourceGroup: resourceGroupPermission,
                                    }}
                                    onEdit={showEditDeploymentEnvForm}
                                    onRegisterNamespace={
                                      showRegisterNamespaceForm
                                    }
                                    onEditNamespace={showEditNamespaceForm}
                                  />
                                )
                              )}
                          </div>
                        )}
                    </section>
                  </Column>
                </Row>
              </Grid>
            </Row>
          </div>
        )}

        {showRegisterLocation && (
          <RegisterLocation
            open={showRegisterLocation}
            onClose={() => setShowRegisterLocation(false)}
            refreshLocation={(location: Location) =>
              handleLocationAdd(location)
            }
            cloudRegionMap={cloudRegionMap}
            cloudDetails={cloudDetails}
            actionType={ActionTypes.ADD}
            fromModule={FromModule.CLOUD}
          />
        )}

        {showEditLocation && (
          <RegisterLocation
            open={showEditLocation}
            onClose={() => setShowEditLocation(false)}
            refreshLocation={() => refreshLocation()}
            cloudRegionMap={cloudRegionMap}
            actionType={ActionTypes.UPDATE}
            locationDetails={selectedLocation}
            cloudDetails={cloudDetails}
            updateCallBack={data => handleLocationEdit(data)}
            allLocationsDeploymentData={selectedLocationEnvs}
            haveZone={haveZone}
            fromModule={FromModule.CLOUD}
          />
        )}

        <RegisterDeploymentEnv
          open={showRegisterDeploymentEnv}
          onClose={() => setShowRegisterDeploymentEnv(false)}
          refreshData={deplEnv => handleDeploymentEnvAdd(deplEnv)}
          deploymentEnvList={deploymentEnvironments}
          deplEnvSubtypesList={deploymentEnvironmentSubtypes}
          selectedLocationProps={selectedLocation}
          selectedCloudProps={cloudDetails as Cloud}
          isEditMode={false}
        />

        {showEditDeploymentEnv && selectedDeploymentEnv && (
          <RegisterDeploymentEnv
            open={showEditDeploymentEnv}
            onClose={() => {
              setShowEditDeploymentEnv(false);
              setSelectedDeploymentEnv(null);
            }}
            refreshData={deplEnv => handleDeploymentEnvEdit(deplEnv)}
            editFormData={getEditDeploymentEnvData()}
            editDeploymentEnvData={selectedDeploymentEnv}
            selectTypeId={selectedDeploymentEnv?.resource_id}
            deploymentEnvList={deploymentEnvironments}
            deplEnvSubtypesList={deploymentEnvironmentSubtypes}
            deploymentType={getDeploymentType()}
            selectedCloudProps={cloudDetails as Cloud}
            selectedLocationProps={selectedLocation}
            isEditMode={true}
            isManageMode={selectedDeploymentEnv?.unmanaged as boolean}
          />
        )}

        <NamespaceRegister
          open={showRegisterNamespace}
          onClose={() => {
            setShowRegisterNamespace(false);
            setSelectedDeploymentEnv(null);
          }}
          partitonType={'namespace'}
          deploymentdata={selectedDeploymentEnv}
          createPartition={handleNamespaceAdd}
          mode='ADD'
        />

        <NamespaceRegister
          open={showEditNamespace}
          onClose={() => {
            setShowEditNamespace(false);
            setSelectedDeploymentEnv(null);
            setSelectedNamespace(null);
          }}
          partitonType={'namespace'}
          deploymentdata={selectedDeploymentEnv}
          createPartition={handleNamespaceEdit}
          partitionData={selectedNamespace}
          mode='EDIT'
          appsDeployed={policyAppDeployments}
          hasConnectedPolicy={hasConnectedPolicy}
          registerNamespaceId={selectedNamespace?.resource_id}
        />

        <Modal
          className='delete-cloud-modal'
          danger
          modalHeading={t('deleteModal.header')}
          onRequestClose={() => toggleDeleteCloudModal(false)}
          onRequestSubmit={deleteLocationCloud}
          primaryButtonText={t('deleteModal.confirm')}
          secondaryButtonText={t('deleteModal.cancel')}
          open={deleteCloudModal}
          size={'xs'}
          primaryButtonDisabled={disableCloudDeleteBtn}
        >
          {t('deleteModal.body', { name: cloudDetails.name })}
        </Modal>
      </div>
    );
  }
);

export default CloudLocationList;
