import { axiosInstance } from './api';
import { MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL, MCNM_NETWORK_API_URL, MCNM_TENANT_API_URL } from './config';

export const getGateways = async (view = 'all') => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway?visibility=${view}`,
    });

    return response?.data?.gateways;
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};

export async function getGatewayIngressTypes() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL}/rhsi_ingress_types`,
    });
    return response?.data?.Ingresses || [];
  } catch (error) {
    throw error;
  }
}

export async function getGatewayAgentList(availabilityValue = false) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TENANT_API_URL}/gwagentnodes?only_available=${availabilityValue}`,
    });

    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getGatewayComputeProfiles() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL}/rhsi_compute_profile`,
    });
    return response?.data?.Profiles || [];
  } catch (error) {
    throw error;
  }
}

// Get all of the remote connections for this gateway
export async function getGatewayRemoteConnections(gateway_id: string) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}/remoteconnection`,
    });

    return response?.data?.remoteconnections;
  } catch (error) {
    console.log(error);
    return [];
  }
}

//Get one of the gateways that MCNM oversees
export async function getGatewayDetails(gateway_id:string) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
