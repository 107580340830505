import images from '../../images/images';

export const resourceTypeIcon = {
  vpc: images.deploymentEnvIcon(),
  node: images.deploymentEnvIcon(),
  deploymentenv: images.deploymentEnvIcon(),
  cluster: images.deploymentEnvIcon(),
  cloud: images.cloudSingleIcon(),
  gateway: images.EdgeGatewayIcon(),
  app: images.applicationIcon(),
  policy: images.policyIcon(),
  location: images.LocationIcon(),
  service: images.serviceIconSmall(),
  partition: images.partitionIcon(),
  securitygroup: images.partitionIcon(),
  namespace: images.partitionIcon(),
  networksegment: images.NetworkSegmentIcon(),
};

export const resourceTypeUrl = {
  vpc: 'deploymentEnvironmentDetails?deplId',
  node: 'deploymentEnvironmentDetails?deplId',
  deploymentenv: 'deploymentEnvironmentDetails?deplId',
  cluster: 'deploymentEnvironmentDetails?deplId',
  cloud: 'cloudDetails?cloudId',
  gateway: 'gatewayDetails?gatewayId',
  app: 'ApplicationDetails?appId',
  policy: 'connectionaccesspolicydetails?policyId',
  networksegment: 'networkSegmentDetails?nwSegId',
};

export const getResourceTypeIcon = (
  resourceType: keyof typeof resourceTypeIcon
) => {
  return resourceTypeIcon[resourceType] ?? '';
};

export const getResourceTypeUrl = (
  resourceUrl: keyof typeof resourceTypeUrl
) => {
  return resourceTypeUrl[resourceUrl] ?? '';
};
