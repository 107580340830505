import images from '../images/images';

export const DEFAULT_NETWORK_SEGMENT_ID = 'default-network-segment';

export const orderListIBM = {
  cloud: 'IBM',
  order: ['IKS', 'ROKS', 'IBMVPC', 'OCP', 'K8S', 'VM', 'BAREMETAL'],
};

export const orderListAWS = {
  cloud: 'AWS',
  order: ['EKS', 'AWSVPC', 'ROSA', 'OCP', 'K8S', 'VM', 'BAREMETAL'],
};

export const orderListAzure = {
  cloud: 'Azure',
  order: ['AKS', 'AZURE-OCP', 'AZURE-VNET', 'OCP', 'K8S', 'VM', 'BAREMETAL'],
};

export const orderListGoogle = {
  cloud: 'Google',
  order: ['GKE', 'K8S', 'VM', 'BAREMETAL'],
};

export const orderListOther = {
  cloud: 'other',
  order: ['OCP', 'K8S', 'VM', 'BAREMETAL'],
};
export const DEFAULT_INFRASTRUCTURE_ID = 'default-infra';
export const DEFAULT_APPLICATION_ID = 'default-app';
export const METRICS_TIMESTAMP_FORMAT = 'MMM DD, YYYY hh:mm:ss A';
export const METRICS_CHART_TIME_OFFSET = 300000; // 5 minutes
export const getDeploymentSubTypeIcon: any = {
  EKS: images.eksDepIcon(),
  AWSVPC: [images.awsCloudIcon(), images.vpcDepIcon()],
  ROSA: [images.awsCloudIcon(), images.rosaDepIcon()],
  ROKS: [images.ibmCloudIcon(), images.rosaDepIcon()],
  IKS: images.iksDepIcon(),
  IBMVPC: images.vpcDepIcon(),
  AKS: [images.microsoftCloudIcon(), images.azureAksIcon()],
  'AZURE-OCP': [images.microsoftCloudIcon(), images.rosaDepIcon()],
  'AZURE-VNET': [images.microsoftCloudIcon(), images.vpcDepIcon()],
  BAREMETAL: images.baremetalDepIcon(),
  VM: images.virtualMachineDepIcon(),
  K8S: images.aksDepIcon(),
  OCP: images.rosaDepIcon(),
  GKE:images.gkeIcon()
};

export const defaultNetworkSegmentObject: any = {
  resource_id: 'default-network-segment',
  name: 'Default_Network_Segment',
  description: 'The default network segment',
  labels: null,
  resource_group_id: 'default-infra',
  compatibility_set: 'AXON',
  flow_collector: false,
};

export const RHSICustomComputeProfile = {
  cpu_lim: '',
  cpu_req: '',
  mem_lim: '',
  mem_req: '',
  profile: 'custom',
};
