import { useEffect, useState } from 'react';
import {
  TextInput,
  Column,
  Row,
  Dropdown,
  ComboBox,
} from 'carbon-components-react';
import {
  Accordion,
  AccordionItem,
  RadioButton,
  RadioButtonGroup,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  StructuredListInput,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { NumberInput, ComboBox as ComboBoxCarbonReact } from '@carbon/react';

import {
  GatewayIngressTypes,
  SkupperComputeProfile,
} from '../../../models/master';
import { getRouterLogging, getRouterModes } from '../config';
import { IngressTypes, RHSIComputeProfiles } from '../../../lib/enums';
import { RHSICustomComputeProfile } from '../../../lib/constants';
import {
  useGatewayComputeProfiles,
  useGatewayIngressTypes,
} from '../../../hooks/useGateways';
import LabelTag from '../../../components/LabelTag/LabelTag';

import AddLabels from '../../../components/AddLabels/AddLabels';
import './ConfigureGateway.scss';
import {
  blockInvalidCustomRouterSizingChars,
  blockInvalidInputChars,
  blockInvalidNumberChar,
} from '../../../lib/utils';

const isNumber = (evt: any) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

const ConfigureGateway = (props: {
  onChange?: any;
  onBlur?: any;
  formData: any;
  children?: any;
  isFormValid: boolean;
  enableConfigureGateway: boolean;
  optionalParamInValid: boolean;
  open: boolean;
  skupperSiteConfig?: any;
  mode: string;
  partitionData?: any;
  connectedGatewayData?: any;
}) => {
  const {
    onChange,
    formData,
    onBlur,
    children,

    isFormValid,
    optionalParamInValid,
    open,
    skupperSiteConfig,
    mode,
    partitionData,
    connectedGatewayData,
    enableConfigureGateway,
  } = props;

  const { t } = useTranslation('namespaceRegister');

  const { data: gwIngressTypes } = useGatewayIngressTypes({ enabled: open });
  const { data: gwRhsiComputeProfiles } = useGatewayComputeProfiles({
    enabled: open,
  });
  const [routerLoggingItems] = useState(getRouterLogging(t));
  const [routerModes] = useState(getRouterModes(t));

  useEffect(() => {
    if (Array.isArray(gwRhsiComputeProfiles)) {
      let profile = gwRhsiComputeProfiles.find(
        cp => cp.profile === RHSIComputeProfiles.SMALL
      );
      if (formData.intended_compute_profile?.value) {
        if (
          formData.intended_compute_profile?.value !==
          RHSIComputeProfiles.CUSTOM
        )
          profile = gwRhsiComputeProfiles.find(
            cp => cp.profile === formData.intended_compute_profile.value
          );
        else {
          profile = {
            ...RHSICustomComputeProfile,
            cpu_lim:
              formData.skupper_site_configuration?.router_cpu_limit?.value ||
              '',
            cpu_req:
              formData.skupper_site_configuration?.router_cpu?.value || '',
            mem_lim:
              formData.skupper_site_configuration?.router_memory_limit?.value ||
              '',
            mem_req:
              formData.skupper_site_configuration?.router_memory?.value || '',
          };
        }
      }
      onChange('rhsi_compute_profile', profile ?? '');
    }
  }, [gwRhsiComputeProfiles, open, formData.intended_compute_profile?.value]);

  const onSizingChange = (event: any, gwSizing: any) => {
    if (formData?.rhsi_compute_profile?.value?.profile !== gwSizing?.profile) {
      onChange('rhsi_compute_profile', gwSizing);
    }
  };

  const onCustomProfileChange = (e: any, propType: string) => {
    onChange('skupper_site_configuration', e.target.value, propType);
  };

  const enableIngressFields =
    formData?.skupper_site_configuration.ingress_type?.value?.id &&
    formData.skupper_site_configuration.ingress_type.value?.id !==
      IngressTypes.NONE;

  const gatewaySizing = formData?.rhsi_compute_profile?.value;

  return (
    <>
      <CreateTearsheetStep
        className='gateway-details-form-tearsheet'
        hasFieldset={false}
        title={t('configureGateway.steptitle')}
        disableSubmit={() => {
          return !isFormValid || optionalParamInValid;
        }}
        includeStep={enableConfigureGateway}
      >
        {partitionData?.gateway_id && mode === 'EDIT' ? (
          <>
            <div className='configuration-settings-section router-sizing-section'>
              <div className='configuration-settngs-section-heading'>
                {t('skupperConfiguration.routerSizing.heading')}
              </div>
              <Row className='configuration-settings-row'>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerSizing.requestsCPU')}
                    </span>
                    <span className='value'>
                      {gatewaySizing?.profile ===
                      RHSICustomComputeProfile.profile
                        ? skupperSiteConfig?.router_cpu || '—'
                        : gatewaySizing?.cpu_req || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerSizing.requestsMemory')}
                    </span>
                    <span className='value'>
                      {gatewaySizing?.profile ===
                      RHSICustomComputeProfile.profile
                        ? skupperSiteConfig?.router_memory || '—'
                        : gatewaySizing?.mem_req || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerSizing.limitsCPU')}
                    </span>
                    <span className='value'>
                      {gatewaySizing?.profile ===
                      RHSICustomComputeProfile.profile
                        ? skupperSiteConfig?.router_cpu_limit || '—'
                        : gatewaySizing?.cpu_lim || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerSizing.limitsMemory')}
                    </span>
                    <span className='value'>
                      {gatewaySizing?.profile ===
                      RHSICustomComputeProfile.profile
                        ? skupperSiteConfig?.router_memory_limit || '—'
                        : gatewaySizing?.mem_lim || '—'}
                    </span>
                  </div>
                </Column>
              </Row>
            </div>
            <div className='configuration-settings-section ingress-settings-section'>
              <div className='configuration-settngs-section-heading'>
                {t('skupperConfiguration.ingressSettings.heading')}
              </div>
              <Row className='configuration-settings-row'>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.ingressSettings.ingress')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.ingress || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.ingressSettings.ingressHost')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.ingress_host || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.ingressSettings.routerIngressHost'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_ingress_host || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.ingressSettings.controllerIngressHostName'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.controller_ingress_host || '—'}
                    </span>
                  </div>
                </Column>
              </Row>
              <Row className='configuration-settings-row'>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.ingressSettings.ingressAnnotations'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.ingress_annotations || '—'}
                    </span>
                  </div>
                </Column>
              </Row>
            </div>
            <div className='configuration-settings-section'>
              <div className='configuration-settngs-section-heading'>
                {t('skupperConfiguration.routerConfig.heading')}
              </div>
              <Row className='configuration-settings-row'>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerConfig.siteName')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.site_name || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerConfig.routerMode.label')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_mode || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.routerConfig.routerLogging.label'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_logging || '—'}
                    </span>
                  </div>
                </Column>
              </Row>
              <Row className='configuration-settings-row'>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerConfig.annotations')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.annotations || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.routerConfig.routerServiceAnnotations'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_service_annotations || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.routerConfig.routerPodAnnotations'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_pod_annotations || '—'}
                    </span>
                  </div>
                </Column>
              </Row>
              <Row className='configuration-settings-row'>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerConfig.routers')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.routers || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={3} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.routerConfig.routerDataConnectionCount'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_data_connection_count || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerConfig.loadBalancer')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.router_load_balancer_ip || '—'}
                    </span>
                  </div>
                </Column>
                {/* <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.routerConfig.createNetworkPolicy.label'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.create_network_policy
                        ? t(
                            'skupperConfiguration.routerConfig.createNetworkPolicy.yes'
                          )
                        : t(
                            'skupperConfiguration.routerConfig.createNetworkPolicy.no'
                          )}
                    </span>
                  </div>
                </Column>
                <Column md={2} className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t(
                        'skupperConfiguration.routerConfig.enableServiceSync.label'
                      )}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.enable_service_sync
                        ? t(
                            'skupperConfiguration.routerConfig.enableServiceSync.yes'
                          )
                        : t(
                            'skupperConfiguration.routerConfig.enableServiceSync.no'
                          )}
                    </span>
                  </div>
                </Column> */}
              </Row>
              <Row className='configuration-settings-row'>
                <Column className='field-div-col'>
                  <div className='field-div'>
                    <span className='label'>
                      {t('skupperConfiguration.routerConfig.labels')}
                    </span>
                    <span className='value'>
                      {skupperSiteConfig?.labels ? (
                        <LabelTag
                          labelArray={skupperSiteConfig.labels
                            .split(',')
                            .map((label: any) => {
                              return {
                                default: false,
                                value: label,
                                type: 'teal',
                              };
                            })}
                          count={3}
                        ></LabelTag>
                      ) : (
                        '—'
                      )}
                    </span>
                  </div>
                </Column>
              </Row>
            </div>
          </>
        ) : (
          <>
            <Row className='inline-error-notification-row'>{children}</Row>
            <div className='gateway-details-form'>
              <div className='rhsi-edge-form'>
                <div className='gateway-configuration-section'>
                  <div className='heading'>
                    {t('configureGateway.configurationForm.heading')}
                  </div>
                  <div className='description'>
                    <span className='descriptionText'>
                      {t('configureGateway.configurationForm.description')}
                    </span>
                    <span className='link'>
                      <a
                        href={
                          navigator.language === 'en-US'
                            ? 'https://ibm.biz/mesh-reg-gateway'
                            : 'https://ibm.biz/mesh-reg-gateway-fr'
                        }
                        className='ingress-link'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('configureGateway.ingressForm.descriptionLink')}
                      </a>
                    </span>
                  </div>
                  <div className='gateway-sizing-section'>
                    <div className='gateway-sizing-heading'>
                      {t('configureGateway.gatewaySizing.title')}
                    </div>
                    <StructuredListWrapper
                      selection
                      className='gateway-sizing-list-wrapper'
                    >
                      <StructuredListHead>
                        <StructuredListRow head>
                          <StructuredListCell head> </StructuredListCell>
                          <StructuredListCell head>
                            {t('configureGateway.gatewaySizing.size')}
                          </StructuredListCell>
                          <StructuredListCell head>
                            {t('configureGateway.gatewaySizing.cpuLimits')}
                          </StructuredListCell>
                          <StructuredListCell head>
                            {t('configureGateway.gatewaySizing.memoryLimits')}
                          </StructuredListCell>
                          <StructuredListCell head>
                            {t('configureGateway.gatewaySizing.cpuRequests')}
                          </StructuredListCell>
                          <StructuredListCell head>
                            {t('configureGateway.gatewaySizing.memoryRequests')}
                          </StructuredListCell>
                        </StructuredListRow>
                      </StructuredListHead>
                      <StructuredListBody>
                        {Array.isArray(gwRhsiComputeProfiles)
                          ? gwRhsiComputeProfiles
                              ?.sort(
                                (
                                  p1: SkupperComputeProfile,
                                  p2: SkupperComputeProfile
                                ) => (p1?.profile > p2?.profile ? -1 : 1)
                              )
                              ?.map((gwSizing: any, index: number) => (
                                <StructuredListRow
                                  onClick={(event: any) =>
                                    onSizingChange(event, gwSizing)
                                  }
                                >
                                  <>
                                    <StructuredListInput className='list-selection-input' />
                                    <StructuredListCell className='list-selection-radio-btn'>
                                      <RadioButton
                                        onClick={(event: any) =>
                                          onSizingChange(event, gwSizing)
                                        }
                                        checked={
                                          formData?.rhsi_compute_profile?.value
                                            ?.profile === gwSizing?.profile
                                        }
                                      />
                                    </StructuredListCell>
                                  </>
                                  <StructuredListCell noWrap>
                                    {gwSizing?.profile
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      gwSizing?.profile?.slice(1)}
                                  </StructuredListCell>
                                  <StructuredListCell>
                                    {gwSizing?.cpu_lim}
                                  </StructuredListCell>
                                  <StructuredListCell>
                                    {gwSizing?.mem_lim}
                                  </StructuredListCell>
                                  <StructuredListCell>
                                    {gwSizing?.cpu_req}
                                  </StructuredListCell>
                                  <StructuredListCell>
                                    {gwSizing?.mem_req}
                                  </StructuredListCell>
                                </StructuredListRow>
                              ))
                          : null}
                        {/* custom compute profile */}
                        <StructuredListRow
                          onClick={(event: any) =>
                            onSizingChange(event, RHSICustomComputeProfile)
                          }
                        >
                          <>
                            <StructuredListInput className='list-selection-input' />
                            <StructuredListCell className='list-selection-radio-btn'>
                              <RadioButton
                                onClick={(event: any) =>
                                  onSizingChange(
                                    event,
                                    RHSICustomComputeProfile
                                  )
                                }
                                checked={
                                  formData?.rhsi_compute_profile?.value
                                    ?.profile ===
                                  RHSICustomComputeProfile?.profile
                                }
                              />
                            </StructuredListCell>
                          </>
                          <StructuredListCell noWrap>
                            {RHSICustomComputeProfile?.profile
                              ?.charAt(0)
                              .toUpperCase() +
                              RHSICustomComputeProfile?.profile?.slice(1)}
                          </StructuredListCell>
                          <StructuredListCell className='custom-sizing-cell'>
                            {formData?.rhsi_compute_profile?.value?.profile ===
                            RHSICustomComputeProfile?.profile ? (
                              <TextInput
                                light
                                id='custom-sizing-cpu-limit-input'
                                onKeyDown={blockInvalidCustomRouterSizingChars}
                                value={
                                  formData?.skupper_site_configuration
                                    ?.router_cpu_limit?.value
                                }
                                className='custom_compute_profile_input'
                                onChange={(e: any) =>
                                  onCustomProfileChange(e, 'router_cpu_limit')
                                }
                                labelText=''
                                size='md'
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_cpu_limit?.error
                                }
                                invalidText=''
                              />
                            ) : (
                              '—'
                            )}
                          </StructuredListCell>
                          <StructuredListCell className='custom-sizing-cell'>
                            {formData?.rhsi_compute_profile?.value?.profile ===
                            RHSICustomComputeProfile?.profile ? (
                              <TextInput
                                light
                                id='custom-sizing-memory-limit-input'
                                onKeyDown={blockInvalidCustomRouterSizingChars}
                                value={
                                  formData?.skupper_site_configuration
                                    ?.router_memory_limit?.value
                                }
                                className='custom_compute_profile_input'
                                onChange={(e: any) =>
                                  onCustomProfileChange(
                                    e,
                                    'router_memory_limit'
                                  )
                                }
                                labelText=''
                                size='md'
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_memory_limit?.error
                                }
                                invalidText=''
                              />
                            ) : (
                              '—'
                            )}
                          </StructuredListCell>
                          <StructuredListCell className='custom-sizing-cell'>
                            {formData?.rhsi_compute_profile?.value?.profile ===
                            RHSICustomComputeProfile?.profile ? (
                              <TextInput
                                light
                                id='custom-sizing-cpu-input'
                                onKeyDown={blockInvalidCustomRouterSizingChars}
                                value={
                                  formData?.skupper_site_configuration
                                    ?.router_cpu?.value
                                }
                                className='custom_compute_profile_input'
                                onChange={(e: any) =>
                                  onCustomProfileChange(e, 'router_cpu')
                                }
                                labelText=''
                                size='md'
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_cpu?.error
                                }
                                invalidText=''
                              />
                            ) : (
                              '—'
                            )}
                          </StructuredListCell>
                          <StructuredListCell className='custom-sizing-cell'>
                            {formData?.rhsi_compute_profile?.value?.profile ===
                            RHSICustomComputeProfile?.profile ? (
                              <TextInput
                                light
                                id='custom-sizing-memory-input'
                                onKeyDown={blockInvalidCustomRouterSizingChars}
                                value={
                                  formData?.skupper_site_configuration
                                    ?.router_memory?.value
                                }
                                className='custom_compute_profile_input'
                                onChange={(e: any) =>
                                  onCustomProfileChange(e, 'router_memory')
                                }
                                labelText=''
                                size='md'
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_memory?.error
                                }
                                invalidText=''
                              />
                            ) : (
                              '—'
                            )}
                          </StructuredListCell>
                        </StructuredListRow>
                      </StructuredListBody>
                    </StructuredListWrapper>
                    <div className='gateway-sizing-error-message'>
                      {formData?.skupper_site_configuration?.router_cpu_limit
                        ?.errorMessage ||
                        formData?.skupper_site_configuration
                          ?.router_memory_limit?.errorMessage ||
                        formData?.skupper_site_configuration?.router_cpu
                          ?.errorMessage ||
                        formData?.skupper_site_configuration?.router_memory
                          ?.errorMessage}
                    </div>
                  </div>

                  <div className='gateway-optional-settngs-section'>
                    <div className='optional-settngs-heading'>
                      {t('configureGateway.optionalSettings.heading')}
                    </div>
                    <Accordion
                      className='gateway-optional-settngs-accordion'
                      align='env'
                    >
                      <AccordionItem
                        title={t(
                          'configureGateway.optionalSettings.ingressSettings.heading'
                        )}
                      >
                        <div className='ingress-settings-details'>
                          <div className='ingress-form-description'>
                            <span className='descriptionText'>
                              {t('configureGateway.ingressForm.description')}
                            </span>
                          </div>
                          <Row className='row ingress-config-form'>
                            <Column md={4}>
                              <Dropdown
                                light
                                id='select-gateway-ingress-type'
                                items={gwIngressTypes || []}
                                selectedItem={
                                  gwIngressTypes?.find(
                                    (types: GatewayIngressTypes) =>
                                      types.id ===
                                      formData.skupper_site_configuration
                                        ?.ingress_type?.value?.id
                                  ) || ''
                                }
                                itemToString={(item: any) => item.label}
                                onChange={(e: any) => {
                                  onChange(
                                    'skupper_site_configuration',
                                    e.selectedItem,
                                    'ingress_type'
                                  );
                                }}
                                label={t('configureGateway.ingressType.label')}
                                titleText={t(
                                  'configureGateway.ingressType.titleText'
                                )}
                                invalid={gwIngressTypes?.length === 0}
                                invalidText={t('ingressTypeListEmptyError')}
                              />
                            </Column>

                            {enableIngressFields && (
                              <Column md={4}>
                                <TextInput
                                  id='gateway-ingress-hostname'
                                  light
                                  onChange={(e: any) =>
                                    onChange(
                                      'skupper_site_configuration',
                                      e.target.value,
                                      'ingress_hostname'
                                    )
                                  }
                                  value={
                                    formData.skupper_site_configuration
                                      ?.ingress_hostname?.value
                                  }
                                  size='md'
                                  labelText={t(
                                    'configureGateway.ingressHostname.label'
                                  )}
                                  placeholder={t(
                                    'configureGateway.ingressHostname.placeholder'
                                  )}
                                  onBlur={e =>
                                    onBlur(
                                      'skupper_site_configuration',
                                      e.target.value,
                                      'ingress_hostname'
                                    )
                                  }
                                  invalid={
                                    formData?.skupper_site_configuration
                                      ?.ingress_hostname?.error
                                  }
                                  invalidText={
                                    formData?.skupper_site_configuration
                                      ?.ingress_hostname?.errorMessage
                                  }
                                />
                              </Column>
                            )}
                          </Row>
                          {enableIngressFields && (
                            <>
                              <Row className='row'>
                                <>
                                  <Column
                                    md={4}
                                    id='router-ingress-hostname-col'
                                  >
                                    <TextInput
                                      id='router-ingress-hostname'
                                      light
                                      onChange={(e: any) =>
                                        onChange(
                                          'skupper_site_configuration',
                                          e.target.value,
                                          'router_ingress_hostname'
                                        )
                                      }
                                      value={
                                        formData.skupper_site_configuration
                                          ?.router_ingress_hostname?.value
                                      }
                                      size='md'
                                      labelText={t(
                                        'configureGateway.routerIngressHostname.label'
                                      )}
                                      placeholder={t(
                                        'configureGateway.routerIngressHostname.placeholder'
                                      )}
                                      onBlur={e =>
                                        onBlur(
                                          'skupper_site_configuration',
                                          e.target.value,
                                          'router_ingress_hostname'
                                        )
                                      }
                                      invalid={
                                        formData?.skupper_site_configuration
                                          ?.router_ingress_hostname?.error
                                      }
                                      invalidText={
                                        formData?.skupper_site_configuration
                                          ?.router_ingress_hostname
                                          ?.errorMessage
                                      }
                                    />
                                  </Column>
                                  <Column
                                    md={4}
                                    id='controller-ingress-hostname-col'
                                  >
                                    <TextInput
                                      id='controller-ingress-hostname'
                                      light
                                      onChange={(e: any) =>
                                        onChange(
                                          'skupper_site_configuration',
                                          e.target.value,
                                          'controller_ingress_hostname'
                                        )
                                      }
                                      value={
                                        formData.skupper_site_configuration
                                          ?.controller_ingress_hostname?.value
                                      }
                                      size='md'
                                      labelText={t(
                                        'configureGateway.controllerIngressHostname.label'
                                      )}
                                      placeholder={t(
                                        'configureGateway.controllerIngressHostname.placeholder'
                                      )}
                                      onBlur={e =>
                                        onBlur(
                                          'skupper_site_configuration',
                                          e.target.value,
                                          'controller_ingress_hostname'
                                        )
                                      }
                                      invalid={
                                        formData?.skupper_site_configuration
                                          ?.controller_ingress_hostname?.error
                                      }
                                      invalidText={
                                        formData?.skupper_site_configuration
                                          ?.controller_ingress_hostname
                                          ?.errorMessage
                                      }
                                    />
                                  </Column>
                                </>
                              </Row>
                              <Row className='row'>
                                <Column md={8} id='ingress-annotations-col'>
                                  <TextInput
                                    id='ingress-annotations'
                                    light
                                    onChange={(e: any) =>
                                      onChange(
                                        'skupper_site_configuration',
                                        e.target.value,
                                        'ingress_annotations'
                                      )
                                    }
                                    value={
                                      formData.skupper_site_configuration
                                        ?.ingress_annotations?.value
                                    }
                                    size='md'
                                    labelText={t(
                                      'configureGateway.ingressAnnotations.label'
                                    )}
                                    placeholder={t(
                                      'configureGateway.ingressAnnotations.placeholder'
                                    )}
                                    onBlur={e =>
                                      onBlur(
                                        'skupper_site_configuration',
                                        e.target.value,
                                        'ingress_annotations'
                                      )
                                    }
                                    invalid={
                                      formData?.skupper_site_configuration
                                        ?.ingress_annotations?.error
                                    }
                                    invalidText={
                                      formData?.skupper_site_configuration
                                        ?.ingress_annotations?.errorMessage
                                    }
                                  />
                                </Column>
                              </Row>
                            </>
                          )}
                        </div>
                      </AccordionItem>
                      <AccordionItem
                        title={t(
                          'configureGateway.optionalSettings.routerConfiguration.heading'
                        )}
                      >
                        <div className='router-configuration-details'>
                          <Row className='row'>
                            <Column md={4}>
                              <TextInput
                                id='router-config-site-name'
                                light
                                onChange={(e: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'site_name'
                                  )
                                }
                                value={
                                  formData.skupper_site_configuration?.site_name
                                    ?.value
                                }
                                size='md'
                                labelText={t('configureGateway.siteName.label')}
                                placeholder={t(
                                  'configureGateway.siteName.placeholder'
                                )}
                                helperText={t(
                                  'configureGateway.siteName.helpText'
                                )}
                                onBlur={e =>
                                  onBlur(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'site_name'
                                  )
                                }
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.site_name?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration
                                    ?.site_name?.errorMessage
                                }
                              />
                            </Column>
                            <Column md={4} className='router-mode-combobox'>
                              <ComboBoxCarbonReact
                                light
                                id='router-config-mode'
                                items={routerModes}
                                selectedItem={routerModes.find(
                                  mode =>
                                    mode.id ===
                                    formData.skupper_site_configuration
                                      ?.router_mode?.value?.id
                                )}
                                itemToString={(item: any) => item?.label}
                                titleText={t(
                                  'configureGateway.routerMode.label'
                                )}
                                readOnly
                              />
                            </Column>
                          </Row>
                          <Row className='row'>
                            <Column md={4}>
                              <ComboBox
                                light
                                id='router-config-logging'
                                name='routerConfigLogging'
                                items={routerLoggingItems}
                                initialSelectedItem={routerLoggingItems?.[2]}
                                selectedItem={
                                  routerLoggingItems.find(
                                    logging =>
                                      logging.id ===
                                      formData.skupper_site_configuration
                                        ?.router_logging?.value?.id
                                  ) || null
                                }
                                itemToString={(item: any) => item?.label}
                                translateWithId={t}
                                onChange={(e: any) => {
                                  onChange(
                                    'skupper_site_configuration',
                                    e.selectedItem,
                                    'router_logging'
                                  );
                                }}
                                titleText={t(
                                  'configureGateway.routerLogging.label'
                                )}
                              />
                            </Column>

                            <Column md={4}>
                              <TextInput
                                id='router-annotations'
                                light
                                onChange={(e: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'annotations'
                                  )
                                }
                                value={
                                  formData.skupper_site_configuration
                                    ?.annotations?.value
                                }
                                size='md'
                                labelText={t(
                                  'configureGateway.annotations.label'
                                )}
                                placeholder={t(
                                  'configureGateway.annotations.placeholder'
                                )}
                                onBlur={e =>
                                  onBlur(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'annotations'
                                  )
                                }
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.annotations?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration
                                    ?.annotations?.errorMessage
                                }
                              />
                            </Column>
                          </Row>
                          <Row className='row'>
                            <Column md={4}>
                              <TextInput
                                id='router-service-annotations'
                                light
                                onChange={(e: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'router_service_annotations'
                                  )
                                }
                                value={
                                  formData.skupper_site_configuration
                                    ?.router_service_annotations?.value
                                }
                                size='md'
                                labelText={t(
                                  'configureGateway.routerServiceAnnotations.label'
                                )}
                                placeholder={t(
                                  'configureGateway.routerServiceAnnotations.placeholder'
                                )}
                                onBlur={e =>
                                  onBlur(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'router_service_annotations'
                                  )
                                }
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_service_annotations?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration
                                    ?.router_service_annotations?.errorMessage
                                }
                              />
                            </Column>

                            <Column md={4}>
                              <TextInput
                                id='router-pod-annotations'
                                light
                                onChange={(e: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'router_pod_annotations'
                                  )
                                }
                                value={
                                  formData.skupper_site_configuration
                                    ?.router_pod_annotations?.value
                                }
                                size='md'
                                labelText={t(
                                  'configureGateway.routerPodAnnotations.label'
                                )}
                                placeholder={t(
                                  'configureGateway.routerPodAnnotations.placeholder'
                                )}
                                onBlur={e =>
                                  onBlur(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'router_pod_annotations'
                                  )
                                }
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_pod_annotations?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration
                                    ?.router_pod_annotations?.errorMessage
                                }
                              />
                            </Column>
                          </Row>
                          <Row className='row'>
                            <Column md={4}>
                              <NumberInput
                                id='routers-count'
                                light
                                min={0}
                                allowEmpty
                                hideSteppers
                                onChange={(e: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'routers'
                                  )
                                }
                                onKeyDown={blockInvalidNumberChar}
                                value={
                                  formData.skupper_site_configuration?.routers
                                    ?.value ?? ''
                                }
                                size='md'
                                onBlur={(e: any) =>
                                  onBlur(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'routers'
                                  )
                                }
                                invalid={
                                  formData?.skupper_site_configuration?.routers
                                    ?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration?.routers
                                    ?.errorMessage
                                }
                                label={t('configureGateway.routers.label')}
                                placeholder={t(
                                  'configureGateway.routers.placeholder'
                                )}
                              />
                            </Column>

                            <Column md={4}>
                              <NumberInput
                                id='router-data-connection-count'
                                light
                                min={0}
                                allowEmpty
                                hideSteppers
                                onKeyDown={blockInvalidNumberChar}
                                onChange={(e: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'router_data_connection_count'
                                  )
                                }
                                value={
                                  formData.skupper_site_configuration
                                    ?.router_data_connection_count?.value ?? ''
                                }
                                size='md'
                                onBlur={(e: any) =>
                                  onBlur(
                                    'skupper_site_configuration',
                                    e.target.value,
                                    'router_data_connection_count'
                                  )
                                }
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_data_connection_count?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration
                                    ?.router_data_connection_count?.errorMessage
                                }
                                label={t(
                                  'configureGateway.routerDataConnectionCount.label'
                                )}
                                placeholder={t(
                                  'configureGateway.routerDataConnectionCount.placeholder'
                                )}
                              />
                            </Column>
                          </Row>
                          <Row className='row'>
                            <Column md={4}>
                              <TextInput
                                id='load-balancer'
                                light
                                onChange={(e: any) => {
                                  if (isNumber(e))
                                    onChange(
                                      'skupper_site_configuration',
                                      e.target.value,
                                      'router_load_balancer_ip'
                                    );
                                }}
                                value={
                                  formData.skupper_site_configuration
                                    ?.router_load_balancer_ip?.value
                                }
                                size='md'
                                labelText={t(
                                  'configureGateway.loadBalancer.label'
                                )}
                                placeholder={t(
                                  'configureGateway.loadBalancer.placeholder'
                                )}
                                onBlur={e => {
                                  if (isNumber(e))
                                    onBlur(
                                      'skupper_site_configuration',
                                      e.target.value,
                                      'router_load_balancer_ip'
                                    );
                                }}
                                invalid={
                                  formData?.skupper_site_configuration
                                    ?.router_load_balancer_ip?.error
                                }
                                invalidText={
                                  formData?.skupper_site_configuration
                                    ?.router_load_balancer_ip?.errorMessage
                                }
                              />
                            </Column>

                            <Column md={2}>
                              <RadioButtonGroup
                                legendText={t(
                                  'configureGateway.createNetworkPolicy.label'
                                )}
                                name='router-create-network-policy'
                                valueSelected={
                                  formData?.skupper_site_configuration
                                    ?.create_network_policy?.value
                                }
                                readOnly
                              >
                                <RadioButton
                                  labelText={t(
                                    'configureGateway.createNetworkPolicy.yes'
                                  )}
                                  value={true}
                                  id='create-network-policy-yes'
                                />
                                <RadioButton
                                  labelText={t(
                                    'configureGateway.createNetworkPolicy.no'
                                  )}
                                  value={false}
                                  id='create-network-policy-no'
                                />
                              </RadioButtonGroup>
                            </Column>
                          </Row>
                          <Row className='row'>
                            <Column md={8}>
                              <AddLabels
                                id='router-config-label'
                                labelText={t(
                                  'connectGateway.gatewayLabels.label'
                                )}
                                placeholder={t(
                                  'connectGateway.gatewayLabels.placeholder'
                                )}
                                onChange={(data: any) =>
                                  onChange(
                                    'skupper_site_configuration',
                                    data,
                                    'labels'
                                  )
                                }
                                btnText={t(
                                  'connectGateway.gatewayLabels.btnText'
                                )}
                                btnKind='secondary'
                                tagType='green'
                                defaultValues={
                                  formData.skupper_site_configuration?.labels
                                    ?.value
                                }
                                onKeyDown={blockInvalidInputChars}
                              />
                            </Column>
                          </Row>
                        </div>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </CreateTearsheetStep>
    </>
  );
};
export default ConfigureGateway;
