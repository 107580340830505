import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import InlineNotification from '../../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../../../lib/utils';
import { AxiosError } from 'axios';
import {
  Button,
  Column,
  Dropdown,
  NumberInput,
  Row,
  TooltipIcon,
} from 'carbon-components-react';
import {
  AppliedFilter,
  DeploymentEnvironment,
  Gateway,
  Location,
  Namespace,
  NetworkSegment,
  NetworkSegments,
  RemoteConnections,
} from '../../../../models/master';
import { getNetworkSegments } from '../../../../controllers/networksegmentsApi';
import WideTearsheet from '../../../../components/WideTearsheet/WideTearsheet';
import GatewaysTable from './GatewaysTable';
import sortData from '../../../../lib/tableSort';
import {
  HealthStatusType,
  getHealthStatusConfig,
} from '../../../../components/GatewayStatus/config';
import {
  addGatewayRemoteConnection,
  getGateways,
} from '../../../../controllers/gateawayApis';
import {
  getDeploymentEnvs,
  getNamespace,
  getPartition,
} from '../../../../controllers/deploymentEnv';
import { getLocationDetails } from '../../../../controllers/locationApis';
import images from '../../../../images/images';
import { Add16 } from '@carbon/icons-react';
import GenericTruncateString from '../../../../components/GenericTruncateString/GenericTruncateString';
import {
  GatewayLifeCycleState,
  RemoteConnectionDirection,
  VisibilityFlags,
} from '../../../../lib/enums';

import './RegisterRemoteConnection.scss';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../../../lib/constants';

export interface GatewayCustomData extends Gateway {
  location_name: string;
  cloud_name?: string;
  network_segment_name?: string;
  networkSegmentPermission: boolean;
  radioButton?: JSX.Element;
  locationPermission: boolean;
  namespaceName: string;
}

const defaultFormValue = {
  cost: {
    value: 1,
    error: false,
    errorMessage: '',
  },
  remoteGatewayId: {
    value: '',
    error: false,
    errorMessage: '',
  },
  remoteGatewayName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  creationDirection: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const defaultPermissionMap = {
  gateways: true,
  location: true,
  networkSegment: true,
};

const requiredFields = ['cost', 'remoteGatewayId', 'creationDirection'];

interface Props {
  open: boolean;
  onClose: () => void;
  onRegister: () => void;
  gatewayData: Gateway | null;
  currentNetworkSegment: {
    resource_id: string;
    name: string;
  } | null;
  remoteConnectionsList: RemoteConnections[] | null;
}

const RegisterRemoteConnection: React.FC<Props> = ({
  open,
  onClose,
  onRegister,
  gatewayData,
  currentNetworkSegment,
  remoteConnectionsList,
}) => {
  const { t } = useTranslation('registerRemoteConnection');

  const notification = useContext(NotificationContext);

  const [formData, setFormData] = useState({ ...defaultFormValue });
  const [gateWaysData, setGateWaysData] = useState<GatewayCustomData[] | null>(
    null
  );
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegments[] | null
  >(null);

  const [authError, setAuthError] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');
  const [registerRemoteConnectionLoader, setRegisterRemoteConnectionLoader] =
    useState(false);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState<GatewayCustomData[] | []>(
    []
  );
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [selectGatewayTearsheet, setSelectGatewayTearsheet] = useState(false);
  const [createdDir, setCreatedDir] = useState('');
  const [remoteGwSelected, setRemoteGwSelected] = useState({
    id: '',
    name: '',
  });
  const [deploymentEnvsList, setDeployomentEnvList] = useState<
    DeploymentEnvironment[] | null
  >(null);

  useEffect(() => {
    if (open) {
      if (currentNetworkSegment) {
        refreshData();
      } else {
        setGateWaysData([]);
      }
    } else {
      setFormData({ ...defaultFormValue });
      setRegisterErrorMessage('');
      setAuthError(false);
      setRegisterRemoteConnectionLoader(false);
    }
  }, [open]);

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const isSelectGatewayFormValid = () => {
    const field = 'remoteGatewayId';
    const value = (formData as any)[field].value;
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    if (checkFieldValidation(field, trimmedValue)) {
      return false;
    }
    return true;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'cost':
        errorMessage =
          value === ''
            ? t('validation.cost.required')
            : value < 0
            ? t('validation.cost.min')
            : '';
        break;
      case 'remoteGatewayId':
        errorMessage = !value ? t('validation.remoteGatewayId.required') : '';
        break;
      case 'creationDirection':
        errorMessage = !value ? t('validation.creationDirection.required') : '';
        break;
    }
    return errorMessage;
  };

  const handleRemoteGatewaySelected = (data: GatewayCustomData) => {
    setFormData(prevState => ({
      ...prevState,
      remoteGatewayId: {
        value: data.resource_id,
        error: false,
        errorMessage: '',
      },
      remoteGatewayName: {
        value: data.name,
        error: false,
        errorMessage: '',
      },
    }));
  };

  const handleOnChange = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);

    setFormData(prevState => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setRegisterRemoteConnectionLoader(true);

      const gatewayRemoteConnnection = addGatewayRemoteConnection(
        createdDir === RemoteConnectionDirection.outbound
          ? gatewayData?.resource_id
          : remoteGwSelected?.id,
        {
          cost: formData.cost.value,
          name:
            createdDir === RemoteConnectionDirection.outbound
              ? removeExtraSpace(
                  `${gatewayData?.name}-${formData.remoteGatewayName.value}`
                )
              : removeExtraSpace(
                  `${formData.remoteGatewayName.value}-${gatewayData?.name}`
                ),
          remote_gw_id:
            createdDir === RemoteConnectionDirection.outbound
              ? remoteGwSelected?.id
              : gatewayData?.resource_id,
          gateway_id:
            createdDir === RemoteConnectionDirection.outbound
              ? gatewayData?.resource_id
              : remoteGwSelected?.id,
        }
      );

      await Promise.all([gatewayRemoteConnnection]);

      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          gateway: gatewayData?.name,
          remoteGateway: formData.remoteGatewayName.value,
        }),
      });

      setRemoteGwSelected({
        id: '',
        name: '',
      });

      onClose();
      onRegister();
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setAuthError(true);
      }

      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setRegisterErrorMessage(errorMessage);
    } finally {
      setRegisterRemoteConnectionLoader(false);
    }
  };

  const handleErrorBarClose = () => {
    setRegisterErrorMessage('');
    setAuthError(false);
  };

  const cancelForm = () => {
    setRemoteGwSelected({
      id: '',
      name: '',
    });
    onClose();
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getCloudFilters = (gatewaysData: GatewayCustomData[] | null) => [
    {
      key: 'health_status',
      label: t('filters.health'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'single',
      translationReqd: true,
      translationData: {
        operational: t('operational'),
        critical: t('critical'),
        major: t('major'),
        minor: t('minor'),
        pending: t('pending'),
        deploying: t('deploying'),
        provisioning: t('provisioning'),
        terminating: t('terminating'),
        redeploying: t('redeploying'),
        notdeployed: t('notdeployed'),
      },
      hasIcon: true,
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.health_status && gateway?.health_status !== undefined
              )
              .map(gateway => gateway.health_status)
          )
        ).map(status => {
          const healthConfig = getHealthStatusConfig(
            t,
            status as HealthStatusType
          );
          return {
            icon: healthConfig.icon,
            label: status,
          };
        }),
      ],
    },
    {
      key: 'location_name',
      label: t('filters.location'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.location_name && gateway?.location_name !== undefined
              )
              .map(gateway => gateway?.location_name)
          )
        ),
      ],
    },
    {
      key: 'intended_version',
      label: t('filters.version'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.intended_version &&
                  gateway?.intended_version !== undefined
              )
              .map(gateway => gateway?.intended_version)
          )
        ),
      ],
    },
  ];

  const getNamespaceFromCluster = async (gateway: Gateway) => {
    try {
      if (Array.isArray(deploymentEnvsList)) {
        const clusters = deploymentEnvsList.filter(
          deplenv => deplenv.type === 'cluster'
        );

        for (const cluster of clusters) {
          try {
            const namespaceList = await getNamespace(
              cluster.cloud_id,
              cluster.resource_id
            );

            if (Array.isArray(namespaceList?.namespaces)) {
              const namespace = namespaceList?.namespaces.find(
                (namespace: Namespace) =>
                  namespace.gateway_id === gateway.resource_id
              );

              if (namespace) {
                return namespace;
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNetworkSegmentNamespace = async (
    gateway: Gateway,
    networkSegments: NetworkSegment[]
  ) => {
    let networkSegmentId = DEFAULT_NETWORK_SEGMENT_ID;
    let namespace;
    if (
      gateway?.subtype &&
      gateway?.subtype === 'RHSI-EDGE' &&
      gateway?.deployed_in_depl_env_id &&
      gateway?.deployed_in_partition_id
    ) {
      namespace = await getPartition(
        gateway?.deployed_in_depl_env_id,
        gateway?.deployed_in_partition_id
      );
      networkSegmentId = namespace?.network_segment_id ?? '';
    } else {
      namespace = await getNamespaceFromCluster(gateway);
      networkSegmentId = namespace?.network_segment_id ?? '';
    }

    const networkSegment =
      networkSegmentId === DEFAULT_NETWORK_SEGMENT_ID
        ? defaultNetworkSegmentObject
        : networkSegments.find(
            nwSegment => nwSegment?.resource_id === networkSegmentId
          );

    return {
      namespace,
      networkSegment,
    };
  };

  const refreshData = async () => {
    try {
      setGateWaysData(null);
      setSortKey('');
      setSortDirection('NONE');

      let currentPermissionMap = permissionMap;

      let networkSegmentsList: NetworkSegment[];
      let gateWaysData: GatewayCustomData[] = [];

      try {
        const deploymentenvironments: DeploymentEnvironment[] =
          await getDeploymentEnvs(VisibilityFlags.MANAGED, true);
        setDeployomentEnvList(deploymentenvironments);
      } catch (error) {
        console.error(error);
      }

      try {
        networkSegmentsList = await getNetworkSegments();
        setNetworkSegments(networkSegments);

        if (!currentPermissionMap.networkSegment) {
          currentPermissionMap = {
            ...currentPermissionMap,
            networkSegment: true,
          };
          setPermissionMap(currentPermissionMap);
        }
      } catch (error) {
        console.error(error);
        const err = error as AxiosError;
        if (err?.response?.status === 403) {
          currentPermissionMap = {
            ...currentPermissionMap,
            networkSegment: false,
          };
          setPermissionMap(currentPermissionMap);
        }
      }

      const remoteConnectionsIds = Array.isArray(remoteConnectionsList)
        ? remoteConnectionsList.map(remoteConnection =>
            remoteConnection.remote_gw_id === gatewayData?.resource_id
              ? remoteConnection?.gateway_id
              : remoteConnection.remote_gw_id
          )
        : [];

      const gateways = await getGateways();

      await Promise.allSettled(
        gateways.map(async (gateway: Gateway) => {
          if (
            !gateway?.unmanaged &&
            gateway?.lifecycle_state === GatewayLifeCycleState.GW_DEPLOYED
          ) {
            if (
              gateway?.subtype === 'RHSI-EDGE' &&
              gateway.resource_id !== gatewayData?.resource_id &&
              gateway?.terminate === false &&
              !remoteConnectionsIds.includes(gateway.resource_id)
            ) {
              let networkSegment: NetworkSegment | undefined;
              let location: Location | undefined;
              let namespace: Namespace | undefined;

              try {
                const data = await getNetworkSegmentNamespace(
                  gateway,
                  networkSegmentsList
                );

                networkSegment = data?.networkSegment;
                namespace = data?.namespace;
              } catch (error) {
                console.log(error);
              }

              if (
                networkSegment &&
                networkSegment.resource_id ===
                  currentNetworkSegment?.resource_id
              ) {
                if (!currentPermissionMap.location) {
                  currentPermissionMap = {
                    ...currentPermissionMap,
                    location: true,
                  };
                  setPermissionMap(currentPermissionMap);
                }

                if (gateway?.cloud_id && gateway?.location_id) {
                  try {
                    location = await getLocationDetails(
                      gateway?.cloud_id,
                      gateway?.location_id,
                      'all',
                      true
                    );
                  } catch (error) {
                    const err = error as AxiosError;
                    if (err?.response?.status === 403) {
                      currentPermissionMap = {
                        ...currentPermissionMap,
                        location: false,
                      };

                      setPermissionMap(currentPermissionMap);
                    }
                  }
                }

                gateWaysData.push({
                  ...gateway,
                  locationPermission: currentPermissionMap['location'],
                  networkSegmentPermission:
                    currentPermissionMap['networkSegment'],
                  location_name: location?.name ?? '',
                  network_segment_name: networkSegment?.name,
                  network_segment_id: networkSegment?.resource_id,
                  namespaceName: namespace?.name ?? '',
                });
              }
            }
          }
        })
      );

      setGateWaysData(gateWaysData);

      if (!permissionMap.gateways) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          gateways: true,
        }));
      }
    } catch (error) {
      setGateWaysData([]);
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          gateways: false,
        }));
      }
    }
  };

  const handleGWSelCancel = () => {
    setFormData(prevState => ({
      ...prevState,
      remoteGatewayId: {
        value: !remoteGwSelected.id ? '' : remoteGwSelected.id,
        error: false,
        errorMessage: '',
      },
      remoteGatewayName: {
        value: !remoteGwSelected.id ? '' : remoteGwSelected.name,
        error: false,
        errorMessage: '',
      },
    }));
    setSelectGatewayTearsheet(false);
  };

  const handleGWSel = () => {
    setRemoteGwSelected({
      id: formData?.remoteGatewayId?.value,
      name: formData?.remoteGatewayName?.value,
    });
    setSelectGatewayTearsheet(false);
  };

  return (
    <div className='register-remote-connection'>
      <WideTearsheet
        className='register-remote-conn-tearsheet'
        title={t('title')}
        description={t('description', {
          name: gatewayData?.subtype ? t(gatewayData.subtype) : '',
        })}
        open={open}
        actions={[
          {
            kind: 'primary',
            label: t('submitButtonText'),
            onClick: () => {
              handleSubmit();
            },
            disabled: !isFormValid(),
            loading: registerRemoteConnectionLoader,
          },
          {
            kind: 'secondary',
            label: t('cancelButtonText'),
            onClick: () => {
              setFormData({ ...defaultFormValue });
              cancelForm();
              setAuthError(false);
            },
          },
        ]}
      >
        {(registerErrorMessage || authError) && (
          <InlineNotification
            onClose={() => handleErrorBarClose() as any}
            kind={'error'}
            title={
              authError
                ? (t('failureNotification.authTitle') as string)
                : (t('failureNotification.title') as string)
            }
            subtitle={
              authError
                ? (t('failureNotification.authSubtitle') as string)
                : registerErrorMessage
                ? registerErrorMessage
                : (t('failureNotification.subtitle') as string)
            }
          />
        )}
        <div className='select-direction-and-link-metric'>
          <div className='select-direction-and-gateway-section'>
            <div className='select-direction-and-gateway-section-title'>
              {t('selectDirectionAndGatewaySectionTitle')}
            </div>
            <div className='selection-header'>
              <div className='starting-gateway'>
                <Row>
                  <Column>
                    <div> {t('startingGateway')}</div>
                    <div className='selected-gateway-name'>
                      {gatewayData?.name ? (
                        <GenericTruncateString
                          str={gatewayData?.name}
                          limit={9}
                        ></GenericTruncateString>
                      ) : (
                        '—'
                      )}
                    </div>
                  </Column>
                  <Column className='gateway-img'>
                    {images.noGatewaySmallIcon()}
                  </Column>
                </Row>
              </div>

              <div className='creation-direction'>
                <div className='direction-dropdown-title'>
                  {t('creationDirection')}
                </div>
                <Dropdown
                  className='direction-dropdown'
                  label={t('chooseDirection')}
                  id='creation-direction'
                  items={[
                    {
                      text: t('outbound'),
                      element: (
                        <div className='dir-dropdown'>
                          <div className='direction-arrow'>
                            {images.rightArrowSvg()}
                          </div>
                          <div className='dropdown-text'>{t('outbound')}</div>
                        </div>
                      ),
                    },
                    {
                      text: t('inbound'),
                      element: (
                        <div className='dir-dropdown'>
                          <div className='direction-arrow'>
                            {images.leftArrowSvg()}
                          </div>
                          <div className='dropdown-text'>{t('inbound')}</div>
                        </div>
                      ),
                    },
                  ]}
                  itemToString={(item: any) => item?.text}
                  itemToElement={(item: any) => item.element}
                  onChange={(e: any) => {
                    setFormData(prevState => ({
                      ...prevState,
                      creationDirection: {
                        value: e.selectedItem,
                        error: false,
                        errorMessage: '',
                      },
                    }));
                    setCreatedDir(e.selectedItem.text);
                  }}
                  selectedItem={formData?.creationDirection?.value}
                  renderSelectedItem={(item: any) => item.element}
                />
              </div>

              <div className='remote-gateway'>
                <Row>
                  <Column>
                    <div>{t('remoteGateway')}</div>
                    {!remoteGwSelected?.id ? (
                      <Button
                        className='select-gateway-button'
                        kind='ghost'
                        renderIcon={Add16}
                        onClick={() => setSelectGatewayTearsheet(true)}
                      >
                        {`${t('selectGateway')}`}
                      </Button>
                    ) : (
                      <div className='select-gateway-value'>
                        <div>
                          {remoteGwSelected?.name ? (
                            <GenericTruncateString
                              str={remoteGwSelected?.name}
                              limit={7}
                              maxLength={15}
                            ></GenericTruncateString>
                          ) : (
                            '—'
                          )}
                        </div>
                        <div className='gw-edit-button'>
                          <></>
                          <Button
                            className='gw-edit'
                            onClick={() => setSelectGatewayTearsheet(true)}
                            kind='ghost'
                          >
                            {images.editIconSvg()}
                          </Button>
                        </div>
                      </div>
                    )}
                  </Column>
                  <Column className='gateway-img'>
                    {images.noGatewaySmallIcon()}
                  </Column>
                </Row>
              </div>
            </div>
          </div>

          <div className='cost-field-title'>
            <div className='cost-field-header'>{t('labels.cost')}</div>
            <TooltipIcon
              tooltipText={t('linkMetricTooltip')}
              direction={'right'}
            >
              <div className='cost-field-header-icon'>
                {images.infoQuestionIconSvg()}
              </div>
            </TooltipIcon>
          </div>
          <NumberInput
            id='cost-field'
            className='cost-field'
            min={0}
            value={formData.cost.value}
            onChange={(e: any, { value }: any) => {
              if (value !== undefined) {
                handleOnChange('cost', value === '' ? value : Number(value));
              } else {
                // If we use plus and minus button, then the value field is returned as undefined.
                // So we need to manually plus and minus it.
                const currentValue =
                  typeof formData?.cost?.value === 'number'
                    ? formData?.cost?.value
                    : 0;
                if (e.target.classList?.contains('up-icon')) {
                  handleOnChange('cost', currentValue + 1);
                } else {
                  handleOnChange('cost', currentValue - 1);
                }
              }
            }}
            onClick={function noRefCheck() {}}
            invalidText={formData.cost.errorMessage}
            step={1}
          />
        </div>
      </WideTearsheet>
      <WideTearsheet
        className='select-gateway-tearsheet'
        title={t('subTitle')}
        description={t('description')}
        open={selectGatewayTearsheet}
        actions={[
          {
            kind: 'primary',
            label: t('selectGateway'),
            onClick: () => {
              handleGWSel();
            },
            disabled: !isSelectGatewayFormValid(),
          },
          {
            kind: 'secondary',
            label: t('cancelButtonText'),
            onClick: () => {
              handleGWSelCancel();
            },
          },
        ]}
      >
        <div className='curr-gateway-info'>
          <div className='gateway-info'>
            <Row>
              <Column>
                <div className='title'> {t('startingGateway')}</div>
                <div className='select-value'>
                  <div className='curr-gateway-icon'>
                    {images.EdgeGatewayIcon()}
                  </div>
                  <div>
                    {gatewayData?.name ? (
                      <GenericTruncateString
                        str={gatewayData?.name}
                        limit={10}
                        maxLength={25}
                      ></GenericTruncateString>
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
              </Column>
            </Row>
          </div>
          <div className='network-segment-info'>
            <Row>
              <Column>
                <div className='title'> {t('networkSegment')}</div>
                <div className='select-value'>
                  {currentNetworkSegment?.name ? (
                    <GenericTruncateString
                      str={currentNetworkSegment?.name}
                      limit={10}
                      maxLength={25}
                    ></GenericTruncateString>
                  ) : (
                    '—'
                  )}
                </div>
              </Column>
            </Row>
          </div>
          <div className='deployment-env-info'>
            <Row>
              <Column>
                <div className='title'> {t('deploymentEnv')}</div>
                <div className='select-value'>
                  {
                    deploymentEnvsList?.find(
                      depEnv =>
                        depEnv.resource_id ===
                        gatewayData?.deployed_in_depl_env_id
                    )?.name
                  }
                </div>
              </Column>
            </Row>
          </div>
        </div>
        <div className='gateway-table'>
          <GatewaysTable
            currentPageNumber={currentPageNumber}
            currentPageSize={currentPageSize}
            onPageChange={pageData => setPageChange(pageData)}
            sortRows={(
              data: { id: string; text: string },
              direction: 'ASC' | 'DESC' | 'NONE'
            ) => handleTableSort(data, direction)}
            rows={
              Array.isArray(gateWaysData)
                ? filterApplied?.length > 0
                  ? sortData(filteredData, sortKey, sortDirection)
                  : sortData(gateWaysData, sortKey, sortDirection)
                : null
            }
            elementCount={
              gateWaysData
                ? filterApplied?.length > 0
                  ? filteredData?.length
                  : gateWaysData?.length
                : 0
            }
            filteredDataSet={
              gateWaysData
                ? filterApplied?.length > 0
                  ? filteredData
                  : gateWaysData
                : null
            }
            filteredDataCallback={data => {
              data && setFilteredData(data as GatewayCustomData[] | []);
              setPageNumber(1);
            }}
            data={gateWaysData}
            filtersSelected={filterApplied as any}
            filtersAppliedCallback={data => setFilterApplied(data)}
            persistFilter
            filters={getCloudFilters(gateWaysData)}
            onRefresh={() => refreshData()}
            error403Flag={!permissionMap['gateways']}
            remoteGatewaySelected={formData?.remoteGatewayId.value}
            onRemoteGatewaySelected={handleRemoteGatewaySelected}
            onClose={onClose}
            setSelectGatewayTearsheet={setSelectGatewayTearsheet}
            deploymentEnvsList={deploymentEnvsList}
          />
        </div>
      </WideTearsheet>
    </div>
  );
};

export default RegisterRemoteConnection;
