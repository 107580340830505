import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionItem,
  Column,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import './SkupperConfiguration.scss';
import { SkupperComputeProfile } from '../../../models/master';
import LabelTag from '../../../components/LabelTag/LabelTag';
import { RHSICustomComputeProfile } from '../../../lib/constants';

interface SkupperSiteConfig {
  ingress: string;
  ingress_host: string;
  router_ingress_host: string;
  controller_ingress_host: string;
  ingress_annotations: string;
  site_name: string;
  router_cpu: string;
  router_cpu_limit: string;
  router_memory: string;
  router_memory_limit: string;
  annotations: string;
  router_service_annotations: string;
  router_pod_annotations: string;
  routers: number;
  router_load_balancer_ip: string;
  router_data_connection_count: number;
  create_network_policy: boolean;
  enable_service_sync: boolean;
  router_mode: string;
  router_logging: string;
  labels: string;
}

interface Props {
  skupperSiteConfig: SkupperSiteConfig | null;
  gatewaySizing: SkupperComputeProfile | null;
}

const SkupperConfiguration: React.FC<Props> = ({
  skupperSiteConfig,
  gatewaySizing,
}) => {
  const { t } = useTranslation('gatewayDetails');

  return (
    <Accordion className='configuration-settings-accordion' align='start'>
      <AccordionItem title='Configuration settings'>
        <div className='configuration-settings-section router-sizing-section'>
          <div className='configuration-settngs-section-heading'>
            {t('skupperConfiguration.routerSizing.heading')}
          </div>
          <Row className='configuration-settings-row'>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerSizing.requestsCPU')}
                </span>
                <span className='value'>
                  {gatewaySizing?.profile === RHSICustomComputeProfile.profile
                    ? skupperSiteConfig?.router_cpu || '—'
                    : gatewaySizing?.cpu_req || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerSizing.requestsMemory')}
                </span>
                <span className='value'>
                  {gatewaySizing?.profile === RHSICustomComputeProfile.profile
                    ? skupperSiteConfig?.router_memory || '—'
                    : gatewaySizing?.mem_req || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerSizing.limitsCPU')}
                </span>
                <span className='value'>
                  {gatewaySizing?.profile === RHSICustomComputeProfile.profile
                    ? skupperSiteConfig?.router_cpu_limit || '—'
                    : gatewaySizing?.cpu_lim || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerSizing.limitsMemory')}
                </span>
                <span className='value'>
                  {gatewaySizing?.profile === RHSICustomComputeProfile.profile
                    ? skupperSiteConfig?.router_memory_limit || '—'
                    : gatewaySizing?.mem_lim || '—'}
                </span>
              </div>
            </Column>
          </Row>
        </div>
        <div className='configuration-settings-section ingress-settings-section'>
          <div className='configuration-settngs-section-heading'>
            {t('skupperConfiguration.ingressSettings.heading')}
          </div>
          <Row className='configuration-settings-row'>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.ingressSettings.ingress')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.ingress || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.ingressSettings.ingressHost')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.ingress_host || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.ingressSettings.routerIngressHost')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_ingress_host || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t(
                    'skupperConfiguration.ingressSettings.controllerIngressHostName'
                  )}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.controller_ingress_host || '—'}
                </span>
              </div>
            </Column>
          </Row>
          <Row className='configuration-settings-row'>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.ingressSettings.ingressAnnotations')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.ingress_annotations || '—'}
                </span>
              </div>
            </Column>
          </Row>
        </div>
        <div className='configuration-settings-section'>
          <div className='configuration-settngs-section-heading'>
            {t('skupperConfiguration.routerConfig.heading')}
          </div>
          <Row className='configuration-settings-row'>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.siteName')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.site_name || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.routerMode.label')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_mode || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.routerLogging.label')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_logging || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.annotations')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.annotations || '—'}
                </span>
              </div>
            </Column>
          </Row>
          <Row className='configuration-settings-row'>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t(
                    'skupperConfiguration.routerConfig.routerServiceAnnotations'
                  )}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_service_annotations || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.routerPodAnnotations')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_pod_annotations || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.routers')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.routers || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t(
                    'skupperConfiguration.routerConfig.routerDataConnectionCount'
                  )}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_data_connection_count || '—'}
                </span>
              </div>
            </Column>
          </Row>
          <Row className='configuration-settings-row'>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.loadBalancer')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.router_load_balancer_ip || '—'}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t(
                    'skupperConfiguration.routerConfig.createNetworkPolicy.label'
                  )}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.create_network_policy
                    ? t(
                        'skupperConfiguration.routerConfig.createNetworkPolicy.yes'
                      )
                    : t(
                        'skupperConfiguration.routerConfig.createNetworkPolicy.no'
                      )}
                </span>
              </div>
            </Column>
            <Column md={2} className='field-div-col'>
              <div className='field-div'>
                <span className='label'>
                  {t('skupperConfiguration.routerConfig.labels')}
                </span>
                <span className='value'>
                  {skupperSiteConfig?.labels ? (
                    <LabelTag
                      labelArray={skupperSiteConfig.labels
                        .split(',')
                        .map(label => {
                          return { default: false, value: label, type: 'teal' };
                        })}
                      count={3}
                    ></LabelTag>
                  ) : (
                    '—'
                  )}
                </span>
              </div>
            </Column>
          </Row>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default SkupperConfiguration;
