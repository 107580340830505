import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { removeExtraSpace } from '../../../lib/utils';

import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import DeploymentEnvTable from './DeploymentEnvTable/DeploymentEnvTable';
import SkupperGateway from './SkupperGateway';
import MeshGateway from './MeshGateway';

import {
  ResourceGroup,
  Gateway,
  Cloud,
  Location,
  DeploymentEnvironment,
} from '../../../models/master.js';

import { updateGateway } from '../../../controllers/gateawayApis.js';
import { getClouds } from '../../../controllers/cloudApis';
import { getAllLocations } from '../../../controllers/locationApis';

import './EditGateway.scss';

export interface FormData {
  deploymentEnvName: {
    id: string | null;
    name: string;
  } | null;
  deployedInDeplEnvId: {
    value: {
      resource_id: string;
      name: string;
    };
    error: boolean;
    errorMessage: '';
  };
  deployedInType: {
    value: string;
    error: boolean;
    errorMessage: '';
  };
  resourceGroup: {
    value: {
      resource_id: string;
      name: string;
    };
    error: false;
    errorMessage: '';
  };
  clusterName: {
    id: string | null;
    name: string;
  } | null;
  namespaceName: {
    id: string | null;
    name: string;
  } | null;
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  ingressEnabled: {
    value: boolean;
    error: boolean;
    errorMessage: '';
  };
  cloudName: {
    value: {
      resource_id: string;
      name: string;
    };
    error: boolean;
    errorMessage: '';
  };
  locationName: {
    value: {
      resource_id: string;
      name: string;
    };
    error: boolean;
    errorMessage: '';
  };
  labels: {
    value: string[];
    error?: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
    error?: boolean;
    errorMessage: string;
  };
}

const defaultFormData = {
  deploymentEnvName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  deployedInDeplEnvId: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  deployedInType: {
    value: '',
    error: false,
    errorMessage: '',
  },
  resourceGroup: {
    value: {
      resource_id: 'default-infra',
      name: 'Default_Infrastructure_Group',
    },
    error: false,
    errorMessage: '',
  },
  clusterName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  namespaceName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  ingressEnabled: {
    value: false,
    error: false,
    errorMessage: '',
  },
  cloudName: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  locationName: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const requiredFields: any = {
  'AXON-EDGE': ['name'],
  'RHSI-EDGE': ['name'],
  'AXON-WP': ['name'],
};

export interface GatewayToEdit extends Gateway {
  locationName: string | Location | null | undefined;
  cloudName?: string | Cloud | null | undefined;
  ingressEnabled?: boolean;
  clusterName?: string;
  deploymentEnvName?: string;
  namespaceName?: string;
}

interface Props {
  deploymentEnvsList: DeploymentEnvironment[] | null;
  depEnvSubTypesList: any;
  resourceGroupsData: ResourceGroup[] | null;
  gwDetailsData: Gateway | null;
  gatewayDataToEdit: GatewayToEdit | null | undefined;
  gatewayType: 'edge' | 'waypoint';
  gatewaySubtype?: 'AXON-WP' | 'RHSI-EDGE' | 'AXON-EDGE';
  gatewayNamesList: string[];
  open: boolean;
  onClose: () => void;
  onGatewayCreate: () => void;
  gatewaysList: Gateway[] | null;
}

export interface Item {
  depEnvid: string;
  type: string;
  resourceGroupId: string;
  depEnvName: string;
  cloud_id: string;
  cloudName: string;
  location_id: string;
  locationName: string;
}
const EditGateway: React.FC<Props> = ({
  deploymentEnvsList,
  depEnvSubTypesList,
  resourceGroupsData,
  gwDetailsData,
  gatewayDataToEdit,
  gatewayType,
  gatewaySubtype,
  gatewayNamesList,
  open,
  onClose,
  onGatewayCreate,
  gatewaysList,
}) => {
  const { t } = useTranslation('editGateway');
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);

  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const [openDeplEnvTable, setOpenDeplEnvTable] = useState<boolean>(false);
  const [editDeployedInVpcMode, setEditDeployedInVpcMode] =
    useState<boolean>(false);
  const [cloudList, setClouds] = useState<Cloud[] | null>(null);
  const [locationList, setLocations] = useState<Location[] | null>(null);
  const [formData, setFormData] = useState<any>(defaultFormData);
  const [initialFormDataEdit, setInitialFormDataEdit] =
    useState<any>(defaultFormData);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');

  const [selectedDepEnv, setSelectedDepEnv] = useState<Item>({
    depEnvid: '',
    type: '',
    resourceGroupId: '',
    depEnvName: '',
    cloud_id: '',
    cloudName: '',
    location_id: '',
    locationName: '',
  });

  const selectDeploymentEnv = (item: Item) => {
    setSelectedDepEnv(prevState => ({
      ...prevState,
      depEnvid: item.depEnvid,
      type: item.type,
      resourceGroupId: item.resourceGroupId,
      depEnvName: item.depEnvName,
      cloud_id: item.cloud_id,
      cloudName: item.cloudName,
      location_id: item.location_id,
      locationName: item.locationName,
    }));
  };

  useEffect(() => {
    setLoading(true);
    fetchClouds();
    fetchLocations();
    setLoading(false);
  }, []);

  const fetchClouds = async () => {
    let clouds;
    try {
      clouds = await getClouds();
      setClouds(clouds);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLocations = async () => {
    let locations;
    try {
      locations = await getAllLocations();
      setLocations(locations);
    } catch (err) {
      console.log(err);
    }
  };

  const setEditGatewayFormData = () => {
    if (gatewayDataToEdit) {
      let updatedFormData;
      if (gatewaySubtype === 'RHSI-EDGE') {
        updatedFormData = {
          namespaceName: {
            ...formData.namespaceName,
            value: gatewayDataToEdit.namespaceName,
          },
          clusterName: {
            ...formData.clusterName,
            value: gatewayDataToEdit.clusterName,
          },
          cloudName: {
            ...formData.cloudName,
            value: {
              resource_id: gatewayDataToEdit?.cloudName,
              name: gatewayDataToEdit?.cloudName,
            },
          },
          locationName: {
            ...formData.locationName,
            value: {
              resource_id: gatewayDataToEdit?.locationName,
              name: gatewayDataToEdit?.locationName,
            },
          },
          ingressEnabled: {
            ...formData.name,
            value: gatewayDataToEdit?.ingressEnabled,
          },
          name: {
            ...formData.name,
            value: gatewayDataToEdit.name,
          },
          labels: {
            ...formData.labels,
            value: gatewayDataToEdit.labels,
          },
          description: {
            ...formData.description,
            value: gatewayDataToEdit.description,
          },
        };
      } else if (
        (gatewaySubtype === 'AXON-EDGE' && gatewayType === 'edge') ||
        (gatewaySubtype === 'AXON-WP' && gatewayType === 'waypoint')
      ) {
        updatedFormData = {
          deploymentEnvName: {
            ...formData?.deploymentEnvName,
            value: gatewayDataToEdit?.deploymentEnvName,
          },
          resourceGroup: {
            ...formData?.resourceGroup,
            value: {
              resource_id: gatewayDataToEdit?.resource_group_id
                ? gatewayDataToEdit?.resource_group_id
                : '',
              name:
                resourceGroupsData &&
                Array.isArray(resourceGroupsData) &&
                gatewayDataToEdit?.resource_group_id
                  ? resourceGroupsData?.find(
                      resource =>
                        resource.resource_id ===
                          gatewayDataToEdit?.resource_group_id ?? '—'
                    )?.name ?? '—'
                  : '—',
            },
          },
          name: {
            ...formData?.name,
            value: gatewayDataToEdit?.name,
          },

          cloudName: {
            ...formData?.cloudName,
            value: {
              resource_id: gatewayDataToEdit?.cloudName,
              name: gatewayDataToEdit?.cloudName,
            },
          },
          locationName: {
            ...formData?.locationName,
            value: {
              resource_id: gatewayDataToEdit?.locationName,
              name: gatewayDataToEdit?.locationName,
            },
          },
          labels: {
            ...formData?.labels,
            value: gatewayDataToEdit?.labels,
          },
          description: {
            ...formData?.description,
            value: gatewayDataToEdit?.description,
          },

          deployedInDeplEnvId: {
            ...formData?.deployedInDeplEnvId,
            value: {
              resource_id: gatewayDataToEdit?.deployed_in_depl_env_id,
              name: gatewayDataToEdit?.deploymentEnvName,
            },
          },
          deployedInType: {
            ...formData?.deployedInType,
            value: gatewayDataToEdit?.deployed_in_type,
          },
        };
      }
      setInitialFormDataEdit(JSON.parse(JSON.stringify(updatedFormData)));
      setFormData(JSON.parse(JSON.stringify(updatedFormData)));
    }
  };

  useEffect(() => {
    if (gatewayDataToEdit) setEditGatewayFormData();
  }, [gatewayDataToEdit]);

  const cancelForm = () => {
    setEditGatewayFormData();
    onClose();
  };

  const handleOnBlur = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);
    if (errorMessage)
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
  };

  const isFormValid = () => {
    let gwSubType: string = gatewaySubtype != null ? gatewaySubtype : '';
    if (gwSubType.length > 0) {
      for (const field of requiredFields[gwSubType]) {
        const value = formData[field].value;
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (checkFieldValidation(field, trimmedValue)) {
          return false;
        }
      }
    }

    return true;
  };
  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        const valueEmpty = value === '';
        const notUnique =
          gatewayDataToEdit?.name.trim() === value.trim()
            ? false
            : gatewayNamesList.find(name => name.trim() === value.trim())
            ? true
            : false;
        const error = valueEmpty || notUnique;
        const message = valueEmpty
          ? t('validation.name.required')
          : t('validation.name.notUnique');
        errorMessage = error ? message : '';
        break;
    }
    return errorMessage;
  };

  const enableButton = (status: boolean) => {
    setChanged(status);
  };

  const checkValueChanged = () => {
    if (JSON.stringify(initialFormDataEdit) !== JSON.stringify(formData)) {
      return true;
    } else return false;
  };

  const handleOnChange = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSelectDeploymentEnv = (
    selectedDepEnv: Item,
    gatewaySubtype: any
  ) => {
    let deploymentPartitionId: string = '',
      deploymentPartitionName: string = '',
      updateFormAction: boolean = true,
      deployedInType = '',
      deploymentVpcId: string = '',
      deploymentVpcName: string = '';

    if (
      gatewaySubtype != null &&
      (gatewaySubtype === 'AXON-EDGE' || gatewaySubtype === 'AXON-WP')
    ) {
      deploymentPartitionId = '';
      deploymentPartitionName = '';
      deployedInType = 'vpc';
      deploymentVpcId = selectedDepEnv.depEnvid;
      deploymentVpcName = selectedDepEnv.depEnvName;
    }

    const selectedResourceGroup = resourceGroupsData?.find(
      (rg: any) => rg.resource_id === selectedDepEnv.resourceGroupId
    );

    const updatedFormData = {
      resourceGroup: {
        ...formData.resourceGroup,
        value: {
          resource_id: selectedResourceGroup?.resource_id ?? '',
          name: selectedResourceGroup?.name ?? '',
        },
      },
      cloudName: {
        ...formData.cloudName,
        value: {
          resource_id: selectedDepEnv.cloud_id,
          name: selectedDepEnv.cloudName,
        },
      },
      locationName: {
        ...formData.locationName,
        value: {
          resource_id: selectedDepEnv.location_id,
          name: selectedDepEnv.locationName,
        },
      },
      deployedInDeplEnvId: {
        ...formData.deployedInDeplEnvId,
        value: {
          resource_id: selectedDepEnv.depEnvid,
          name: selectedDepEnv.depEnvName,
        },
      },

      deployedInType: {
        ...formData.deployedInType,
        value: deployedInType,
      },
    };
    //updateFormAction &&
    setFormData((formData: any) => ({
      ...formData,
      ...updatedFormData,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let updateGatewayData = {};

      if (gatewaySubtype === 'RHSI-EDGE') {
        updateGatewayData = {
          name: removeExtraSpace(formData?.name?.value),
          description: formData?.description?.value.trim(),
          labels: formData?.labels?.value,
        };
      } else if (
        gatewaySubtype === 'AXON-EDGE' &&
        gatewayDataToEdit?.auto_deploy
      ) {
        updateGatewayData = {
          name: removeExtraSpace(formData?.name?.value),
          description: formData?.description?.value.trim(),
          labels: formData?.labels?.value,
        };
      } else if (
        gatewaySubtype === 'AXON-EDGE' &&
        !gatewayDataToEdit?.auto_deploy
      ) {
        updateGatewayData = {
          name: removeExtraSpace(formData?.name?.value),
          description: formData?.description?.value.trim(),
          labels: formData?.labels?.value,
          resource_group_id: formData?.resourceGroup?.value?.resource_id,
          //cloud_id: formData?.cloudName?.value?.resource_id,
          //location_id: formData?.locationName?.value?.resource_id,
          // deployed_in_type: formData?.deployedInType?.value,
          // deployed_in_depl_env_id:
          //   formData?.deployedInDeplEnvId?.value?.resource_id,
        };
      } else if (
        gatewaySubtype === 'AXON-WP' &&
        gatewayDataToEdit?.auto_deploy
      ) {
        updateGatewayData = {
          name: removeExtraSpace(formData?.name?.value),
          description: formData?.description?.value.trim(),
          labels: formData?.labels?.value,
        };
      } else if (
        gatewaySubtype === 'AXON-WP' &&
        !gatewayDataToEdit?.auto_deploy
      ) {
        updateGatewayData = {
          name: removeExtraSpace(formData?.name?.value),
          description: formData?.description?.value.trim(),
          labels: formData?.labels?.value,
          resource_group_id: formData?.resourceGroup?.value?.resource_id,
          // cloud_id: formData?.cloudName?.value?.resource_id,
          // location_id: formData?.locationName?.value?.resource_id,
          // deployed_in_type: formData?.deployedInType?.value,
          // deployed_in_depl_env_id:
          //   formData?.deployedInDeplEnvId?.value?.resource_id,
        };
      }
      const gateway: Gateway = await updateGateway(
        gatewayDataToEdit?.resource_id,
        updateGatewayData
      );

      notification.onTrigger('TOAST', {
        title: t('successNotificationGateway.title'),
        subtitle: t('successNotificationGateway.subtitle', {
          name: gateway.name,
        }),
        lowContrast: true,
      });

      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      onGatewayCreate();
    } catch (error) {
      const err = error as AxiosError;
      let errorMessage: string = '';
      if (err.response?.status === 403) {
        setAuthError(true);
      }
      const errorData = err?.response?.data as any;
      if (errorData?.error ?? errorData?.status) {
        if (errorData?.error != null) {
          errorMessage = errorData?.error.length > 0 ? errorData?.error : '';
        } else if (errorData?.status != null) {
          errorMessage = errorData?.status.length > 0 ? errorData?.status : '';
        }

        setSubTitleErrorMsg(
          errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
        );
      }
      toggleFailNotification(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  const handleClickDeploymentEnv = (item: Item) => {
    selectDeploymentEnv(item);
  };

  return (
    <WideTearsheet
      className='edit-edgeGateway'
      title={
        gatewaySubtype === 'AXON-EDGE'
          ? t('editMeshEdgeFormTitle')
          : gatewaySubtype === 'AXON-WP'
          ? t('editMeshWpFormTitle')
          : t('editSkupperFormTitle')
      }
      description={
        gatewaySubtype === 'AXON-EDGE'
          ? t('editMeshEdgeDescription')
          : gatewaySubtype === 'AXON-WP'
          ? t('editeMeshWpDescription')
          : t('editeSkupperDescription')
      }
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('submitEditButtonText'),
          onClick: () => handleSubmit(),
          disabled: !(isFormValid() && checkValueChanged()),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => {
            cancelForm();
            toggleFailNotification(false);
            setAuthError(false);
          },
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => handleErrorBarClose() as any}
          kind={'error'}
          title={
            authError
              ? (t('failureNotificationGateway.authTitle') as string)
              : (t('failureNotificationGateway.title') as string)
          }
          subtitle={
            authError
              ? (t('failureNotificationGateway.authSubtitle') as string)
              : subTitleErrorMsg.length === 0
              ? (t('failureNotificationGateway.subtitle') as string)
              : subTitleErrorMsg
          }
        />
      )}
      {editDeployedInVpcMode && (
        <WideTearsheet
          className='deployment-env-table-wide-tearsheet'
          title={
            gatewaySubtype === 'AXON-EDGE'
              ? t('deploymentEnvTable.vpcTitle')
              : t('deploymentEnvTable.clusterTitle')
          }
          description={
            gatewaySubtype === 'AXON-EDGE'
              ? t('deploymentEnvTable.vpcDescription')
              : t('deploymentEnvTable.clusterDescription')
          }
          open={editDeployedInVpcMode}
          actions={[
            {
              kind: 'primary',
              label: t('deploymentEnvTable.selectBtn'),
              onClick: () => {
                setEditDeployedInVpcMode(false);
                handleSelectDeploymentEnv(selectedDepEnv, gatewaySubtype);
              },
              disabled: Object.values(selectedDepEnv).every(
                prop => prop.length === 0
              ),
              loading: loading,
            },
            {
              kind: 'secondary',
              label: t('deploymentEnvTable.cancelBtn'),
              onClick: () => {
                setOpenDeplEnvTable(false);
                if (!editDeployedInVpcMode) {
                  setSelectedDepEnv({
                    depEnvid: '',
                    type: '',
                    resourceGroupId: '',
                    depEnvName: '',
                    cloud_id: '',
                    cloudName: '',
                    location_id: '',
                    locationName: '',
                  });
                } else {
                  setEditDeployedInVpcMode(false);
                }
                toggleFailNotification(false);
                setAuthError(false);
                setSubTitleErrorMsg('');
              },
            },
          ]}
        >
          {
            <DeploymentEnvTable
              deploymentEnvs={
                deploymentEnvsList != null
                  ? deploymentEnvsList.filter((env: DeploymentEnvironment) => {
                      if (gatewaySubtype !== null) {
                        if (
                          gatewaySubtype === 'AXON-EDGE' ||
                          gatewaySubtype === 'AXON-WP'
                        ) {
                          return (
                            env.unmanaged === false &&
                            env.type === 'vpc' &&
                            env.resource_id !==
                              gatewayDataToEdit?.deployed_in_depl_env_id
                          );
                        } else if (gatewaySubtype === 'RHSI-EDGE') {
                          return (
                            env.unmanaged === false && env.type === 'cluster'
                          );
                        }
                      }
                    })
                  : []
              }
              resourceGroupsData={resourceGroupsData}
              deploymentEnvSubtypesData={depEnvSubTypesList}
              handleSelectDepEnv={(item: Item) =>
                handleClickDeploymentEnv(item)
              }
              selectedDepEnv={selectedDepEnv}
              enableButton={(status: boolean) => enableButton(status)}
            />
          }
        </WideTearsheet>
      )}
      {gatewaySubtype === 'RHSI-EDGE' ? (
        <SkupperGateway
          formData={formData}
          onChange={handleOnChange}
          resourceGroupList={resourceGroupsData}
          onBlur={handleOnBlur}
          type={gatewayType}
          subType={gatewaySubtype}
          onClickEditDeployedInVpcBtn={() => setEditDeployedInVpcMode(true)}
          selectedDepEnv={selectedDepEnv}
          openDeploymentEnvTable={() => {
            setOpenDeplEnvTable(true);
          }}
          autoDeployed={''}
          cloudsList={cloudList}
          locationsList={locationList}
        />
      ) : (
        <MeshGateway
          formData={formData}
          onChange={handleOnChange}
          resourceGroupList={resourceGroupsData}
          onBlur={handleOnBlur}
          type={gatewayType}
          subType={gatewaySubtype}
          onClickEditDeployedInVpcBtn={() => setEditDeployedInVpcMode(true)}
          selectedDepEnv={selectedDepEnv}
          openDeploymentEnvTable={() => {
            setOpenDeplEnvTable(true);
          }}
          autoDeployed={
            gatewaySubtype === 'AXON-EDGE' || 'AXON-WP'
              ? gatewayDataToEdit?.auto_deploy
                ? true
                : false
              : ''
          }
          cloudsList={cloudList}
          locationsList={locationList}
          deploymentEnvs={
            deploymentEnvsList != null
              ? deploymentEnvsList.filter((env: DeploymentEnvironment) => {
                  if (gatewaySubtype !== null) {
                    if (
                      gatewaySubtype === 'AXON-EDGE' ||
                      gatewaySubtype === 'AXON-WP'
                    ) {
                      if (env.type === 'vpc') {
                        let filteredGw = gatewaysList?.filter(
                          gateway =>
                            Object.keys(gateway).includes(
                              'deployedInDeplEnvId'
                            ) &&
                            gateway.deployed_in_depl_env_id === env.resource_id
                        );
                        if (
                          Array.isArray(filteredGw) &&
                          filteredGw.length > 0
                        ) {
                          return false;
                        }
                      }
                      let cloudAutoDiscoverOn: boolean = false;
                      if (cloudList && cloudList?.length > 0) {
                        let filteredCloud = cloudList?.find(
                          cloud =>
                            cloud.resource_id === env.cloud_id &&
                            env.type === 'vpc'
                        );
                        cloudAutoDiscoverOn =
                          filteredCloud != null && filteredCloud.auto_discover;
                      }
                      if (gatewayDataToEdit?.auto_deploy) {
                        return (
                          env.unmanaged === false &&
                          env.type === 'vpc' &&
                          cloudAutoDiscoverOn
                        );
                      } else {
                        return env.unmanaged === false && env.type === 'vpc';
                      }
                    } else if (gatewaySubtype === 'RHSI-EDGE') {
                      return env.unmanaged === false && env.type === 'cluster';
                    }
                  }
                })
              : []
          }
        />
      )}
    </WideTearsheet>
  );
};

export default EditGateway;
