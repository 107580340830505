import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProceduralActivityType, getProceduralActivityConfig } from './config';

import './GatewayStatus.scss';
import { InlineLoading, TooltipDefinition } from 'carbon-components-react';

interface Props {
  status: ProceduralActivityType;
}

const ProceduralActivity: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('gatewayStatus');

  const config = getProceduralActivityConfig(t, status);
  if (config) {
    if (status === 'waiting-for-closure') {
      return (
        <TooltipDefinition
          tooltipText={t('proceduralActivity.tooltip.waitingClosure')}
          direction='bottom'
          align='center'
        >
          <div className='procedural-activity-field'>
            <div className='icon'>{config.icon}</div>
            <div className='status-message'>{config.label}</div>
          </div>
        </TooltipDefinition>
      );
    }
    if (config.loadingIcon) {
      return (
        <div className='procedural-activity-field'>
          <InlineLoading description={config.label} />
        </div>
      );
    }
    return (
      <div className='procedural-activity-field'>
        <div className='icon'>{config.icon}</div>
        <div className='status-message'>{config.label}</div>
      </div>
    );
  }

  return <>—</>;
};

export default ProceduralActivity;
