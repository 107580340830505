import React, { useState, useEffect } from 'react';
import {
  TextInput,
  Button,
  Tag,
  ButtonKind,
  TagTypeName,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';

import './AddLabels.scss';

interface Props {
  id: string;
  labelText: string;
  placeholder: string;
  onChange: (labels: string[]) => void;
  btnText: string;
  btnKind?: ButtonKind;
  tagType?: TagTypeName;
  light?: boolean;
  defaultValues?: string[] | null;
  wraperClass?: string;
  large?: boolean;
  disabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const AddLabels: React.FC<Props> = ({
  id,
  labelText,
  placeholder,
  onChange,
  btnText,
  light = false,
  btnKind = 'secondary',
  tagType = 'green',
  defaultValues = [],
  wraperClass = '',
  large,
  disabled,
  onKeyDown = () => {},
}) => {
  const [value, setValue] = useState('');
  const [labels, setLabels] = useState<string[] | any>(defaultValues ?? []);

  useEffect(() => {
    const isSame =
      defaultValues?.length === labels.length &&
      defaultValues?.every((element, index) => element === labels[index]);

    if (!isSame) {
      setValue('');
      setLabels(defaultValues ?? []);
    }
  }, [defaultValues]);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value);
  };

  const handleAddLabel: React.MouseEventHandler<HTMLButtonElement> = () => {
    setValue('');

    const trimmedValue = value.trim();
    const duplicateCheck = (val: string) => val === trimmedValue;
    if (trimmedValue) {
      if (!labels.some(duplicateCheck)) {
        const newLabels = [...labels, trimmedValue];
        setLabels(newLabels);
        onChange(newLabels);
      }
    }
  };

  const handleRemoveLabel = (index: number) => {
    labels.splice(index, 1);
    const newLabels = [...labels];
    setLabels(newLabels);
    onChange(newLabels);
  };

  return (
    <div className={wraperClass ? wraperClass : 'add-labels-component'}>
      <div className='input-container'>
        <div className='input'>
          <TextInput
            value={value}
            light={light}
            id={id}
            labelText={labelText}
            placeholder={placeholder}
            onChange={handleOnChange}
            onKeyDown={onKeyDown}
            autoComplete='off'
            maxLength={20}
            size={large ? 'lg' : 'md'}
            disabled={disabled ? disabled : false}
          />
        </div>
        <div className='button'>
          <Button
            kind={btnKind}
            size={large ? 'default' : 'md'}
            disabled={!value}
            renderIcon={Add16}
            onClick={handleAddLabel}
          >
            {btnText}
          </Button>
        </div>
      </div>
      <div className='values-container'>
        {Array.isArray(labels) &&
          labels.map((label, index) => (
            <Tag
              key={index}
              type={tagType}
              filter
              onClose={() => handleRemoveLabel(index)}
            >
              {typeof label === 'object' ? label?.value ?? '' : label}
            </Tag>
          ))}
      </div>
    </div>
  );
};

export default AddLabels;
