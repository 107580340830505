import { useTranslation } from 'react-i18next';
import { Tile } from 'carbon-components-react';
import { DeploymentEnvironment, Namespace } from '../../../models/master';
import GwDeploymentEnvironmentList from './GatewayEnvironmentList';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import images from '../../../images/images';

import './GatewayEnvironmentsList.scss';

interface Props {
  namespace: Namespace | null;
  networkSegment: {
    resource_id: string;
    name: string;
  } | null;
  deploymentEnvironment: DeploymentEnvironment | undefined;
}

const GatewayNamespaceList = (props: Props) => {
  const { t } = useTranslation('gatewayDetails');
  const { namespace, networkSegment, deploymentEnvironment } = props;

  return (
    <>
      <Tile className='gateway-envs-services-tile'>
        <div className='header-container'>
          <div className='header'>
            {t('namespaceTile.header', {
              title: t('namespaceTile.namespace'),
              count: namespace ? 1 : 0,
            })}
          </div>
        </div>
        {namespace ? (
          <div className='services'>
            <GwDeploymentEnvironmentList
              isNamespace={true}
              partitionId={namespace?.resource_id}
              depEnvId={namespace?.cluster_id}
              name={namespace?.name}
              resGrp={`${t('namespaceTile.networksegment')} ${
                networkSegment?.name
              }`}
              labels={namespace?.labels}
              depEnvName={deploymentEnvironment?.name}
            />
          </div>
        ) : (
          <VerticalEmptyState
            icon={images.NamespaceEmpty()}
            header={t('namespaceTile.emptyState.header')}
            description={`${t('namespaceTile.emptyState.description')}`}
          />
        )}
      </Tile>
    </>
  );
};

export default GatewayNamespaceList;
