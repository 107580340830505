import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { registerNamespace } from '../../controllers/partitionApi';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../lib/utils';
import { AxiosError } from 'axios';
import { Modal } from '@carbon/react';

import MultiStepTearsheet from '../../components/MultiStepTearSheet/MultiStepTearSheet';
import CreateNetworkSegment from '../NetworkSegmentsContainer/CreateNetworkSegment/CreateNetworkSegment';
import analyticsData from '../../lib/analyticsEventData';
import useAnalytics from '../../lib/useAnalytics';
import {
  IngressTypes,
  NetworkSegmentCompatibilitySet,
  QueryKeys,
  RHSIComputeProfiles,
  ResourceGroupTypes,
  VisibilityFlags,
} from '../../lib/enums';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';
import {
  alphaNumbericRegexPattern,
  domainNameValidation,
  ingressAnnotationRegex,
  ipRegexPattern,
} from '../../lib/regex';

import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import { useNetworksegments } from '../../hooks/useNetworksegment';
import {
  useGatewayDetailsData,
  useGatewayRemoteConnectionsList,
  useGateways,
} from '../../hooks/useGateways';
import { useDepEnvNamespaces } from '../../hooks/useNamespace';
import { useDeploymentEnvSubtypes } from '../../hooks/useDeploymentEnvs';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';
import { Gateway, NetworkSegments, ResourceGroup } from '../../models/master';
import { updateGateway } from '../../controllers/gateawayApis';
import { updateNamespaceData } from '../../controllers/deploymentEnv';

import DeployGatewayToNamespace from './DeployGateway/DeployGateway';
import ConfigureGateway from './ConfigureGateway/ConfigureGateway';
import { defaultFormValue } from './config';
import NamespaceDetailsForm from './NamespaceDetailsForm/NamespaceDetailsForm';

import './NamespaceRegister.scss';

const requiredFields = ['name', 'networkSegment'];

interface Props {
  open: boolean;
  hasConnectedPolicy?: boolean;
  onClose: () => void;
  partitonType: any;
  deploymentdata: any;
  createPartition: () => void;
  mode: 'ADD' | 'EDIT';
  appsDeployed?: any[] | null;
  partitionData?: any;
  registerNamespaceId?: string;
}

interface namespacePatchData {
  name?: string;
  labels?: string[];
  description?: string;
  auto_discover?: boolean;
  unmanaged?: boolean;
  is_discovered?: boolean;
  network_segment_id?: string;
  app_resource_group_id?: string;
  gateway_id?: string;
}

const NamespaceRegister: React.FC<Props> = ({
  open,
  onClose,
  partitonType,
  deploymentdata,
  createPartition,
  mode,
  appsDeployed,
  partitionData,
  hasConnectedPolicy = false,
  registerNamespaceId = 'namespace',
}) => {
  const [simulatedDelay] = useState(650);
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [newNetworkSegmentId, setNewNetworkSegmentId] = useState<string>('');
  const [openCreateNetworkSegment, toggleCreateNetworkSegment] =
    useState(false);
  const [openNoGatewayConnectedModal, setNoGatewayConnectedModal] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openGatewayContinueModal, toggleGatewayContinueModal] =
    useState(false);
  const [openDisconnectGatewayModal, toggleDisconnectGatewayModal] =
    useState(false);
  const [optionalParamInValid, setOptionalParamInValid] = useState({
    ingress_hostname: false,
    router_ingress_hostname: false,
    controller_ingress_hostname: false,
    ingress_annotations: false,
    router_cpu_limit: false,
    router_memory_limit: false,
    router_cpu: false,
    router_memory: false,
  });
  const [initialformData, setInitialFormDataEdit] =
    useState<any>(defaultFormValue);

  //error notification states
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [showUpdateFailNotification, toggleUpdateFailNotification] =
    useState(false);
  const [authError, setAuthError] = useState(false);
  const [gatewayFailNotification, toggleGatewayFailNotification] =
    useState(false);
  const [gatewayAuthError, setGatewayAuthError] = useState(false);

  // Confirmation Modal states
  const [status, setStatus] = useState('inactive');
  const [description, setDescription] = useState('Submitting...');
  const [enableConfigureGateway, setEnableConfigureGateway] = useState(false);

  let submitInProgress = false;

  const { t } = useTranslation('namespaceRegister');
  const { trackButtonClicked } = useAnalytics();
  const notification = useContext(NotificationContext);

  const { data: depEnvSubTypesList } = useDeploymentEnvSubtypes({
    enabled: open,
  });

  const { data: resourceGroups } = useResourceGroupsData({ enabled: open });

  const resourceGroupList = useMemo(() => {
    return resourceGroups?.filter(
      (resourceGroup: any) =>
        resourceGroup.type === ResourceGroupTypes.INFRASTRUCTURE
    );
  }, [resourceGroups]);

  const { data: namespaceListData } = useDepEnvNamespaces(
    deploymentdata?.cloud_id,
    deploymentdata?.resource_id,
    VisibilityFlags.ALL,
    { enabled: open }
  );

  const {
    data: networkSegments,
    refetch: refetchNetworksegments,
    isRefetching: loadingNetworkSegments,
  } = useNetworksegments({ enabled: open });

  const networkSegmentList = useMemo(() => {
    if (newNetworkSegmentId) {
      setFormData((prevState: any) => ({
        ...prevState,
        networkSegment: {
          ...formData.networkSegment,
          value:
            networkSegments != null
              ? networkSegments.find(
                  (segment: NetworkSegments) =>
                    segment.resource_id === newNetworkSegmentId
                ) ?? null
              : null,
        },
      }));
      setNewNetworkSegmentId('');
    }
    if (
      networkSegments != null &&
      !networkSegments.find(
        (segment: NetworkSegments) =>
          segment.resource_id === 'create-network-segment'
      )
    ) {
      networkSegments.push({
        name: t('createNetworkSegmentButton'),
        resource_id: 'create-network-segment',
        labels: [],
        resource_group_id: '',
        compatibility_set: 'RHSI',
      });
      return networkSegments;
    } else if (!networkSegments) {
      return [
        {
          name: t('createNetworkSegmentButton'),
          resource_id: 'create-network-segment',
          labels: [],
          resource_group_id: '',
          compatibility_set: 'RHSI',
        },
      ];
    } else {
      return networkSegments;
    }
  }, [networkSegments]);

  // Queries gateways
  const {
    data: gateways,
    isLoading: loadingGateways,
    error: gatewayError,
    isError: isGatewayError,
    refetch: refetchGateways,
    isRefetching: refetchingGateways,
  } = useGateways(VisibilityFlags.UNMANAGED, { enabled: open });
  // if (isGatewayError) {
  //   const error = gatewayError as AxiosError;
  //   permission.gateways = error?.response?.status === 403 ? false : true;
  //   error500.gateways = error.response!?.status >= 500 ? true : false;
  // }

  // Queries remote connection of gateway connected
  const { data: gatewayConnectedRemoteConnections } =
    useGatewayRemoteConnectionsList(partitionData?.gateway_id, {
      enabled: Boolean(partitionData?.gateway_id && open && mode === 'EDIT'),
    });

  // Queries data of gateway connected
  const {
    data: connectedGatewayData,
    isLoading: loadingConnectedGateways,
    error: connectedGatewayError,
    isError: isConnectedGatewayError,
  } = useGatewayDetailsData(partitionData?.gateway_id, {
    enabled: Boolean(partitionData?.gateway_id && open && mode === 'EDIT'),
  });

  const unmanagedGatewayList = useMemo(() => {
    return gateways?.filter(
      (gateway: any) =>
        gateway?.lifecycle_state === 'gw-not-deployed' && gateway?.unmanaged
    );
  }, [gateways]);

  const resetStatus = () => {
    setStatus('inactive');
    setDescription('Submitting...');
  };

  const handleRhsiNamespaceRegister = async () => {
    try {
      submitInProgress = true;

      const partition_data = {
        name: removeExtraSpace(formData?.name?.value),
        labels: formData?.labels?.value,
        description: formData?.description?.value?.trim(),
        auto_discover: formData?.auto_discover?.value,
        unmanaged: !formData?.managedStatus?.value,
        is_discovered: false,
        network_segment_id: formData?.networkSegment?.value?.resource_id,
        gateway_id: formData?.gateway?.value
          ? formData?.gateway?.value?.resource_id
          : '',
        app_resource_group_id: formData?.appResourceGroup?.value?.resource_id,
      };

      const nameSpaceData = await registerNamespace(
        deploymentdata.cloud_id,
        deploymentdata.resource_id,
        partition_data
      );

      inValidateListCacheFn(QueryKeys.NAMESPACES);
      if (formData?.gateway?.value) {
        try {
          let skupperConfigArgs = {
            ingress:
              formData?.skupper_site_configuration?.ingress_type?.value?.id,
            ingress_host:
              formData?.skupper_site_configuration?.ingress_hostname?.value,
            router_ingress_host:
              formData?.skupper_site_configuration?.router_ingress_hostname
                ?.value,
            controller_ingress_host:
              formData?.skupper_site_configuration?.controller_ingress_hostname
                ?.value,
            ingress_annotations:
              formData?.skupper_site_configuration?.ingress_annotations?.value,
            site_name:
              formData?.skupper_site_configuration?.site_name?.value?.trim(),
            router_mode:
              formData?.skupper_site_configuration?.router_mode?.value?.id,
            router_logging:
              formData?.skupper_site_configuration?.router_logging?.value?.id,
            router_cpu_limit:
              formData?.skupper_site_configuration?.router_cpu_limit?.value ||
              '',
            router_cpu:
              formData?.skupper_site_configuration?.router_cpu?.value || '',
            router_memory_limit:
              formData?.skupper_site_configuration?.router_memory_limit
                ?.value || '',
            router_memory:
              formData?.skupper_site_configuration?.router_memory?.value || '',
            annotations:
              formData?.skupper_site_configuration?.annotations?.value,
            router_service_annotations:
              formData?.skupper_site_configuration?.router_service_annotations
                ?.value,
            router_pod_annotations:
              formData?.skupper_site_configuration?.router_pod_annotations
                ?.value,
            create_network_policy:
              formData?.skupper_site_configuration?.create_network_policy
                ?.value,
            enable_service_sync:
              formData?.skupper_site_configuration?.enable_service_sync?.value,
            router_load_balancer_ip:
              formData?.skupper_site_configuration?.router_load_balancer_ip
                ?.value,
            router_data_connection_count:
              formData.skupper_site_configuration?.router_data_connection_count?.value?.toString(),
            routers:
              formData.skupper_site_configuration?.routers?.value?.toString(),
            labels: formData?.skupper_site_configuration?.labels?.value?.length
              ? formData?.skupper_site_configuration?.labels?.value?.join(',')
              : '',
          };
          const updatedGatewayData = {
            cloud_id: deploymentdata?.cloud_id,
            location_id: deploymentdata?.location_id,
            deployed_in_type: 'namespace',
            deployed_in_depl_env_id: deploymentdata?.resource_id,
            deployed_in_partition_id: nameSpaceData?.resource_id,
            unmanaged: false,
            network_segment_id: formData?.networkSegment?.value?.resource_id,
            labels: formData?.gatewayLabels?.value,
            description: formData?.gatewayDescription?.value?.trim(),
            intended_compute_profile:
              formData.rhsi_compute_profile?.value?.profile,
            site_configuration: JSON.stringify(skupperConfigArgs),
          };
          const gatewayVal = await updateGateway(
            formData?.gateway?.value?.resource_id,
            updatedGatewayData
          );
          notification.onTrigger('TOAST', {
            kind: 'info',
            customClassName: 'gatewayDeploySuccessToast',
            title: t('gatewayDeployingSuccess.title'),
            subtitle: t('gatewayDeployingSuccess.subtitle', {
              gatewayName: formData?.gateway?.value?.name,
              nameSpaceName: formData?.name?.value,
            }),
          });
        } catch (e) {
          const err = e as AxiosError;
          if (err.response?.status === 403) {
            notification.onTrigger('TOAST', {
              title: t('gatewayFailureNotification.authTitle') as string,
              kind: 'error',
              subtitle: t('gatewayFailureNotification.authSubtitle') as string,
            });
            // We added this project.reject to prevent the modal closing when error occured.
            // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
            // To prevent closing of modal we need to return promise rejection
            // return Promise.reject(() => console.error(err));
          }

          const errorMessage: string =
            err.response !== undefined
              ? (err as any).response['customErrorMessage']
              : '';

          notification.onTrigger('TOAST', {
            title: t('gatewayFailureNotification.title') as string,
            kind: 'error',
            subtitle: t(
              errorMessage && errorMessage.length > 0
                ? errorMessage
                : 'gatewayFailureNotification.subtitle'
            ) as string,
          });
        } finally {
          inValidateListCacheFn(QueryKeys.GATEWAYS);
        }
      }

      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          deploymentEnvName: deploymentdata.name,
          nameSpaceName: nameSpaceData.name,
        }),
      });
      // setChanged(false);
      createPartition();
      setFormData(defaultFormValue);
      setAuthError(false);

      inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      submitInProgress = false;
      setNoGatewayConnectedModal(false);
      resetStatus();
    }
  };

  const handleRhsiNamespaceEdit = async () => {
    try {
      submitInProgress = true;
      toggleFailNotification(false);
      if (partitionData) {
        const { cloud_id, cluster_id, resource_id } = partitionData;
        let updatedData: namespacePatchData = {
          name: removeExtraSpace(formData?.name?.value),
          labels: formData?.labels?.value,
          description: formData?.description?.value?.trim(),
          auto_discover: formData?.auto_discover?.value,
          unmanaged: !formData?.managedStatus?.value,
          is_discovered: false,
          network_segment_id: formData?.networkSegment?.value?.resource_id,
          app_resource_group_id: formData?.gateway?.isDisconnected
            ? ''
            : formData?.appResourceGroup?.value?.resource_id,
        };
        if (
          formData?.gateway?.isDisconnected ||
          (formData?.gateway?.value && !partitionData?.gateway_id)
        )
          updatedData['gateway_id'] =
            formData?.gateway?.value && !formData?.gateway?.isDisconnected
              ? formData?.gateway?.value?.resource_id
              : '';
        // if (!formData?.gatewayId?.value && partitionData['router_site_cor_id'])
        //   updatedData['router_site_cor_id'] = '';
        const updatedPartitionData = await updateNamespaceData(
          cloud_id,
          cluster_id,
          resource_id,
          updatedData
        );

        notification.onTrigger('TOAST', {
          title: t('updateNamespaceSuccessNotification.title'),
          subtitle: t('updateNamespaceSuccessNotification.subtitle', {
            deploymentEnvName: deploymentdata.name,
            nameSpaceName: updatedPartitionData.name,
          }),
        });
      }

      if (formData?.gateway?.value && !partitionData?.gateway_id) {
        try {
          let skupperConfigArgs = {
            ingress:
              formData?.skupper_site_configuration?.ingress_type?.value?.id,
            ingress_host:
              formData?.skupper_site_configuration?.ingress_hostname?.value,
            router_ingress_host:
              formData?.skupper_site_configuration?.router_ingress_hostname
                ?.value,
            controller_ingress_host:
              formData?.skupper_site_configuration?.controller_ingress_hostname
                ?.value,
            ingress_annotations:
              formData?.skupper_site_configuration?.ingress_annotations?.value,
            site_name:
              formData?.skupper_site_configuration?.site_name?.value?.trim(),
            router_mode:
              formData?.skupper_site_configuration?.router_mode?.value?.id,
            router_logging:
              formData?.skupper_site_configuration?.router_logging?.value?.id,
            router_cpu_limit:
              formData?.skupper_site_configuration?.router_cpu_limit?.value ||
              '',
            router_cpu:
              formData?.skupper_site_configuration?.router_cpu?.value || '',
            router_memory_limit:
              formData?.skupper_site_configuration?.router_memory_limit
                ?.value || '',
            router_memory:
              formData?.skupper_site_configuration?.router_memory?.value || '',
            annotations:
              formData?.skupper_site_configuration?.annotations?.value,
            router_service_annotations:
              formData?.skupper_site_configuration?.router_service_annotations
                ?.value,
            router_pod_annotations:
              formData?.skupper_site_configuration?.router_pod_annotations
                ?.value,
            create_network_policy:
              formData?.skupper_site_configuration?.create_network_policy
                ?.value,
            enable_service_sync:
              formData?.skupper_site_configuration?.enable_service_sync?.value,
            router_load_balancer_ip:
              formData?.skupper_site_configuration?.router_load_balancer_ip
                ?.value,
            router_data_connection_count:
              formData?.skupper_site_configuration?.router_data_connection_count?.value?.toString(),
            routers:
              formData?.skupper_site_configuration?.routers?.value?.toString(),
            labels: formData?.skupper_site_configuration?.labels?.value?.length
              ? formData?.skupper_site_configuration?.labels?.value?.join(',')
              : '',
          };
          const updatedGatewayData = {
            cloud_id: deploymentdata?.cloud_id,
            location_id: deploymentdata?.location_id,
            deployed_in_type: 'namespace',
            deployed_in_depl_env_id: deploymentdata?.resource_id,
            deployed_in_partition_id: partitionData?.resource_id,
            unmanaged: false,
            network_segment_id: formData?.networkSegment?.value?.resource_id,
            labels: formData?.gatewayLabels?.value,
            description: formData?.gatewayDescription?.value?.trim(),
            intended_compute_profile:
              formData.rhsi_compute_profile?.value?.profile,
            site_configuration: JSON.stringify(skupperConfigArgs),
          };
          const gatewayVal = await updateGateway(
            formData?.gateway?.value?.resource_id,
            updatedGatewayData
          );
          notification.onTrigger('TOAST', {
            kind: 'info',
            customClassName: 'gatewayDeploySuccessToast',
            title: t('gatewayDeployingSuccess.title'),
            subtitle: t('gatewayDeployingSuccess.subtitle', {
              gatewayName: formData?.gateway?.value?.name,
              nameSpaceName: formData?.name?.value,
            }),
          });
        } catch (e) {
          console.log('Gateway update failed');
        }
      }
      // setChanged(false);
      createPartition();
      setFormData(defaultFormValue);
      toggleFailNotification(false);
      setAuthError(false);

      inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
      inValidateListCacheFn(QueryKeys.NAMESPACES);
      inValidateListCacheFn(QueryKeys.GATEWAYS);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      submitInProgress = false;
      setNoGatewayConnectedModal(false);
      toggleDisconnectGatewayModal(false);
      toggleGatewayContinueModal(false);
      resetStatus();
    }
  };

  const handleSubmit = () => {
    if (mode === 'ADD') {
      handleRhsiNamespaceRegister();
    }
    if (mode === 'EDIT') {
      trackButtonClicked(
        analyticsData['Deployment Environment Details'].events.updateNamespace
          .props,
        analyticsData['Deployment Environment Details'].events.updateNamespace
          .event
      );
      handleRhsiNamespaceEdit();
    }
  };

  const cancelForm = () => {
    onClose();
    setFormData(defaultFormValue);
    toggleFailNotification(false);
    toggleGatewayFailNotification(false);
    toggleUpdateFailNotification(false);
    setAuthError(false);
  };

  const checkFieldValidation = (name: string, value: any, subKey: string) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        const valueEmpty =
          value === '' || value === null || value === undefined ? true : false;
        const filteredPartitionList =
          mode === 'ADD'
            ? namespaceListData?.namespaces?.filter(
                (partition: any) => partition.name === value
              ) ?? []
            : namespaceListData?.namespaces?.filter(
                (partition: any) =>
                  partition.name === value &&
                  partition.name !== partitionData?.name
              ) ?? [];
        const notUnique = filteredPartitionList?.length > 0 ? true : false;
        errorMessage = valueEmpty
          ? t('validation.name.required')
          : notUnique
          ? t('validation.name.alreadyExists')
          : '';
        break;
      case 'skupper_site_configuration':
        // if (
        //   (subKey === 'routers' || subKey === 'router_data_connection_count') &&
        //   (value === '' || Number(value) < 0)
        // ) {
        //   errorMessage = t(`validation.${subKey}.errorMessage`);
        // }
        if (
          (subKey === 'router_cpu_limit' ||
            subKey === 'router_memory_limit' ||
            subKey === 'router_cpu' ||
            subKey === 'router_memory') &&
          !value
        ) {
          errorMessage = t('validation.custom_router_sizing.emptyErrorMessage');
        } else if (value) {
          if (
            subKey === 'ingress_hostname' ||
            subKey === 'router_ingress_hostname' ||
            subKey === 'controller_ingress_hostname'
          ) {
            let domainNameRegex = domainNameValidation();
            let valid = domainNameRegex.test(value);
            errorMessage = !valid ? t('validation.hostname.errorMessage') : '';
          } else if (
            subKey === 'ingress_annotations' ||
            subKey === 'annotations' ||
            subKey === 'router_service_annotations' ||
            subKey === 'router_pod_annotations'
          ) {
            let ingressAnnotation: any = ingressAnnotationRegex();
            let annotationArr = value.split(' ');
            let valid = annotationArr.every((annotation: string) =>
              ingressAnnotation.test(annotation)
            );
            errorMessage = !valid ? t(`validation.${subKey}.errorMessage`) : '';
          } else if (
            subKey === 'routers' ||
            subKey === 'router_data_connection_count'
          ) {
            errorMessage = !value ? t(`validation.${subKey}.errorMessage`) : '';
          } else if (subKey === 'site_name') {
            let alphaNumericRegex: any = alphaNumbericRegexPattern();
            const valid = alphaNumericRegex.test(value.trim());
            errorMessage = !valid ? t('validation.site_name.errorMessage') : '';
          } else if (subKey === 'router_load_balancer_ip') {
            let alphaNumericRegex: any = ipRegexPattern();
            const valid = alphaNumericRegex.test(value.trim());
            errorMessage = !valid
              ? t('validation.router_load_balancer_ip.errorMessage')
              : '';
          }
        }
        setOptionalParamInValid(prevState => ({
          ...prevState,
          [subKey]: !!errorMessage,
        }));

        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any, subKey: string = '') => {
    let defaultSkupperConfig = JSON.parse(
      JSON.stringify(defaultFormValue.skupper_site_configuration)
    );
    let errorMessage = checkFieldValidation(name, value, subKey);

    if (name === 'skupper_site_configuration') {
      if (
        subKey === 'ingress_type' &&
        (value.id.length === 0 || value.id === IngressTypes.NONE)
      ) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            ingress_type: { ...defaultSkupperConfig.ingress_type, value },
            ingress_hostname: defaultSkupperConfig.ingress_hostname,
            router_ingress_hostname:
              defaultSkupperConfig.router_ingress_hostname,
            controller_ingress_hostname:
              defaultSkupperConfig.controller_ingress_hostname,
            ingress_annotations: defaultSkupperConfig.ingress_annotations,
          },
        }));
        setOptionalParamInValid((prevState: any) => ({
          ...prevState,
          ingress_hostname: false,
          router_ingress_hostname: false,
          controller_ingress_hostname: false,
          ingress_annotations: false,
        }));
      } else if (subKey === 'router_logging' && !value) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            [subKey]: {
              value: defaultSkupperConfig.router_logging.value,
              error: !!errorMessage,
              errorMessage,
            },
          },
        }));
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            [subKey]: {
              value,
              error: !!errorMessage,
              errorMessage,
            },
          },
        }));
      }
    } else if (name === 'rhsi_compute_profile') {
      if (value?.profile !== RHSIComputeProfiles.CUSTOM) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            router_cpu_limit: defaultSkupperConfig.router_cpu_limit,
            router_cpu: defaultSkupperConfig.router_cpu,
            router_memory_limit: defaultSkupperConfig.router_memory_limit,
            router_memory: defaultSkupperConfig.router_memory,
          },
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        }));
        setOptionalParamInValid((prevState: any) => ({
          ...prevState,
          router_cpu_limit: false,
          router_memory_limit: false,
          router_cpu: false,
          router_memory: false,
        }));
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            router_cpu_limit: {
              ...formData.skupper_site_configuration?.router_cpu_limit,
              error: formData.skupper_site_configuration?.router_cpu_limit
                ?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration
                ?.router_cpu_limit?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
            router_cpu: {
              ...formData.skupper_site_configuration?.router_cpu,
              error: formData.skupper_site_configuration?.router_cpu?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration?.router_cpu
                ?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
            router_memory_limit: {
              ...formData.skupper_site_configuration?.router_memory_limit,
              error: formData.skupper_site_configuration?.router_memory_limit
                ?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration
                ?.router_memory_limit?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
            router_memory: {
              ...formData.skupper_site_configuration?.router_memory,
              error: formData.skupper_site_configuration?.router_memory?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration?.router_memory
                ?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
          },
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        }));
        setOptionalParamInValid((prevState: any) => ({
          ...prevState,
          router_cpu_limit: formData.skupper_site_configuration
            ?.router_memory_limit?.value
            ? false
            : true,
          router_memory_limit: formData.skupper_site_configuration
            ?.router_memory_limit?.value
            ? false
            : true,
          router_cpu: formData.skupper_site_configuration?.router_cpu?.value
            ? false
            : true,
          router_memory: formData.skupper_site_configuration?.router_memory
            ?.value
            ? false
            : true,
        }));
      }
    } else if (name === 'gateway') {
      setEnableConfigureGateway(true);
      setFormData((prevState: any) => ({
        ...prevState,
        gateway: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
        name: {
          value: value?.namespace_name,
          error: !!errorMessage,
          errorMessage,
        },
        gatewayLabels: {
          value: value?.labels,
          error: !!errorMessage,
          errorMessage,
        },
        gatewayDescription: {
          value: value?.description,
          error: !!errorMessage,
          errorMessage,
        },
        managedStatus: {
          value: true,
          error: !!errorMessage,
          errorMessage,
        },
        intended_compute_profile: {
          ...formData.intended_compute_profile,
          value: value?.intended_compute_profile ?? '',
        },
        skupper_site_configuration: {
          ...getSiteConfigurations(value),
        },
      }));
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    }
  };

  const disConnectGateway = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      gateway: {
        ...prevState.gateway,
        isDisconnected: true,
      },
      auto_discover: {
        value: false,
        error: false,
        errorMessage: '',
      },
    }));
    setEnableConfigureGateway(false);
  };

  const isFormValid = (requiredFields: any) => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue, '')) {
        return false;
      }
    }

    return true;
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  const handleGatewayErrorBarClose = () => {
    toggleGatewayFailNotification(false);
    setGatewayAuthError(false);
  };

  const handleNamespaceUpdateErrorBarClose = () => {
    toggleUpdateFailNotification(false);
    setAuthError(false);
  };

  const getSiteConfigurations = (gateway: Gateway) => {
    const gatewaySiteConfiguration = gateway?.site_configuration
      ? JSON.parse(gateway?.site_configuration)
      : {};
    const siteConfig = {
      ingress_type: {
        value: gatewaySiteConfiguration?.ingress
          ? { id: gatewaySiteConfiguration?.ingress, label: '' }
          : formData.skupper_site_configuration.ingress_type.value,
        error: false,
        errorMessage: '',
      },
      ingress_hostname: {
        value:
          gatewaySiteConfiguration?.ingress_host ||
          formData.skupper_site_configuration.ingress_hostname.value,
        error: false,
        errorMessage: '',
      },
      router_ingress_hostname: {
        value:
          gatewaySiteConfiguration?.router_ingress_host ||
          formData.skupper_site_configuration.router_ingress_hostname.value,
        error: false,
        errorMessage: '',
      },
      controller_ingress_hostname: {
        value:
          gatewaySiteConfiguration?.controller_ingress_host ||
          formData.skupper_site_configuration.controller_ingress_hostname.value,
        error: false,
        errorMessage: '',
      },
      ingress_annotations: {
        value:
          gatewaySiteConfiguration?.ingress_annotations ||
          formData.skupper_site_configuration.ingress_annotations.value,
        error: false,
        errorMessage: '',
      },
      site_name: {
        value: gatewaySiteConfiguration?.site_name || '',
        error: false,
        errorMessage: '',
      },
      router_mode: {
        value: gatewaySiteConfiguration?.router_mode
          ? { id: gatewaySiteConfiguration?.router_mode, label: '' }
          : formData.skupper_site_configuration.router_mode.value,
        error: false,
        errorMessage: '',
      },
      router_logging: {
        value: gatewaySiteConfiguration?.router_logging
          ? { id: gatewaySiteConfiguration?.router_logging, label: '' }
          : formData.skupper_site_configuration.router_logging.value,
        error: false,
        errorMessage: '',
      },
      annotations: {
        value:
          gatewaySiteConfiguration?.annotations ||
          formData.skupper_site_configuration.annotations.value,
        error: false,
        errorMessage: '',
      },
      router_service_annotations: {
        value:
          gatewaySiteConfiguration?.router_service_annotations ||
          formData.skupper_site_configuration.router_service_annotations.value,
        error: false,
        errorMessage: '',
      },
      router_pod_annotations: {
        value:
          gatewaySiteConfiguration?.router_pod_annotations ||
          formData.skupper_site_configuration.router_pod_annotations.value,
        error: false,
        errorMessage: '',
      },
      routers: {
        value:
          gatewaySiteConfiguration?.routers ||
          formData.skupper_site_configuration.routers.value,
        error: false,
        errorMessage: '',
      },
      router_data_connection_count: {
        value:
          gatewaySiteConfiguration?.router_data_connection_count ||
          formData.skupper_site_configuration.router_data_connection_count
            .value,
        error: false,
        errorMessage: '',
      },
      router_load_balancer_ip: {
        value:
          gatewaySiteConfiguration?.router_load_balancer_ip ||
          formData.skupper_site_configuration.router_load_balancer_ip.value,
        error: false,
        errorMessage: '',
      },
      create_network_policy: {
        value: !!gatewaySiteConfiguration?.create_network_policy,
        error: false,
        errorMessage: '',
      },
      enable_service_sync: {
        value:
          gatewaySiteConfiguration?.enable_service_sync === false
            ? gatewaySiteConfiguration?.enable_service_sync
            : formData.skupper_site_configuration.enable_service_sync.value,
        error: false,
        errorMessage: '',
      },
      router_cpu_limit: {
        value:
          gatewaySiteConfiguration?.router_cpu_limit ||
          formData.skupper_site_configuration.router_cpu_limit.value,
        error: false,
        errorMessage: '',
      },
      router_cpu: {
        value:
          gatewaySiteConfiguration?.router_cpu ||
          formData.skupper_site_configuration.router_cpu.value,
        error: false,
        errorMessage: '',
      },
      router_memory_limit: {
        value:
          gatewaySiteConfiguration?.router_memory_limit ||
          formData.skupper_site_configuration.router_memory_limit.value,
        error: false,
        errorMessage: '',
      },
      router_memory: {
        value:
          gatewaySiteConfiguration?.router_memory ||
          formData.skupper_site_configuration.router_memory.value,
        error: false,
        errorMessage: '',
      },
      labels: {
        value: gatewaySiteConfiguration?.labels
          ? gatewaySiteConfiguration?.labels?.split(',')
          : formData.skupper_site_configuration.labels.value,
        error: false,
        errorMessage: '',
      },
    };
    return siteConfig;
  };

  const updateFormData = async () => {
    const networkSegment =
      partitionData?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID
        ? defaultNetworkSegmentObject
        : networkSegments?.find(
            (rg: any) => rg.resource_id === partitionData?.network_segment_id
          ) || null;
    const appResource = resourceGroups?.find(
      (rg: any) =>
        rg.resource_id ===
        (partitionData?.app_resource_group_id ?? 'default-app')
    );
    if (connectedGatewayData) setEnableConfigureGateway(true);
    const fData = {
      ...formData,
      name: { ...formData.name, value: partitionData?.name },
      labels: {
        ...formData.labels,
        value: Array.isArray(partitionData?.labels)
          ? [...partitionData?.labels]
          : null,
      },
      gatewayLabels: {
        ...formData.gatewayLabels,
        value:
          connectedGatewayData && Array.isArray(connectedGatewayData?.labels)
            ? [...connectedGatewayData?.labels]
            : null,
      },
      auto_discover: {
        ...formData?.auto_discover,
        value: partitionData?.auto_discover,
      },
      managedStatus: {
        ...formData?.managedStatus,
        value: mode === 'EDIT' ? true : !partitionData?.unmanaged,
      },
      description: {
        ...formData.description,
        value: partitionData?.description ?? '',
      },
      gatewayDescription: {
        ...formData.description,
        value: connectedGatewayData ? connectedGatewayData?.description : '',
      },
      networkSegment: {
        ...formData.networkSegment,
        value: networkSegment,
      },
      appResourceGroup: {
        ...formData.appResourceGroup,
        value: appResource,
      },
      gateway: {
        ...formData.gateway,
        value: connectedGatewayData,
        isDisconnected: false,
      },
      infrastructureGroup: {
        value: resourceGroupList?.find(
          (rg: any) =>
            rg.resource_id ===
            (connectedGatewayData?.resource_group_id ||
              networkSegment?.resource_group_id)
        ),
        error: false,
        errorMessage: '',
      },
      intended_compute_profile: {
        ...formData.intended_compute_profile,
        value: connectedGatewayData?.intended_compute_profile ?? '',
      },
      rhsi_compute_profile: {
        ...formData.rhsi_compute_profile,
      },
      skupper_site_configuration: {
        ...getSiteConfigurations(connectedGatewayData),
      },
      version: {
        ...formData.version,
      },
    };
    setInitialFormDataEdit(fData);
    setFormData(fData);
    //  isEditDataLoading(false);
  };

  useEffect(() => {
    if (mode === 'EDIT') updateFormData();
  }, [partitionData, resourceGroups, networkSegments, connectedGatewayData]);

  useEffect(() => {
    if (mode === 'ADD') setEnableConfigureGateway(false);
  }, [open]);

  const disableSaveButton = () => {
    if (mode === 'EDIT' && partitionData?.unmanaged) {
      return false;
    }
    if (JSON.stringify(initialformData) !== JSON.stringify(formData)) {
      return !isFormValid(['name', 'networkSegment']);
    } else return true;
  };

  return (
    <div>
      <div>
        <MultiStepTearsheet
          className='register-namespace-tearsheet'
          title={mode === 'ADD' ? t('titleNamespace') : t('editNamespace')}
          description={''}
          submitButtonText={
            mode === 'ADD' ? t('submitButtonText') : t('saveChangesBtn')
          }
          cancelButtonText={t('cancelButtonText')}
          backButtonText={t('backButtonText')}
          nextButtonText={t('nextButtonText')}
          open={open}
          onClose={() => {
            cancelForm();
            // setChanged(false);
            setAuthError(false);
          }}
          onRequestSubmit={
            mode === 'ADD' &&
            !formData?.gateway?.value &&
            formData?.networkSegment?.value?.compatibility_set ===
              NetworkSegmentCompatibilitySet.ServiceInterconnect
              ? () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      setNoGatewayConnectedModal(true);
                    }, simulatedDelay);
                  })
              : mode === 'EDIT' &&
                formData?.gateway?.isDisconnected &&
                formData?.networkSegment?.value?.compatibility_set ===
                  NetworkSegmentCompatibilitySet.ServiceInterconnect
              ? () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      toggleDisconnectGatewayModal(true);
                    }, simulatedDelay);
                  })
              : mode === 'EDIT' &&
                appsDeployed &&
                appsDeployed?.length > 0 &&
                formData?.gateway?.value &&
                !formData?.gateway?.isDisconnected &&
                formData?.networkSegment?.value?.compatibility_set ===
                  NetworkSegmentCompatibilitySet.ServiceInterconnect
              ? () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      toggleGatewayContinueModal(true);
                    }, simulatedDelay);
                  })
              : () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      handleSubmit();
                    }, simulatedDelay);
                  })
          }
          initialStep={1}
          isSubmitting={submitInProgress}
        >
          <DeployGatewayToNamespace
            gatewayList={unmanagedGatewayList}
            formData={formData}
            onChange={handleOnChange}
            resourceGroupList={resourceGroupList}
            networkSegments={networkSegmentList}
            openCreateSegment={() => toggleCreateNetworkSegment(true)}
            disConnectGateway={() => disConnectGateway()}
            appsDeployed={appsDeployed}
            mode={mode}
            partitionData={partitionData}
            gatewayConnectedRemoteConnections={
              gatewayConnectedRemoteConnections
            }
            nwSegmentsDataLoading={loadingNetworkSegments}
            dataLoading={
              mode === 'EDIT' && partitionData?.gateway_id
                ? loadingConnectedGateways
                : refetchingGateways
            }
            existingCompactibilitySet={'RHSI'}
          ></DeployGatewayToNamespace>
          <ConfigureGateway
            formData={formData}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            isFormValid={isFormValid(['rhsi_compute_profile'])}
            optionalParamInValid={Object.values(optionalParamInValid).some(
              Boolean
            )}
            open={open}
            skupperSiteConfig={
              connectedGatewayData?.site_configuration
                ? JSON.parse(connectedGatewayData?.site_configuration)
                : null
            }
            mode={mode}
            partitionData={partitionData}
            connectedGatewayData={connectedGatewayData}
            enableConfigureGateway={enableConfigureGateway}
          ></ConfigureGateway>
          <NamespaceDetailsForm
            id={registerNamespaceId}
            formData={formData}
            onChange={handleOnChange}
            partitionType={partitonType}
            deploymentdata={deploymentdata}
            // existingCompactibilitySet={existingCompactibilitySet}
            appsDeployed={appsDeployed}
            mode={mode}
            partitionData={partitionData}
            hasConnectedPolicy={hasConnectedPolicy}
            appResourceGroups={resourceGroups?.filter(
              (resourceGroup: ResourceGroup) =>
                resourceGroup.type === ResourceGroupTypes.APPLICATION
            )}
            isSubmitDisabled={
              mode === 'ADD'
                ? !isFormValid(['name', 'networkSegment'])
                : disableSaveButton()
            }
            showFailNotification={showFailNotification}
            gatewayFailNotification={gatewayFailNotification}
            showUpdateFailNotification={showUpdateFailNotification}
            handleErrorBarClose={handleErrorBarClose}
            handleGatewayErrorBarClose={handleGatewayErrorBarClose}
            handleNamespaceUpdateErrorBarClose={
              handleNamespaceUpdateErrorBarClose
            }
            authError={authError}
            gatewayAuthError={gatewayAuthError}
            allDepEnvSubTypes={depEnvSubTypesList ?? []}
            isSubmitting={submitInProgress}
          />

          {openCreateNetworkSegment && (
            <CreateNetworkSegment
              open={openCreateNetworkSegment}
              onClose={() => toggleCreateNetworkSegment(false)}
              networkSegments={networkSegments}
              groups={resourceGroupList}
              fromPage='registerNamespace'
              refreshData={(nwSegmentResourceId?: string) => {
                setNewNetworkSegmentId(nwSegmentResourceId ?? '');
                refetchNetworksegments();
              }}
              className='create-network-segment'
              actionType='ADD'
            />
          )}
          <Modal
            className='no-gateway-modal'
            modalHeading={t('noGatewayConnected.header')}
            onRequestClose={() => {
              submitInProgress = false;
              setNoGatewayConnectedModal(false);
            }}
            onRequestSubmit={() => {
              setStatus('active');
              // setNoGatewayConnectedModal(false);
              handleRhsiNamespaceRegister();
            }}
            primaryButtonText={t('noGatewayConnected.continue')}
            secondaryButtonText={t('noGatewayConnected.cancel')}
            open={openNoGatewayConnectedModal}
            size='sm'
            loadingStatus={status}
            loadingDescription={description}
          >
            {t('noGatewayConnected.body')}
          </Modal>
          <Modal
            className='disconnect-gateway-modal'
            modalHeading={t('disConnectGateway.header')}
            onRequestClose={() => {
              submitInProgress = false;
              toggleDisconnectGatewayModal(false);
            }}
            onRequestSubmit={() => {
              setStatus('active');
              handleRhsiNamespaceEdit();
            }}
            primaryButtonText={t('disConnectGateway.continue')}
            secondaryButtonText={t('disConnectGateway.cancel')}
            open={openDisconnectGatewayModal}
            size='sm'
            loadingStatus={status}
            loadingDescription={description}
          >
            {t('disConnectGateway.body')}
          </Modal>
          <Modal
            className='continue-gateway-modal'
            danger
            modalHeading={t('form.connectedGateway.header')}
            onRequestClose={() => {
              submitInProgress = false;
              toggleGatewayContinueModal(false);
            }}
            onRequestSubmit={() => {
              setStatus('active');
              handleRhsiNamespaceEdit();
            }}
            primaryButtonText={t('form.connectedGateway.continue')}
            secondaryButtonText={t('form.connectedGateway.cancel')}
            open={openGatewayContinueModal}
            size='sm'
            loadingStatus={status}
            loadingDescription={description}
          >
            {t('form.connectedGateway.body')}
          </Modal>
        </MultiStepTearsheet>
      </div>
    </div>
  );
};

export default NamespaceRegister;
