import { HierarchyLink } from 'd3';
import React, { useEffect, useState } from 'react';
import { EdgeClassType, Location, Resouce } from '../types';
import {
  NodeComponentConfig,
  EdgeComponentConfig,
  getNodeComponentConfig,
  getEdgeComponentConfig,
  EdgeType,
} from './config';
import { NetworkSegment } from '../../../models/master';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';

interface Props {
  node: Location | Resouce | null;
  edge: {
    edge: HierarchyLink<Location | Resouce>;
    type: EdgeClassType;
  } | null;
  onClose: () => void;
  setOpen: (open: boolean) => void;
  onTriggerEdgeFocus: (edge: string) => void;
  onTriggerNodeFocus: (node: string) => void;
  sidePanelWidth: string;
  handleSidePanelWidth: (width: string) => void;
  handleHeaderWidth: (width: number) => void;
  initialHeaderWidth: number | null;
  visibility?: 'all' | 'managed' | 'unmanaged';
  headerWidth: number | null;
  open: boolean;
  networkSegments: NetworkSegment[] | null;
}

const SidePanelContainer: React.FC<Props> = ({
  node,
  edge,
  onClose,
  onTriggerEdgeFocus,
  onTriggerNodeFocus,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  visibility = 'managed',
  headerWidth,
  setOpen,
  open,
  networkSegments,
}) => {
  const { trackButtonClicked } = useAnalytics();

  const [nodeComponentConfig, setNodeComponentConfig] =
    useState<NodeComponentConfig | null>(null);
  const [edgeComponentConfig, setEdgeComponentConfig] =
    useState<EdgeComponentConfig | null>(null);
  useEffect(() => {
    const func = (node: Location | Resouce) => {
      const config = getNodeComponentConfig(node?._type);
      if (config && config.component) {
        trackButtonClicked(
          analyticsData['Topology'].events.openSidePanel.props,
          analyticsData['Topology'].events.openSidePanel.event
        );
        setOpen(true);
        setNodeComponentConfig(config as any);
        onTriggerNodeFocus(node.uniqueId);
        onTriggerEdgeFocus('');
      } else {
        onTriggerNodeFocus('');
        onTriggerEdgeFocus('');
      }
    };

    setOpen(false);

    if (node) {
      // Note: since state updates inside useEffect hook are batched,
      // We need to give an timeout to properly close and unmount inner SidePaneDetailsComponent component.
      setTimeout(() => func(node), 500);
    } else {
      setNodeComponentConfig(null);
      onTriggerNodeFocus('');
      onTriggerEdgeFocus('');
    }
  }, [node]);

  useEffect(() => {
    const func = (edgeData: {
      edge: HierarchyLink<Location | Resouce>;
      type: EdgeClassType;
    }) => {
      const config = getEdgeComponentConfig(
        `${edgeData?.edge?.source?.data?._type}-${edgeData?.edge?.target?.data?._type}` as EdgeType,
        edgeData.type
      );

      if (config && config.component) {
        setEdgeComponentConfig(config as any);
        setOpen(true);
        onTriggerNodeFocus('');
        onTriggerEdgeFocus(
          `${edgeData?.edge?.source?.data?.uniqueId}-${edgeData?.edge?.target?.data?.uniqueId}`
        );
      } else {
        onTriggerNodeFocus('');
        onTriggerEdgeFocus('');
      }
    };

    setOpen(false);

    if (edge) {
      // Note: since state updates inside useEffect hook are batched,
      // We need to give an timeout to properly close and unmount inner SidePaneDetailsComponent component.
      setTimeout(() => func(edge), 500);
    } else {
      setEdgeComponentConfig(null);
      onTriggerNodeFocus('');
      onTriggerEdgeFocus('');
    }
  }, [edge]);

  const handleClose = () => {
    setOpen(false);
    onClose();
    onTriggerNodeFocus('');
    onTriggerEdgeFocus('');
  };

  const NodeSidePaneDetailsComponent = nodeComponentConfig
    ? nodeComponentConfig.component
    : null;

  const EdgeSidePaneDetailsComponent = edgeComponentConfig
    ? edgeComponentConfig.component
    : null;

  return (
    <>
      {open && node && NodeSidePaneDetailsComponent && (
        <NodeSidePaneDetailsComponent
          node={node}
          open={open}
          onClose={handleClose}
          sidePanelWidth={sidePanelWidth}
          handleSidePanelWidth={handleSidePanelWidth}
          handleHeaderWidth={handleHeaderWidth}
          initialHeaderWidth={initialHeaderWidth}
          visibility={visibility}
          headerWidth={headerWidth}
        />
      )}
      {open && edge && EdgeSidePaneDetailsComponent && (
        <EdgeSidePaneDetailsComponent
          source={edge?.edge.source.data}
          target={edge?.edge.target.data}
          open={open}
          onClose={handleClose}
          sidePanelWidth={sidePanelWidth}
          handleSidePanelWidth={handleSidePanelWidth}
          handleHeaderWidth={handleHeaderWidth}
          initialHeaderWidth={initialHeaderWidth}
          headerWidth={headerWidth}
          networkSegments={networkSegments}
        />
      )}
    </>
  );
};

export default SidePanelContainer;
