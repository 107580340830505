import {
  MCNM_NETWORK_API_URL,
  MCNM_TOPOLOGY_API_URL,
  MCNM_AUTODEPLOY_GATEWAY_API_URL,
  MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL,
} from './config';
import { axiosInstance } from './api';

//Get Get all of the gateways that MCNM oversees
export async function getGateways(view = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway?visibility=${view}`,
    });

    if (response.data.gateways === null) {
      return [];
    }

    return response?.data?.gateways;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Add a new gateway that MCNM should oversee
export async function addGateway(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/gateway`,
      data,
    });
    console.log('New gateway added successfully');
    return response.data;
  } catch (error) {
    console.log('Error in POST gateway', error);
    throw error;
  }
}

//Delete an existing gateway that MCNM oversees
export async function deleteGateway(gateway_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}`,
    });
    return response;
  } catch (error) {
    console.log('error in DELETE gateway', error);
    throw error;
  }
}

//Get one of the gateways that MCNM oversees
export async function getGateway(gateway_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Update an existing gateway that MCNM oversees
export async function updateGateway(gateway_id, gatewayData) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}`,
      data: gatewayData,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Update an existing gateway that MCNM oversees
export async function updateGatewayDetails(gateway_id, gatewayData) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}`,
      data: gatewayData,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get the list of deploymentenvs that this gateway provides connectivity for
export async function getGatewayDeploymentenvs(gateway_id, view = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}/deploymentenv?visibility=${view}`,
    });
    return response.data.deploymentenvs;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get one of the deploymentenvs that this gateway provides connectivity for
export async function getGatewayDeploymentenv(gateway_id, deploymentenv_id) {}

// Get all of the remote connections for this gateway
export async function getGatewayRemoteConnections(gateway_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}/remoteconnection`,
    });

    return response?.data?.remoteconnections;
  } catch (error) {
    console.log(error);
    return [];
  }
}

//Add a new remote connection for this gateway
export async function addGatewayRemoteConnection(gateway_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}/remoteconnection`,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Delete an existing remote connection of this gateway
export async function deleteGatewayRemoteConnection(
  gateway_id,
  remoteconnection_id
) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}/remoteconnection/${remoteconnection_id}`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get one of the remote connections of this gateway
export async function getGatewayRemoteConnection(
  gateway_id,
  remoteconnection_id
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway/${gateway_id}/remoteconnection/${remoteconnection_id}`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

//Update an existing remote connection of this gateway
export async function updateGatewayRemoteConnection(
  gateway_id,
  remoteconnection_id
) {}

//Get metrics details of the gateway and its remote connection metrics

export async function getGatewayMetrics(gateway_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TOPOLOGY_API_URL}/topology/resource/${gateway_id}?include_metrics=true`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function deployGateway(cloud_id, vpc_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTODEPLOY_GATEWAY_API_URL}/cloud/${cloud_id}/vpc/${vpc_id}/gw_deployment`,
      data,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//CompatibilitySet API
export async function getGatewayCompatibilitySet() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gwcompatibilityset`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getGatewayInstance(gateway_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway_instance?gateway_id=${gateway_id}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getGatewayImageVersions() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTODEPLOY_GATEWAY_API_URL}/gw_deployment/gw_image_versions`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGatewayComputeProfiles() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTODEPLOY_GATEWAY_API_URL}/gw_compute_profiles`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGatewayIngressTypes() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL}/rhsi_ingress_types`,
    });
    return response.data.Ingresses;
  } catch (error) {
    // return [
    //   {
    //     id: 'router',
    //     label: 'OpenShift Router',
    //   },
    //   {
    //     id: 'loadbalancer',
    //     label: 'Load balancer',
    //   },
    //   {
    //     id: 'nginx-ingress-v1',
    //     label: 'Nginx Ingress',
    //   },
    //   {
    //     id: 'ingress',
    //     label: 'Ingress',
    //   },
    //   { id: 'none', label: 'None' },
    //   {
    //     id: '',
    //     label: 'Default',
    //   },
    // ];

    throw error;
  }
}

export async function getRhsiComputeProfile() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL}/rhsi_compute_profile`,
    });
    return response.data.Profiles;
  } catch (error) {
    // return [
    //   {
    //     profile: 'medium',
    //     cpu_req: '110m',
    //     cpu_lim: '410m',
    //     mem_req: '110Mi',
    //     mem_lim: '610Mi',
    //   },
    //   {
    //     profile: 'large',
    //     cpu_req: '120m',
    //     cpu_lim: '420m',
    //     mem_req: '120Mi',
    //     mem_lim: '620Mi',
    //   },
    //   {
    //     profile: 'small',
    //     cpu_req: '100m',
    //     cpu_lim: '400m',
    //     mem_req: '100Mi',
    //     mem_lim: '600Mi',
    //   },
    // ];
    throw error;
  }
}
