import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SkeletonPlaceholder,
  DataTableSkeleton,
  Button,
  TooltipDefinition,
  Toggle,
  OnChangeData,
  Modal,
} from 'carbon-components-react';
import { AxiosError } from 'axios';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { LocationTypes, QueryKeys, VisibilityFlags } from '../../lib/enums';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import sortData from '../../lib/tableSort';
import images from '../../images/images';
import {
  Location,
  DeploymentEnvironment,
  AppliedFilter,
  ResourceGroup,
  Cloud,
  Error500Type,
  DeploymentEnvironmentSubtype,
} from '../../models/master';
import {
  getLocationDetails,
  deleteLocation,
} from '../../controllers/locationApis';
import { getAllLocations } from '../../controllers/location';
import { getCloud } from '../../controllers/cloudApis';
import {
  getDeploymentEnvs,
  getDeploymentEnvSubtypes,
} from '../../controllers/deploymentEnv';
import { getResourceGroups } from '../../controllers/resourceGroupApi';

import DetailsCard from '../../components/DetailsCard/DetailsCard';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import Header from '../../components/Header/Header';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';

import Error500 from '../Errors/Error500';
import RegisterLocation from '../Locations/RegisterLocation/RegisterLocation';
import LocationDetailsDeploymentTable from '../LocationDetails/LocationDetailsDeploymentTable/LocationDetailsDeploymentTable';

import './LocationDetailsContainer.scss';
import RegisterDeploymentEnv from '../DeploymentEnvsContainer/RegisterDeploymentEnv/RegisterDeploymentEnv';

type LocationState = {
  breadcrumb?: any;
};

const defaultPermissionMap = {
  cloud: true,
  deploymentEnv: true,
};

const LocationDetailsContainer = () => {
  const { t } = useTranslation('locationDetails');

  const [locationData, setLocationData] = useState<Location | null>(null);
  const [locationDataLoading, toggleLocationDataLoading] = useState(false);
  const [haveZone, setHaveZone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const couldId = searchParams.get('cloudId');
  const locationId = searchParams.get('locationId');
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('');
  const [locationsDeploymentData, setLocationsDeploymentData] = useState<
    DeploymentEnvironment[] | null
  >(null);
  const [allLocationsDeploymentData, setAllLocationsDeploymentData] = useState<
    DeploymentEnvironment[] | null
  >(null);
  const [filteredData, setFilteredData] = useState<
    DeploymentEnvironment[] | []
  >([]);
  const [
    openRegisterDeploymentEnvTearSheet,
    setOpenRegisterDeploymentEnvTearSheet,
  ] = useState<boolean>(false);

  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [visibilityFlag, setVisibilityFlag] = useState('managed');
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [cloudDetails, setCloudDetails] = useState<Cloud | null>(null);
  const [deplEnvSubtypes, setDeplEnvSubtypes] = useState<
    DeploymentEnvironmentSubtype[] | null
  >(null);
  const [cloudRegionMap, setCloudRegionMap] = useState<any>(new Map());
  const [showEditLocation, setShowEditLocation] = useState<boolean>(false);
  const [openDeleteLocationModal, setOpenDeleteLocationModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const queryClient = useQueryClient();
  const { trackButtonClicked, pageViewed } = useAnalytics();

  const updateLocationDetails = (locationData: Location) => {
    setLocationData(locationData);
  };

  const refreshPage = async () => {
    try {
      setLocationData(null);
      await getAllLocations()
        .then((response: any) => {
          setHaveZone(
            response?.locationsData?.some(
              (location: {
                type: LocationTypes;
                parent_location_id: string | null;
              }) =>
                location.type === LocationTypes.ZONE &&
                location.parent_location_id === locationId
            )
          );
          response?.locationsData
            ?.filter(
              (location: Location) => location.type === LocationTypes.REGION
            )
            .map((location: Location) =>
              setCloudRegionMap(
                new Map(
                  cloudRegionMap.set(location.name, [
                    location.resource_id,
                    location.cloud_location_code,
                    location.cloud_id,
                  ])
                )
              )
            );
        })
        .catch((error: any) => console.log(error));

      await getLocationDetails(couldId, locationId, VisibilityFlags.ALL, true)
        .then(data => {
          setLocationData(data as Location);
        })
        .catch((error: any) => {
          const err = error as AxiosError;

          if (err.response?.status === 404) {
            navigate('/404');
          }

          if (err.response!?.status >= 500) {
            setError500(err.response!?.status?.toString() as Error500Type);
          }
        });

      await getCloud(couldId)
        .then(data => {
          setCloudDetails(data);
        })
        .catch((error: any) => {
          if (error.response?.status === 403) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              cloud: false,
            }));
            // To prevent closing of modal when error occurs, we can return promise rejection, as shown below.
            //return Promise.reject(() => console.error(err));
            // But in latest version of '@carbon/ibm-products' the above line is not required and is throwing error.
          }
          console.log(error);
        });
    } catch (error: any) {
      console.log(error);
    }
  };

  const locationDetailsLabels = () => {
    const labels: any = [];
    if (locationData?.labels) {
      locationData.labels.forEach(el => {
        labels.push(el);
      });
    }
    if (locationData?.discovered_labels) {
      locationData.discovered_labels.forEach(el => {
        labels.push({
          default: true,
          value: el,
        });
      });
    }
    return labels;
  };

  const onClose = () => {
    setShowEditLocation(false);
  };

  useEffect(() => {
    pageViewed('Location Details');
    toggleLocationDataLoading(true);
    refreshPage();
    refreshData();
  }, []);

  useEffect(() => {
    locationData !== null
      ? toggleLocationDataLoading(false)
      : toggleLocationDataLoading(true);
  }, [locationData]);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const getLocationFilters = () => [
    {
      key: 'name',
      label: t('filters.name'),
      type: 'multi',
      values: [
        ...Array.from(
          new Set(locationsDeploymentData?.map(depEnv => depEnv.name))
        ),
      ],
    },
    {
      key: 'type',
      label: t('filters.type'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(locationsDeploymentData?.map(depEnv => depEnv.type))
        ),
      ],
    },
  ];

  const leftInlineFilters: any = [
    {
      key: t('view'),
      type: '',
      values: [
        {
          value: 'all',
          label: t('all'),
        },
        {
          value: 'managed',
          label: t('toggleLabelManaged'),
        },
        {
          value: 'unmanaged',
          label: t('toggleLabelUnmanaged'),
        },
      ],
      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem) {
          setLocationsDeploymentData(null);
          setVisibilityFlag(e.selectedItem?.label?.toLocaleLowerCase());
          applyVisibilityFlag(e.selectedItem?.value);
        }
      },
    },
  ];

  const applyVisibilityFlag = (flag: string) => {
    getDeploymentEnvs('all', true)
      .then(response => {
        let data = response.filter(
          (depEnv: DeploymentEnvironment) => depEnv?.location_id === locationId
        );
        setAllLocationsDeploymentData(data);
        if (flag !== 'all') {
          data = data?.filter((deploymentenv: any) =>
            flag === 'unmanaged'
              ? deploymentenv.unmanaged
              : !deploymentenv.unmanaged
          );
        }
        data.forEach((data: { [x: string]: any; resource_group_id: any }) => {
          const resourceGroup =
            data.resource_group_id && Array.isArray(resourceGroupsData)
              ? resourceGroupsData?.find(
                  resourceGroup =>
                    resourceGroup.resource_id === data.resource_group_id
                )
              : null;

          data['resourceGroup'] = resourceGroup ? resourceGroup.name : '';
        });

        setLocationsDeploymentData(data as DeploymentEnvironment[]);
      })
      .catch(error => {
        toggleLocationDataLoading(false);
        if (error.response?.status === 403) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            deploymentEnv: false,
          }));
        }
      });
  };

  const editBtnClick = () => {
    setShowEditLocation(true);
  };

  const refreshData = async () => {
    try {
      setLocationsDeploymentData(null);
      setFilterApplied([]);
      setFilteredData([]);
      setSortKey('');
      setSortDirection('NONE');
      setVisibilityFlag('managed');

      fetchDeploymentEnvSubtypes();

      const response = await getResourceGroups();
      setResourceGroupsData(response.resource_groups as ResourceGroup[]);
      const resourceGroupsData = response.resource_groups;

      await getDeploymentEnvs('all', true)
        .then(response => {
          let data = response.filter(
            (depEnv: DeploymentEnvironment) =>
              depEnv?.location_id === locationId
          );
          setAllLocationsDeploymentData(data);
          data = data?.filter((deploymentEnv: any) => !deploymentEnv.unmanaged);
          data.map((data: { [x: string]: any; resource_group_id: any }) => {
            const resourceGroup =
              data.resource_group_id && Array.isArray(resourceGroupsData)
                ? resourceGroupsData?.find(
                    resourceGroup =>
                      resourceGroup.resource_id === data.resource_group_id
                  )
                : null;
            data['resourceGroup'] = resourceGroup ? resourceGroup.name : '';
          });
          setLocationsDeploymentData(data as DeploymentEnvironment[]);
        })
        .catch(error => {
          toggleLocationDataLoading(false);
          if (error.response?.status === 403) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              deploymentEnv: false,
            }));
          }
        });
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchDeploymentEnvSubtypes = async () => {
    let depEnvSubTypes;
    try {
      depEnvSubTypes = await getDeploymentEnvSubtypes();
      setDeplEnvSubtypes(depEnvSubTypes ?? null);
    } catch (error) {
      console.error(error);
    }
  };

  const disableUnManagedToggle = () => {
    // If location is already unmanaged then we don't need to disable it
    if (locationData?.unmanaged) {
      return false;
    }
    const unmanagedLocationCount = Array.isArray(allLocationsDeploymentData)
      ? allLocationsDeploymentData.filter(
          deploymentEnv => deploymentEnv.unmanaged === false
        ).length
      : 0;
    return unmanagedLocationCount > 0;
  };

  const managedToggle = !locationData?.unmanaged;

  const getHeaderIcon = () => {
    if (!managedToggle && locationData?.is_discovered) {
      return (
        <div className='header-icon-contaniner'>
          <IconWithToolTip
            icon={images.AutoDiscoverdLockIcon()}
            iconDescription={t('autoDiscoveredAccessLimited')}
          />
        </div>
      );
    }

    if (locationData?.is_discovered) {
      return (
        <div className='autodiscovered-icon-contaniner'>
          <IconWithToolTip
            icon={images.AutoDiscoverdLockIcon()}
            iconDescription={t('autoDiscoveredAccessLimited')}
          />
        </div>
      );
    }

    return false;
  };

  const getSubTitle = () => {
    return (
      <div className='sub-heading-section'>
        <div
          className={
            'header-icon-contaniner ' +
            (!managedToggle ? 'unmanaged' : 'managed')
          }
        >
          <div className={managedToggle ? 'managed-icon' : 'unmanaged-icon'} />
          <div className='icon-text'>
            {managedToggle ? t('managedTooltip') : t('unmanagedTooltip')}
          </div>
        </div>
      </div>
    );
  };

  const getDeleteButton = (disabled: boolean, className?: string) => (
    <div className='delete-location'>
      {haveZone ? (
        <TooltipDefinition
          className='tooltipDeleteLocation'
          direction='top'
          tooltipText={t('tooltipDeleteLocationText') as string}
        >
          <Button
            className='locationDetailsDeleteDisabled'
            kind='danger--ghost'
          >
            {t('delete.deleteLocation')}
          </Button>
        </TooltipDefinition>
      ) : (
        <Button
          kind='danger--ghost'
          disabled={disabled}
          className={className}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Locations Details'].events.deleteLocation.props,
              analyticsData['Locations Details'].events.deleteLocation.event
            );
            setOpenDeleteLocationModal(true);
          }}
        >
          {t('delete.deleteLocation')}
        </Button>
      )}
    </div>
  );

  const submitDeleteRequest = async () => {
    try {
      setDisableButton(true);
      await deleteLocation(couldId, locationId);
      notification.onTrigger('TOAST', {
        title: t('delete.successNotification.title'),
        subtitle: t('delete.successNotification.description', {
          locationName: locationData?.name ?? '',
        }),
      });
      inValidateListCacheFn(QueryKeys.LOCATIONS);
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.LOCATIONS],
      });

      navigate('/locations');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('delete.error.authErrorTitle'),
          kind: 'error',
          subtitle: t('delete.error.authErrorSubtitle'),
        });
      }

      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      notification.onTrigger('TOAST', {
        title: t('delete.error.title'),
        kind: 'error',
        subtitle:
          errorMessage.length > 0
            ? errorMessage
            : t('delete.error.errorMsg', {
                locationName: locationData?.name ?? '',
              }),
      });
    } finally {
      setDisableButton(false);
      setOpenDeleteLocationModal(false);
    }
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='location-deployment-environment-details'>
      <Header
        loading={locationDataLoading}
        title={locationData ? locationData.name : ''}
        subTitle={getSubTitle()}
        headerIcon={getHeaderIcon()}
        breadcrumbs={
          state !== null && state.breadcrumb
            ? state.breadcrumb
            : [
                {
                  url: '/',
                  label: t('home'),
                },
                {
                  url: '/locations',
                  label: t('locations'),
                },
              ]
        }
        dataTestId='location-details-header'
      />

      {locationDataLoading ? (
        <div className='page-content'>
          <SkeletonPlaceholder className='details-tile-skeleton' />
          <DataTableSkeleton />
        </div>
      ) : (
        <div className='page-content'>
          <DetailsCard
            type='LOCATION_DETAILS'
            openEditModal={editBtnClick}
            isEditable={true}
            isUnManaged={locationData?.unmanaged}
            detailsCardName={t('detailsCardName')}
            data={[
              {
                key: 'name',
                value: locationData?.name ? locationData?.name : '—',
              },
              {
                key: 'cloud',
                value: cloudDetails?.name ? cloudDetails?.name : '—',
              },
              {
                key: 'type',
                value: t(locationData?.type ?? ''),
              },
              {
                key: 'coordinates',
                value: locationData?.geo_coordinates
                  ? locationData?.geo_coordinates
                  : '—',
              },
              {
                key: 'streetAddress',
                value: locationData?.street ? locationData?.street : '—',
              },
              {
                key: 'city',
                value: locationData?.city ? locationData?.city : '—',
              },
              {
                key: 'stateRegion',
                value: locationData?.region ? locationData?.region : '—',
              },
              {
                key: 'country',
                value: locationData?.country ? locationData?.country : '—',
              },
              {
                key: 'updatedAt',
                value: locationData?.updated_at
                  ? locationData?.updated_at
                  : '—',
              },
              {
                key: 'labels',
                value: locationDetailsLabels(),
              },
            ]}
            dataTestId='location-details-card'
          />

          <LocationDetailsDeploymentTable
            title={t('tableTitle', {
              count: locationsDeploymentData
                ? locationsDeploymentData?.length
                : 0,
            })}
            currentPageNumber={currentPageNumber}
            currentPageSize={currentPageSize}
            sortRows={(
              data: { id: string; text: string },
              direction: 'ASC' | 'DESC' | 'NONE'
            ) => handleTableSort(data, direction)}
            onPageChange={(pageData: { page: number; pageSize: number }) =>
              setPageChange(pageData)
            }
            rows={
              locationsDeploymentData
                ? filterApplied.length > 0
                  ? sortData(filteredData, sortKey, sortDirection)
                  : sortData(locationsDeploymentData, sortKey, sortDirection)
                : null
            }
            elementCount={
              locationsDeploymentData
                ? filterApplied.length > 0
                  ? filteredData.length
                  : locationsDeploymentData.length
                : 0
            }
            filteredDataSet={
              locationsDeploymentData
                ? filterApplied.length > 0
                  ? filteredData
                  : locationsDeploymentData
                : null
            }
            filteredDataCallback={data => {
              setFilteredData(data as DeploymentEnvironment[] | []);
              setPageNumber(1);
            }}
            data={locationsDeploymentData}
            filtersSelected={filterApplied as any}
            filtersAppliedCallback={data => setFilterApplied(data)}
            filters={getLocationFilters()}
            onRefresh={() => refreshData()}
            leftInlineFilters={leftInlineFilters}
            visibilityFlag={visibilityFlag}
            resourceGroupsData={resourceGroupsData}
            error403Flag={!permissionMap['deploymentEnv']}
            setOpenRegisterDeploymentEnvTearSheet={() => {
              setOpenRegisterDeploymentEnvTearSheet(
                !openRegisterDeploymentEnvTearSheet
              );
            }}
            locationData={locationData ?? null}
          />
          {locationData?.is_discovered && cloudDetails?.auto_discover ? (
            <TooltipDefinition
              tooltipText={t('delete.tooltipWithAutodiscover') as string}
              direction='top'
              data-testid={'tooltip-delete-button'}
            >
              {getDeleteButton(true, 'delete-location-button-disabled')}
            </TooltipDefinition>
          ) : locationsDeploymentData && locationsDeploymentData.length > 0 ? (
            <TooltipDefinition
              tooltipText={t('delete.tooltipWithoutAutodiscover') as string}
              direction='top'
              data-testid={'tooltip-delete-button'}
            >
              {getDeleteButton(true, 'delete-location-button-disabled')}
            </TooltipDefinition>
          ) : (
            getDeleteButton(false)
          )}
          {showEditLocation && (
            <RegisterLocation
              open={showEditLocation}
              onClose={() => onClose()}
              refreshLocation={refreshPage}
              cloudRegionMap={cloudRegionMap}
              actionType='update'
              locationDetails={locationData}
              cloudDetails={cloudDetails}
              updateCallBack={updateLocationDetails}
              allLocationsDeploymentData={allLocationsDeploymentData}
              haveZone={haveZone}
            />
          )}
          {
            <RegisterDeploymentEnv
              open={openRegisterDeploymentEnvTearSheet}
              isEditMode={false}
              deploymentEnvList={allLocationsDeploymentData ?? []}
              deplEnvSubtypesList={deplEnvSubtypes ?? []}
              onClose={() => {
                setOpenRegisterDeploymentEnvTearSheet(false);
              }}
              selectedCloudProps={cloudDetails ?? null}
              selectedLocationProps={locationData ?? null}
              refreshData={() => {
                refreshData();
              }}
              hasCloudAuthorization={permissionMap['cloud']}
              disableLocationDropdown={true}
            />
          }
        </div>
      )}
      <Modal
        className='delete-location-modal'
        size='xs'
        danger
        modalHeading={t('delete.modalTitle')}
        onRequestClose={() => setOpenDeleteLocationModal(false)}
        onRequestSubmit={() => submitDeleteRequest()}
        primaryButtonText={t('delete.deleteButton')}
        secondaryButtonText={t('delete.cancelButton')}
        open={openDeleteLocationModal}
        primaryButtonDisabled={disableButton}
      >
        {t('delete.description', { locationName: locationData?.name ?? '' })}
      </Modal>
    </div>
  );
};

export default LocationDetailsContainer;
