import { MCNM_NETWORK_API_URL } from './config';
import { axiosInstance } from './api';

//Get location details
export async function getLocationDetails(
  cloudId,
  locationId,
  view = 'all',
  throwError = false
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/locations/${locationId}`,
      params: {
        visibility: view, // gets back all locations (managed and unmanaged) by default
      },
    });

    return response.data;
  } catch (error) {
    // Note: I added an extra throwError parameter here to make this function backward compatible.
    if (throwError) {
      throw error;
    }

    console.log('error in GET  locations details', error);
    return [];
  }
}
//Get all the locations that MCNM oversees across all clouds
export async function getAllLocations(view = 'managed', throwError = true) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud`,
    });
    let locationsData = [];
    await Promise.all(
      response.data.clouds.map(async cloud => {
        const cloudId = cloud?.resource_id;
        let cloudLocations = [];
        await getLocations(cloudId, view)
          .then(response => {
            cloudLocations = response ?? [];
          })
          .catch(error => {
            if (throwError && error.response?.status === 403) {
              throw error;
            }
          });
        if (Array.isArray(cloudLocations) && cloudLocations.length > 0) {
          cloudLocations.forEach(eachLocation => {
            eachLocation.cloud_name = cloud?.name;
          });
          locationsData = [...locationsData, ...cloudLocations];
        }
        return [];
      })
    );
    return locationsData;
  } catch (error) {
    console.log('error in GET all locations across clouds', error);
    if (throwError && error.response.status === 403) {
      throw error;
    }
    return [];
  }
}

export async function getLocations(cloud_id, view = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/locations`,
      params: {
        visibility: view, // gets back all locations (managed and unmanaged) by default
      },
    });

    return response.data.locations;
  } catch (error) {
    console.log(error);
    if (error.response?.status === 403) {
      throw error;
    }
  }
}

// get particular cloud location data
export async function getCloudLocation(cloud_id, location_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/locations/${location_id}`,
      // params: {
      //   visibility: 'all' // gets back all locations (managed and unmanaged)
      // }
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function createLocation(cloud_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/locations`,
      data,
    });

    return response.data;
  } catch (error) {
    console.log('Error creating location', error);
    throw error;
  }
}

//Delete an existing location
export async function deleteLocation(cloud_id, location_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/locations/${location_id}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Update an existing location
export async function updateLocation(cloud_id, location_id, location_data) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/locations/${location_id}`,
      data: location_data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
