import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  InlineNotification,
  OnChangeData,
  RadioButton,
  Row,
} from 'carbon-components-react';
import { CheckmarkFilled16, EdgeCluster16 } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import sortData from '../../../lib/tableSort';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';
import images from '../../../images/images.js';
import {
  ResourceGroup,
  AppliedFilter,
  DeploymentEnvironment,
  DeploymentEnvironmentSubtype,
} from '../../../models/master';

import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import { Filter } from '../../../components/FindAndFilterBar/FindAndFilterBar';
import LabelTag from '../../../components/LabelTag/LabelTag';

import './ConnectCluster.scss';
import { Cluster } from '../DeployGateway';

export interface Item {
  depEnvid: string;
  type: string;
  resourceGroupId: string;
  depEnvName: string;
  cloud_id: string;
  cloudName: string;
  location_id: string;
  locationName: string;
}

interface Props {
  deploymentEnvs: Cluster[] | null;
  deploymentEnvSubtypesData: DeploymentEnvironmentSubtype[] | null;
  resourceGroupsData: ResourceGroup[] | null;
  selectedDepEnv: Cluster | null;
  selectedDepEnvTemp: Cluster | null;
  handleSelectDepEnv: (item: Cluster, makeApiCall: boolean) => void;
  error: boolean;
  depEnvPermissionError: boolean;
  depEnv500Error: boolean;
  errorType: string;
  onCloseError: () => void;
}

const DeploymentEnvTable: React.FC<Props> = ({
  deploymentEnvs,
  deploymentEnvSubtypesData,
  resourceGroupsData,
  selectedDepEnv,
  selectedDepEnvTemp,
  handleSelectDepEnv,
  error,
  errorType,
  onCloseError,
  depEnvPermissionError,
  depEnv500Error,
}) => {
  const { t } = useTranslation('deployGateway');
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [filteredData, setFilteredData] = useState<
    DeploymentEnvironment[] | []
  >([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  // const [toggle, setToggle] = useState(false);
  const [cloudFilterName, setCloudFilterName] = useState('clouds');
  const [filteredDeploymentEnvs, setFilteredDeploymentEnvs] = useState<any>([]);

  const navigate = useNavigate();

  const leftInlineFilters: any = [
    {
      key: t('deploymentEnvTable.view'),
      label: t('deploymentEnvTable.view'),
      type: '',
      values: [t('deploymentEnvTable.leftFilterCloudsValue')]
        .concat(
          Array.from(
            new Set(deploymentEnvs?.map(deplEnv => deplEnv.cloud_name))
          )
        )
        .map(item =>
          item === 'clouds'
            ? {
                label: t('deploymentEnvTable.tableHeaders.filter.allClouds'),
                value: 'clouds',
              }
            : {
                label: item,
                value: item,
              }
        ),
      filterCallback: (e: any) => {
        if (e.selectedItem.label) {
          setCloudFilterName(e.selectedItem.value ?? e.selectedItem.label);
        }
      },
    },
  ];

  useEffect(() => {
    if (Array.isArray(deploymentEnvs)) {
      if (cloudFilterName !== 'clouds') {
        let filteredData = deploymentEnvs?.filter(
          deplEnv => deplEnv.cloud_name === cloudFilterName
        );
        setFilteredDeploymentEnvs(filteredData);
      } else {
        setFilteredDeploymentEnvs(deploymentEnvs);
      }
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(deploymentEnvs)) {
      if (cloudFilterName !== 'clouds') {
        let filteredData = deploymentEnvs?.filter(
          deplEnv => deplEnv.cloud_name === cloudFilterName
        );
        setFilteredDeploymentEnvs(filteredData);
      } else {
        setFilteredDeploymentEnvs(deploymentEnvs);
      }
    }
  }, [cloudFilterName, deploymentEnvs]);

  const getDeploymentTableHeaders = () => {
    let headers = [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'name__format',
        originalKey: 'name',
        header: t(`deploymentEnvTable.tableHeaders.name`),
        sort: 'sortByName',
        style: { minWidth: '11.5rem' },
      },
      {
        key: 'resourceGroup',
        originalKey: 'resourceGroup',
        header: t('deploymentEnvTable.tableHeaders.resourceGroup'),
      },
      {
        key: 'location',
        originalKey: 'location',
        header: t('deploymentEnvTable.tableHeaders.location'),
        sort: 'sortByLocation',
      },
      {
        key: 'cloudName',
        originalKey: 'cloudName',
        header: t('deploymentEnvTable.tableHeaders.cloudName'),
        sort: 'sortByCloudName',
      },
      {
        key: 'type',
        originalKey: 'type',
        header: t('deploymentEnvTable.tableHeaders.type'),
        sort: 'sortByType',
      },

      {
        key: 'labels',
        originalKey: 'labels',
        header: t('deploymentEnvTable.tableHeaders.labels'),
      },
    ];

    return headers;
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getResourceGroupName = (id: string | undefined) => {
    const resourceGroup =
      id && Array.isArray(resourceGroupsData)
        ? resourceGroupsData!.find(
            resourceGroup => resourceGroup.resource_id === id
          )
        : null;

    return resourceGroup ? resourceGroup.name : '';
  };

  const emptyStateData = {
    icon: images.DeploymentEnvironmentEmpty(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('deploymentEnvTable.emptyState.emptyContainerHeader'),
    emptyStateDescription: t(
      'deploymentEnvTable.emptyState.emptyContainerDescription'
    ),
  };

  const handleRadiobtnClick = (value: boolean, item: Cluster) => {
    value && handleSelectDepEnv(item, true);
  };

  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const renderRadioButton = (item: Cluster, checked: boolean) => {
    return (
      <div>
        <RadioButton
          id={'' + item.resource_id}
          labelText=''
          hideLabel
          checked={checked}
          onClick={e =>
            handleRadiobtnClick((e.target as HTMLInputElement).checked, item)
          }
        />
      </div>
    );
  };

  const renderAutoDiscover = (
    type: string,
    autoDiscover: boolean | undefined
  ) => {
    let autoDiscoverField;
    switch (type) {
      case 'cluster':
        autoDiscoverField = autoDiscover ? (
          <div className='auto-discover-field'>
            <div className='auto-discover-label'>
              <div className='label-icon'>
                <CheckmarkFilled16 fill={'green'} />
              </div>
              <span className='label-value'>
                {t('deploymentEnvTable.table.autodiscover.namespace')}
              </span>
            </div>
          </div>
        ) : (
          'Off'
        );
        break;

      default:
        autoDiscoverField = 'Off';
        break;
    }
    return autoDiscoverField;
  };

  const getFormattedRows = (rows: Cluster[] | null) => {
    const formattedData: {
      checkbox: JSX.Element;
      id: string;
      name: string;
      name__format: JSX.Element;
      resourceGroup: string;
      location: string;
      type: string | false;
      autodiscover: JSX.Element | undefined | string;
      cloudName: string;
      labels: JSX.Element | string;
    }[] = [];
    if (rows)
      rows.map((row: Cluster) => {
        let item: Item = {
          depEnvid: row.resource_id,
          type: row.type,
          resourceGroupId: row.resource_group_id,
          depEnvName: row.name,
          cloud_id: row.cloud_id,
          cloudName: row.cloud_name,
          location_id: row.location_id,
          locationName: row.location_name,
        };
        const checked = row.resource_id === selectedDepEnvTemp?.resource_id;
        formattedData.push({
          checkbox: renderRadioButton(row, checked),
          id: row.resource_id,
          name: row.name,
          name__format: (
            <div className='name-column-value'>
              <div className='depl-env-icon'>
                {row.type === 'cluster' ? (
                  <EdgeCluster16 />
                ) : (
                  images.vpcIconSvg()
                )}
              </div>
              {
                <GenericTruncateString
                  str={row.name}
                  maxLength={23}
                  limit={9}
                  tableView={false}
                />
              }
              {row?.is_discovered && (
                <div className='icon'>
                  <IconWithToolTip
                    icon={images.AutoDiscoverdLockIconSmall()}
                    iconDescription={t(
                      'deploymentEnvTable.autoDiscoveredAccessLimited'
                    )}
                  />
                </div>
              )}
            </div>
          ),
          resourceGroup: getResourceGroupName(row.resource_group_id),
          location: row.location_name,
          cloudName: row.cloud_name,
          autodiscover: renderAutoDiscover(row.type, row.auto_discover),
          type:
            (Array.isArray(deploymentEnvSubtypesData) &&
              deploymentEnvSubtypesData.length > 0 &&
              deploymentEnvSubtypesData?.find(
                depEnvSubType => depEnvSubType.type_code === row.subtype
              )?.type_name) ??
            '',

          labels: row.labels ? formatTags(row.labels) : '—',
        });
        return 0;
      });
    else return null;
    return formattedData;
  };

  return (
    <div className='deployment-table-container'>
      <div className='deployment-table-content'>
        <div
          className={`deployment-table ${
            selectedDepEnv?.type === 'cluster' ? 'selected' : ''
          }`}
        >
          {error && (
            <InlineNotification
              onClose={() => onCloseError() as any}
              kind={'error'}
              title={t(
                `deploymentEnvTable.clusterErrorMessage.${errorType}.title`
              )}
              subtitle={t(
                `deploymentEnvTable.clusterErrorMessage.${errorType}.subtitle`
              )}
              lowContrast
            />
          )}
          <div className='selectClusterTitleContainer'>
            <Row>
              <Column md={4}>
                <div className='clusterTitle'>
                  {t('deploymentEnvTable.clusterTitle')}
                </div>
                <div className='clusterSubTitle'>
                  {t('deploymentEnvTable.selectClusterSubTitle')}
                </div>
              </Column>
            </Row>
          </div>

          <GenericTableWithFilters
            id='table-with-filter-panel'
            rows={
              Array.isArray(filteredDeploymentEnvs)
                ? filterApplied.length > 0
                  ? sortData(
                      getFormattedRows(filteredData as any),
                      sortKey,
                      sortDirection
                    )
                  : sortData(
                      getFormattedRows(filteredDeploymentEnvs),
                      sortKey,
                      sortDirection
                    )
                : null
            }
            data={
              filteredDeploymentEnvs
                ? filterApplied.length > 0
                  ? filteredData
                  : filteredDeploymentEnvs
                : null
            }
            headers={getDeploymentTableHeaders()}
            isSortable={true}
            totalElementsCount={
              filteredDeploymentEnvs
                ? filterApplied.length > 0
                  ? filteredData.length
                  : filteredDeploymentEnvs?.length ?? 0
                : 0
            }
            fullData={filteredDeploymentEnvs}
            onTableRefresh={() => {}}
            showRefresh={false}
            filteredDataCallback={(data: DeploymentEnvironment[] | []) => {
              data && setFilteredData(data);
              setPageNumber(1);
            }}
            selectedFiltersVal={filterApplied as any}
            setFilterApplied={(data: AppliedFilter[]) => setFilterApplied(data)}
            filters={
              [
                {
                  key: 'location',
                  type: 'multi',
                  mdWidth: 4,
                  lgWidth: 4,
                  label: t('deploymentEnvTable.tableHeaders.filter.location'),
                  values: [
                    ...Array.from(
                      new Set(
                        filteredDeploymentEnvs?.map(
                          (depenv: any) => depenv.location_name
                        )
                      )
                    ),
                  ],
                },
                {
                  key: 'infrastructureGroup',
                  type: 'single',
                  mdWidth: 4,
                  lgWidth: 4,
                  label: t(
                    'deploymentEnvTable.tableHeaders.filter.resourceGroup'
                  ),
                  values: [
                    ...Array.from(
                      new Set(
                        filteredDeploymentEnvs?.map((depenv: any) =>
                          getResourceGroupName(depenv.resource_group_id)
                        )
                      )
                    ),
                  ],
                },
                {
                  key: 'subTypeName',
                  label: t('deploymentEnvTable.tableHeaders.filter.subType'),
                  type: 'single',
                  values: [
                    ...Array.from(
                      new Set(
                        filteredDeploymentEnvs?.map(
                          (depenv: any) => depenv?.subTypeName
                        )
                      )
                    ),
                  ],
                },
              ] as Filter[]
            }
            currentPageNumber={currentPageNumber}
            currentPageSize={currentPageSize}
            onPageChange={(pageData: any) => setPageChange(pageData)}
            emptyState={emptyStateData}
            sortRows={(
              data: { id: string; text: string },
              sortDirection: 'ASC' | 'DESC' | 'NONE'
            ) => handleTableSort(data, sortDirection)}
            leftInlineFilters={leftInlineFilters}
            visibilityFlag={cloudFilterName}
            render403Container={depEnvPermissionError}
            render500Container={depEnv500Error}
          />
        </div>
      </div>
    </div>
  );
};

export default DeploymentEnvTable;
