import React from 'react';
import { useTranslation } from 'react-i18next';
import { Procedure, getGatewayProcedureConfig } from './config';

import './GatewayStatus.scss';
import { InlineLoading } from 'carbon-components-react';

interface Props {
  status: Procedure;
}

const GatewayProcedure: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('gatewayStatus');

  const config = getGatewayProcedureConfig(t, status);
  if (config) {
    if (config.loadingIcon) {
      return (
        <div className='procedure-field'>
          <InlineLoading description={config.label} />
        </div>
      );
    }
    return (
      <div className='procedure-field'>
        <div className='icon'>{config.icon}</div>
        <div className='status-message'>{config.label}</div>
      </div>
    );
  }

  return <>—</>;
};

export default GatewayProcedure;
